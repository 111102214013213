import { Injectable } from '@angular/core';
import { BaseSmartFilterFormViewModelComponent } from '../base-smart-filter-form-view-model';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class CannabinoidAddEditSmartFilterFormViewModel extends BaseSmartFilterFormViewModelComponent {

  public secondaryCannabinoids$: Observable<string[]> = window.types.secondaryCannabinoids$.pipe(
    map(secondaryCannabinoidTypes => secondaryCannabinoidTypes?.map(sct => sct?.getSelectionValue()))
  );

}
