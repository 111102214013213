import { Deserializable } from '../../protocols/deserializable';
import { ReplaySubject } from 'rxjs';
import { SafeResourceUrl } from '@angular/platform-browser';
import { MediaType } from '../../enum/dto/media-type.enum';
import { DateUtils } from '../../../utils/date-utils';
import { CachePolicy } from '../../enum/shared/cachable-image-policy.enum';
import { BlobUtils } from '../../../utils/blob-utils';
import { AssetSize } from '../../enum/dto/asset-size.enum';

export class AssetUrl implements Deserializable {

  public size: AssetSize;
  public url: string;

  // not from API
  public name: string;
  public assetId: string;
  public md5Hash: string;
  public mediaType: MediaType;
  public srcUrl: ReplaySubject<[AssetSize, string | SafeResourceUrl]>;
  public loading: ReplaySubject<boolean>;
  public timeUrlArrivedFromApi: number = -1;
  private alreadyDownloading: boolean = false;

  // Cache
  public urlAccessDate: number;

  public buildCacheKey(): string {
    return `Image-${this.assetId}-${this.size}-${this.md5Hash}`;
  }

  public onDeserialize() {
    this.srcUrl = new ReplaySubject<[AssetSize, string | SafeResourceUrl]>(1);
    this.loading = new ReplaySubject<boolean>(1);
    this.alreadyDownloading = false;
    if (this.timeUrlArrivedFromApi < 0
      || this.timeUrlArrivedFromApi === undefined
      || this.timeUrlArrivedFromApi === null
    ) {
      this.timeUrlArrivedFromApi = DateUtils.currentTimestampInSeconds();
    }
  }

  public isImage(): boolean {
    return this.mediaType.match(/image\/*/) !== null;
  }

  public isVideo(): boolean {
    return this.mediaType.match(/video\/*/) !== null;
  }

  public isPDF(): boolean {
    return this.mediaType.match(/pdf\/*/) !== null;
  }

  public updateDataFrom(updated: AssetUrl): AssetUrl {
    this.size = updated.size;
    this.url = updated.url;
    this.name = updated.name;
    this.assetId = updated.assetId;
    this.md5Hash = updated.md5Hash;
    this.mediaType = updated.mediaType;
    this.timeUrlArrivedFromApi = updated.timeUrlArrivedFromApi;
    return this;
  }

  urlExpired(): boolean {
    const expiresAt = this.timeUrlArrivedFromApi + this.urlExpiresAfterNSeconds();
    return DateUtils.currentTimestampInSeconds() > expiresAt;
  }

  urlExpiresAfterNSeconds(): number {
    return DateUtils.secondsInOneMinute() * 5;
  }

  forceUrlToExpired() {
    this.timeUrlArrivedFromApi = 0;
  }

  loadAssetIntoSrcUrlSubject(cachePolicy: CachePolicy, cacheForNSeconds: number) {
    if (!this.getCachedAsset(cachePolicy, cacheForNSeconds)) {
      const maxRetryCount = this.isVideo() ? 3 : 1;
      if (!this.alreadyDownloading) {
        this.alreadyDownloading = true;
        this.downloadAndCacheBlob(cachePolicy, cacheForNSeconds, 0, maxRetryCount);
      }
    }
  }

  loadAssetIntoSrcUrlSubjectIfCached(cachePolicy: CachePolicy, cacheForNSeconds: number) {
    this.getCachedAsset(cachePolicy, cacheForNSeconds);
  }

  private downloadAndCacheBlob(
    cachePolicy: CachePolicy = CachePolicy.Service,
    cacheForNSeconds: number,
    retryCount: number = 0,
    maxRetryCount: number = 3
  ) {
    this.loading.next(true);
    window?.injector?.imageApi?.GetBlobFromUrl(this)?.subscribe((blob: Blob) => {
      // Cache the blob
      this.url = '';
      if (blob) {
        const reader = new FileReader();
        reader.onloadend = function() {
          const base64data = reader.result;
          window?.injector?.cache?.cacheBlob(this.buildCacheKey(), base64data, cachePolicy);
        }.bind(this);
        reader.readAsDataURL(blob);
        // Pass the Blob Url to the srcUrl
        const urlObject = URL.createObjectURL(blob);
        this.srcUrl.next([this.size, window?.injector?.sanitizer?.bypassSecurityTrustResourceUrl(urlObject)]);
        this.urlAccessDate = DateUtils.currentTimestampInSeconds();
      }
      this.alreadyDownloading = false;
      this.loading.next(false);
    }, _ => {
      this.forceUrlToExpired();
      if (retryCount > -1 && retryCount < maxRetryCount) {
        // Retry mechanism for when asset is not available at url yet
        // (ie: presigned url is valid, but image-sync is still processing)
        const delayTime = (retryCount) * 4000;
        setTimeout(function() {
          this.downloadAndCacheBlob(cachePolicy, cacheForNSeconds, retryCount + 1, maxRetryCount);
        }.bind(this), delayTime);
      } else {
        this.loading.next(false);
        this.alreadyDownloading = false;
      }
    });
  }

  private getCachedAsset(cachePolicy: CachePolicy, cacheForNSeconds: number): boolean {
    const key = this.buildCacheKey();
    return this.loadCachedBlob(key, this.mediaType, cachePolicy, cacheForNSeconds);
  }

  public loadCachedBlob(
    key: string,
    mediaType: MediaType,
    cachePolicy: CachePolicy,
    cacheForNSeconds: number
  ): boolean {
    const blobString = window?.injector?.cache?.getCachedBlob(key, cachePolicy, cacheForNSeconds);
    const blob = BlobUtils.b64toBlob(blobString, mediaType);
    if (blob) {
      const urlObject = URL.createObjectURL(blob);
      this.srcUrl.next([this.size, window?.injector?.sanitizer?.bypassSecurityTrustResourceUrl(urlObject)]);
      this.urlAccessDate = DateUtils.currentTimestampInSeconds();
      this.loading.next(false);
      return true;
    } else {
      return false;
    }
  }

}
