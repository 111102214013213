<app-loading *ngIf="viewModel.isLoading$ | async" [options]="viewModel?.loadingOpts$ | async"> </app-loading>

<div class="row">
  <div class="col-12">
    <div class="bs-headline">Profile</div>
  </div>
</div>
<div class="row mt-48px">
  <div class="col-12 flex-align-end">
    <div class="align-self-center inline-block">
      <div class="bs-section-title lh-80p flex-wrap-content ml-16px">
        {{ viewModel.user?.getFullName() }}
      </div>
    </div>
  </div>
</div>

<hr class="mt-28px mb-24px" />

<div *ngIf="!viewModel.user?.emailConfirmed">
  <div class="row">
    <div class="col-4">
      <p class="bs-semi-bold f16px">Email Confirmation</p>
      <p class="bs-regular f14px">
        A confirmation code has been sent to your email address.<br />
        Enter it and press 'Confirm Email'.
      </p>
    </div>
    <div class="col-8">
      <app-warning-banner *ngIf="!viewModel.user?.emailConfirmed" class="block mb-16px">
        You are required to confirm your email address.
      </app-warning-banner>
      <!-- Form -->
      <lib-reactive-form-group
        #emailConfirmation
        [bindTo]="viewModel.emailConfirmationReq"
        (canSubmit$)="viewModel.confirmationCodeValid$.next($event)"
        (formSubmitted$)="emailConfirmationSubmit($event)">
        <lib-reactive-form-text
          [inputName]="'confirmationCode'"
          [label]="'Confirmation Code'"
          [placeholder]="'Enter Code Here'"
          [bindingProperty]="'confirmationCode'"
          [required]="true"
          [customValidators]="viewModel.emailConfirmationCodeCustomValidator">
        </lib-reactive-form-text>
      </lib-reactive-form-group>
      <div class="mt-2">
        <button
          class="bs-button preferred-button ms-0"
          (click)="emailConfirmation.submitForm()"
          [disabled]="!(viewModel.confirmationCodeValid$ | async)"
          [class.disabled]="!(viewModel.confirmationCodeValid$ | async)">
          Confirm Email
        </button>
        <button class="bs-button neutral-button" (click)="resendCode()">Resend Code</button>
      </div>
    </div>
  </div>
  <hr class="mt-24px mb-20px" />
</div>

<div class="row">
  <div class="col-4">
    <p class="bs-semi-bold f16px">Account Information</p>
  </div>
  <div class="col-8">
    <!-- Form -->
    <lib-reactive-form-group
      #accountData
      [bindTo]="viewModel.accountInfoReq"
      (formSubmitted$)="accountInfoFormSubmitted($event)"
      (unsavedChanges$)="viewModel.unsavedChanges = $event">
      <lib-reactive-form-column-layout [nColumns]="2">
        <lib-reactive-form-text
          [inputName]="'firstName'"
          [label]="'First Name'"
          [bindingProperty]="'firstName'"
          [required]="true"
          [placeholder]="'Name'">
        </lib-reactive-form-text>

        <lib-reactive-form-text
          [inputName]="'lastName'"
          [label]="'Last Name'"
          [bindingProperty]="'lastName'"
          [required]="true"
          [placeholder]="'Last Name'">
        </lib-reactive-form-text>
      </lib-reactive-form-column-layout>

      <lib-reactive-form-email
        [inputName]="'email'"
        [label]="'Email'"
        [bindingProperty]="'email'"
        [required]="true"
        [placeholder]="'Enter your email'">
      </lib-reactive-form-email>
    </lib-reactive-form-group>
    <div class="mt-2">
      <button
        class="bs-button preferred-button ms-0"
        (click)="accountData.submitForm()"
        [disabled]="this.accountData.disableSubmit$ | async"
        [ngClass]="{ disabled: this.accountData?.disableSubmit$ | async }">
        Save Changes
      </button>
      <button class="bs-button neutral-button" (click)="openChangePasswordModal()">Change Password</button>
    </div>
  </div>
</div>

<hr class="mt-24px mb-20px" />
