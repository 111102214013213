import { MediaType } from '../models/enum/dto/media-type.enum';
import { AssetSize } from '../models/enum/dto/asset-size.enum';
import { CachePolicy } from '../models/enum/shared/cachable-image-policy.enum';
import { SmartFilterCategoryAllProducts } from '../models/enum/shared/smart-filter-category-all-products';
import { MenuType } from './menu-type-definition';
import { Orientation } from './orientation-type';
import { ProductType } from './product-type-definition';
import { InventoryProvider } from './inventory-provider-type';
import { MenuSubtype } from './menu-subtype-definition';
import { CompanySmartSyncRequestType } from '../models/company/enum/company-smart-sync-request-type.enum';
import { SmartDataType } from '../models/enum/shared/smart-data-type.enum';
import { NgxPopperjsPlacements, NgxPopperjsTriggers } from 'ngx-popperjs';
import { SyncJobStatus } from './sync-job-status-type';
import { SyncJobSyncedBy } from '../models/automation/enum/sync-job-synced-by.enum';
import { TableLayoutType } from '@mobilefirstdev/reactive-table';
import { SyncJobStep } from '../views/customers/components/customer-details/components/customer-details-integration/components/new-sync-job-modal/sync-job-step';
import { FileUtils } from './file-utils';

export class ClientTypeUtils {

  static AssetSize: typeof AssetSize = AssetSize;
  static CachePolicy: typeof CachePolicy = CachePolicy;
  static CompanySmartFilterRequestType: typeof CompanySmartSyncRequestType = CompanySmartSyncRequestType;
  static FileUtils: typeof FileUtils = FileUtils;
  static InventoryProvider: typeof InventoryProvider = InventoryProvider;
  static MediaType: typeof MediaType = MediaType;
  static MenuType: typeof MenuType = MenuType;
  static MenuSubType: typeof MenuSubtype = MenuSubtype;
  static NgxPopperjsPlacements: typeof NgxPopperjsPlacements = NgxPopperjsPlacements;
  static NgxPopperjsTriggers: typeof NgxPopperjsTriggers = NgxPopperjsTriggers;
  static Orientation: typeof Orientation = Orientation;
  static ProductType: typeof ProductType = ProductType;
  static SmartDataType: typeof SmartDataType = SmartDataType;
  static SyncJobStatus: typeof SyncJobStatus = SyncJobStatus;
  static SyncJobStep: typeof SyncJobStep = SyncJobStep;
  static SyncJobSyncedBy: typeof SyncJobSyncedBy = SyncJobSyncedBy;
  static TableLayoutType: typeof TableLayoutType = TableLayoutType;
  static SmartFilterCategoryAllProducts: typeof SmartFilterCategoryAllProducts = SmartFilterCategoryAllProducts;

}
