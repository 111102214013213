// noinspection JSUnusedLocalSymbols

import { BaseDomainModel } from '../models/base/base-domain-model';
import { BehaviorSubject, combineLatest, defer, forkJoin, iif, interval, Observable, of, Subject } from 'rxjs';
import { HydratedVariantBadge } from '../models/product/dto/hydrated-variant-badge';
import { CuratedVariantBadgeSection } from '../models/product/dto/curated-variant-badge-section';
import { debounceTime, delay, distinctUntilChanged, filter, map, shareReplay, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { SessionService } from '../services/session-service';
import { CacheService } from '../services/cache-service';
import { ProductAPI } from '../api/product-api';
import { VariantBadge } from '../models/product/dto/variant-badge';
import { BudsenseFile } from '../models/shared/budsense-file';
import { GenerateUploadUrlRequest } from '../models/image/requests/generate-upload-url-request';
import { UploadFilePath } from '../models/enum/dto/upload-file.path';
import { ImageAPI } from '../api/image-api';
import { Injectable } from '@angular/core';
import { StringUtils } from '../utils/string-utils';
import type { AddVariantLookupForm } from '../models/product/dto/add-variant-lookup-form';
import { MediaType } from '../models/enum/dto/media-type.enum';
import { VariantLookupLog } from '../models/product/dto/variant-lookup-log';
import * as moment from 'moment';
import { Variant } from '../models/product/dto/variant';
import { Card } from '../models/shared/stylesheet/card';
import { CardStyle } from '../models/shared/stylesheet/card-style.enum';
import { DistinctUtils } from '../utils/distinct-utils';
import { DisplayAttributeGroup } from '../models/product/dto/display-attribute-group';
import { AssetGroup } from '../models/product/dto/asset-group';
import { CreateAssetGroupRequest } from '../models/product/requests/create-asset-group-request';
import { MediaUtils } from '../utils/media-utils';
import { ProductType } from '../utils/product-type-definition';
import { Asset } from '../models/image/dto/asset';
import { SortUtils } from '../utils/sort-utils';
import { DisplayAttributeGroupDetails } from '../models/product/dto/display-attribute-group-details';
import { exists } from '../functions/exists';

@Injectable({
  providedIn: 'root'
})
export class ProductDomainModel extends BaseDomainModel {

  constructor(
    public session: SessionService,
    private cacheService: CacheService,
    private productAPI: ProductAPI,
    private imageAPI: ImageAPI,
  ) {
    super();
    this.init();
  }

  private badges: BehaviorSubject<HydratedVariantBadge[]> = new BehaviorSubject<HydratedVariantBadge[]>(null);
  public badges$ = this.badges.asObservable();
  private curatedBadges: BehaviorSubject<HydratedVariantBadge[]> = new BehaviorSubject<HydratedVariantBadge[]>(null);
  public curatedBadges$ = this.curatedBadges.asObservable();
  public curatedBadgeSections$ = this.curatedBadges.pipe(map(curated => this.getBadgeSections(curated)));

  private variantLookupLogs = new BehaviorSubject<VariantLookupLog[]>(null);
  private variantLookupLogUploads = new BehaviorSubject<VariantLookupLog[]>(null);
  public variantLookupLogs$ = combineLatest([this.variantLookupLogUploads, this.variantLookupLogs]).pipe(
    debounceTime(10),
    map(([uploads, logs]) => {
      if (uploads === null && logs === null) {
        return null; // this is so the table loading indicator appears properly
      }
      return [...(uploads ?? []), ...(logs ?? [])];
    }),
    shareReplay(1),
  );

  private stopVariantLookupLogPolling = new Subject<void>();
  private variantLookupLogsUploadingCheck = this.variantLookupLogUploads.pipe(
    filter(uploadingLogs => uploadingLogs?.length > 0),
    switchMap(() => interval(5000).pipe(takeUntil(this.stopVariantLookupLogPolling))),
    switchMap(() => this.productAPI.GetVariantLookupLogs())
  ).subscribe((newVariantLookupLogs) => {
    const recentLogs = newVariantLookupLogs.filter(l => (moment().unix() - l.dateCreated) <= 60);
    const uploadingLogs = this.variantLookupLogUploads.getValue();
    // find any logs created in the last 60 seconds and compare file names
    const uploadedLogsCompleted = uploadingLogs.some(ul => {
      return !!recentLogs.find(rl => rl.fileName === ul.fileName);
    });
    if (uploadedLogsCompleted) {
      this.stopVariantLookupLogPolling.next();
      this.variantLookupLogUploads.next([]);
    }
    this.variantLookupLogs.next(newVariantLookupLogs);
  }).addTo(this.subscriptions);

  private _universalVariants = new BehaviorSubject<Variant[]>(null);
  public universalVariants$ = this._universalVariants.pipe(shareReplay(1));

  // This connects smart filters with all products datatable
  private _smartFilerDataTableVariants = new BehaviorSubject<Variant[]>([]);
  public smartFilerDataTableVariants$ = this._smartFilerDataTableVariants.asObservable();
  private _tellSmartFiltersRowViewModelAboutVariantUpdate = new Subject<Variant[]>();
  public tellSmartFiltersRowViewModelAboutVariantUpdate$ =
    this._tellSmartFiltersRowViewModelAboutVariantUpdate as Observable<Variant[]>;

  // Global Signals
  public createNewBadgeFlag: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

  // Incomplete variants
  public incompleteVariants$ = this.universalVariants$.pipe(map(uvs => {
    return uvs
      ?.filter(uv => uv.isIncompleteOrUnconfirmed())
      .sort((a, b) => {
        const aUnconfirmed = Number(a.isUnconfirmed());
        const bUnconfirmed = Number(b.isUnconfirmed());
        const aIncomplete = Number(a.isIncomplete());
        const bIncomplete = Number(b.isIncomplete());
        if (aUnconfirmed === bUnconfirmed) {
          return aIncomplete - bIncomplete;
        }
        return bUnconfirmed - aUnconfirmed;
      }); // sort by complete and unconfirmed first
  }));

  public incompleteVariantCards$ = this.incompleteVariants$.notNull().pipe(
    map(variants => {
      // Always filter out Accessories from incomplete/unconfirmed variants
      return variants.map((variant) => {
        // Add incomplete variant cards
        const card = new Card(
          '',
          variant.name,
        );
        card.id = variant.barcode;
        card.subtext = `${variant.incompletePropertyCount()} Missing Fields`;
        card.cardStyle = variant.isIncomplete() ? CardStyle.IncompleteProduct : CardStyle.UnconfirmedProduct;
        card.data = variant;
        return card;
      });
    })
  );
  public incompleteVariantCount$ = this.incompleteVariantCards$.notNull().pipe(
    map(incomplete => incomplete?.length ?? 0)
  );
  public incompleteVariantPreviewCards$: Observable<Card[]> = this.incompleteVariantCards$.notNull().pipe(
    startWith([]),
    map(incomplete => incomplete?.take(10)),
  );

  // Global Display Attribute Groups
  private _displayAttributeGroups = new BehaviorSubject<DisplayAttributeGroup[]>(null);
  public displayAttributeGroups$ = this._displayAttributeGroups.pipe(
    distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiableArray)
  );
  connectToDisplayAttributeGroups = (gdags: DisplayAttributeGroup[]) => this._displayAttributeGroups.next(gdags);
  connectAndSortDisplayAttributeGroups = (gdags: DisplayAttributeGroup[]) => {
    const displayGroupAsc = (a: DisplayAttributeGroup, b: DisplayAttributeGroup) => {
      return SortUtils.numericStringAsc(a?.groupName, b?.groupName);
    };
    gdags?.sort(displayGroupAsc);
    this.connectToDisplayAttributeGroups(gdags);
  };
  public displayAttributeGroupCount$ = this.displayAttributeGroups$.notNull().pipe(
    map(gdags => gdags.length ?? 0)
  );

  // Unassigned Variants
  public unassignedVariants$ = combineLatest([
    this.universalVariants$.notNull().pipe(distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiableArray)),
    this.incompleteVariants$.notNull().pipe(distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiableArray)),
  ]).pipe(map(([uvs, ivs]) => {
    const incompleteVariantBarcodes = ivs?.map(iv => iv?.barcode);
    const unassignedUBVs = uvs?.filter(uv => uv?.isUnassigned());
    return unassignedUBVs?.filter(v => !incompleteVariantBarcodes?.includes(v?.barcode));
  }));

  public unassignedVariantCards$: Observable<Card[]> = this.unassignedVariants$.notNull().pipe(
    map(variants => variants.take(20)),
    map((variants: Variant[]) => {
      return variants.map((variant: Variant) => {
        // Add unassigned variant cards
        const card = new Card(
          variant.barcode,
          variant.name,
        );
        card.id = variant.barcode;
        card.data = variant;
        card.cardStyle = CardStyle.UnconfirmedProduct;
        return card;
      });
    })
  );

  public unassignedVariantCount$ = this.unassignedVariants$.notNull().pipe(
    map(unassigned => unassigned?.length ?? 0)
  );
  public unassignedVariantPreviewCards$: Observable<Card[]> = this.unassignedVariantCards$.notNull().pipe(
    startWith([]),
    map(unassigned => {
      return unassigned;
    }),
  );

  // Unassigned Asset Groups
  public gdagsWithVariantsUnassignedToAssetGroups$ = combineLatest([
    this.universalVariants$,
    this.displayAttributeGroups$
  ]).pipe(
    map(([uvs, gdags]) => {
      const variantsNotAssignedToAssetGroups = uvs?.filter(uv => {
        return !uv?.isUnassigned() && uv?.isUnassignedToAssetGroup();
      });
      const gdagIdsWithUnassignedVariants = variantsNotAssignedToAssetGroups
        ?.map(v => v?.displayAttributeGroupId)
        ?.unique();
      return gdags
        ?.filter(gdag => gdagIdsWithUnassignedVariants?.includes(gdag?.id))
        ?.sort((gdagA, gdagB) => SortUtils.numericStringAsc(gdagA?.groupName, gdagB?.groupName));
    })
  );

  private _isLoadingUBVs = new BehaviorSubject<boolean>(false);
  public isLoadingUBVs$ = this._isLoadingUBVs as Observable<boolean>;

  private _brands: BehaviorSubject<AssetGroup[]> = new BehaviorSubject<AssetGroup[]>([]);
  public brands$ = this._brands as Observable<AssetGroup[]>;

  static getUploadBadgeReq(badge: VariantBadge, f: BudsenseFile): GenerateUploadUrlRequest {
    const uploadReq = new GenerateUploadUrlRequest();
    uploadReq.fileName = StringUtils.normalizeCharacters(f.name);
    uploadReq.mediaClass = UploadFilePath.VariantBadgePath;
    uploadReq.mediaType = f.getMediaType();
    uploadReq.metadata = new Map<string, string>();
    uploadReq.metadata.set('CompanyId', badge.companyId.toString());
    uploadReq.metadata.set('Id', badge.id);
    return uploadReq;
  }

  static getUploadGroupAssetReq(assetGroupId: string, f: BudsenseFile): GenerateUploadUrlRequest {
    const uploadReq = new GenerateUploadUrlRequest();
    uploadReq.fileName = StringUtils.normalizeCharacters(f.name);
    uploadReq.mediaClass = UploadFilePath.AssetGroupPath;
    uploadReq.mediaType = f.getMediaType();
    uploadReq.mediaId = assetGroupId;
    uploadReq.metadata = new Map<string, string>();
    uploadReq.metadata.set('AssetGroupId', assetGroupId);
    return uploadReq;
  }

  public getBadges(force: boolean = false): Observable<HydratedVariantBadge[]> {
    const cacheKey = HydratedVariantBadge.buildArrayCacheKey(this.session.getAdminCompanyId());
    const cachedBadges = this.cacheService.getCachedArray<HydratedVariantBadge>(HydratedVariantBadge, cacheKey);
    if (cachedBadges && !force) {
      this.setBadges(cachedBadges);
      return of(cachedBadges);
    } else {
      return this.productAPI.GetAdminCuratedBadges().pipe(
        map(badges => {
          this.setBadges(badges);
          return badges;
        })
      );
    }
  }

  public getAdminCuratedBadge(id: string): Observable<HydratedVariantBadge[]> {
    return this.productAPI.GetAdminCuratedBadge(id);
  }

  private getBadgeSections(badges: HydratedVariantBadge[]): CuratedVariantBadgeSection[] {
    badges?.forEach(b => b.category = b.category || 'Your Badges');
    const sectionTitles = badges?.map(b => b.category).unique();
    return sectionTitles?.map(title => {
      const s = new CuratedVariantBadgeSection();
      s.title = title;
      const sectionBadges = badges?.filter(b => title === b.category);
      s.badges = sectionBadges?.filter(b => b.subCategory === '');
      const subSectionTitles = sectionBadges?.map(b => b.subCategory).unique();
      subSectionTitles.forEach(subSectionTitle => {
        if (subSectionTitle) {
          const subSectionBadges = sectionBadges?.filter(b => b.subCategory === subSectionTitle);
          s.subSectionBadges.set(subSectionTitle, subSectionBadges);
        }
      });
      return s;
    }).sort(((a, b) => a.title.localeCompare(b.title)));
  }

  private setBadges(badges: HydratedVariantBadge[]) {
    badges = badges.sort((a, b) => a.name.localeCompare(b.name));
    this.badges.next(badges);
    this.curatedBadges.next(badges);
  }

  public uploadAdminBadge(req: VariantBadge, f: BudsenseFile): Observable<HydratedVariantBadge[]> {
    return this.productAPI.CreateAdminCuratedBadge(req).pipe(
      switchMap((badge) => {
        const uploadReq = ProductDomainModel.getUploadBadgeReq(badge, f);
        return this.uploadBadgeHelper(badge, uploadReq, f);
      })
    );
  }

  public deleteCuratedBadges(badgeIds: string[]): Observable<string> {
    return this.productAPI.DeleteCuratedBadge(badgeIds).pipe(
      delay(500),
      map(deleteBadge => {
        const badges = this.curatedBadges.getValue();
        badgeIds.forEach(id => {
          const i = badges.findIndex(findB => findB.id === id);
          if (i > -1) {
            badges.splice(i, 1);
          }
        });
        this.setBadges(badges);
        return deleteBadge;
      })
    );
  }

  public updateCuratedBadges(b: VariantBadge[], newImg?: BudsenseFile): Observable<HydratedVariantBadge[]> {
    return this.productAPI.UpdateCuratedBadges(b).pipe(
      switchMap((badges) => {
        if (newImg && b.length === 1) {
          const req = ProductDomainModel.getUploadBadgeReq(badges[0], newImg);
          return this.uploadBadgeHelper(badges[0], req, newImg).pipe(map(badge => badge));
        }
        this.replaceBadge(badges);
        return of(badges);
      })
    );
  }

  private uploadBadgeHelper(
    badge: VariantBadge,
    req: GenerateUploadUrlRequest,
    f: BudsenseFile
  ): Observable<HydratedVariantBadge[]> {
    return this.imageAPI.GenerateUploadUrl(req).pipe(
      switchMap((signedUploadUrl) => {
        return this.imageAPI.PutImageUploadUrl(signedUploadUrl.url, f.url.toString(), req.fileName).pipe(
          // provide delay based on file size
          delay(f.getUploadDelay()),
          switchMap((_) => {
            return this.getAdminCuratedBadge(badge.id);
          }),
          map(mBadge => {
            this.replaceBadge(mBadge);
            return mBadge;
          })
        );
      })
    );
  }

  private replaceBadge(badges: HydratedVariantBadge[]) {
    const currentBadges = this.badges.getValue();
    badges.forEach(badge => {
      const i = currentBadges.findIndex(cb => cb.id === badge.id);
      if (i > -1) {
        currentBadges.splice(i, 1);
        currentBadges.push(badge);
      } else {
        currentBadges.push(badge);
      }
    });
    this.setBadges(currentBadges);
  }

  public fetchVariantLookupLogs() {
    if (this.variantLookupLogs.getValue()?.length > 0) {
      return;
    }
    this.productAPI.GetVariantLookupLogs().subscribe(s => {
      this.variantLookupLogs.next(s);
    });
  }

  public uploadVariantLookupForm(variantLookupFormObject: AddVariantLookupForm): Observable<void> {
    const uploadReq = new GenerateUploadUrlRequest();
    uploadReq.fileName = StringUtils.normalizeCharacters(variantLookupFormObject.fileToUpload.name);
    uploadReq.mediaClass = UploadFilePath.VariantLookupPath;
    uploadReq.mediaType = MediaType.CSV;
    uploadReq.metadata = new Map<string, string>();
    uploadReq.metadata.set('VariantLookupType', variantLookupFormObject.variantLookupType);

    return this.imageAPI.GenerateUploadUrl(uploadReq).pipe(
      switchMap((signedUploadUrl) => {
        return this.imageAPI.PutImageUploadUrl(
          signedUploadUrl.url,
          variantLookupFormObject.fileToUpload.url.toString(),
          uploadReq.fileName
        ).pipe(
          switchMap(() => {
            const uploadingLog = variantLookupFormObject.getUploadingVariantLookupLog();
            this.variantLookupLogUploads.next([uploadingLog]);
            return of(null);
          }),
        );
      })
    );
  }

  public fetchUniversalVariants(forceFetch: boolean = false) {
    if (this._universalVariants.getValue() === null || forceFetch) {
      this._isLoadingUBVs.next(true);
      this.productAPI.GetUniversalVariants([ProductType.Accessories]).subscribe(v => {
        this._universalVariants.next(v);
        this._isLoadingUBVs.next(false);
      }).addTo(this.subscriptions);
    }
  }

  public updateUniversalVariant(variant: Variant): Observable<Variant> {
    return this.productAPI.UpdateUniversalVariant(variant).pipe(tap(updatedVariant => {
      const cachedVariants = this._universalVariants.getValue();
      const index = cachedVariants.findIndex(v => v.barcode === updatedVariant.barcode);
      if (index >= 0) {
        cachedVariants[index] = updatedVariant;
      }
      this._universalVariants.next([...cachedVariants]);
      if (!!updatedVariant?.displayAttributeGroupId) {
        const gdags = this._displayAttributeGroups.getValue();
        const gdagToUpdate = gdags?.find(gdag => gdag?.id === updatedVariant?.displayAttributeGroupId);
        if (!!gdagToUpdate) {
          const i = gdagToUpdate.variants?.findIndex(v => v.barcode === updatedVariant.barcode);
          if (i > -1) {
            gdagToUpdate.variants?.splice(i, 1, updatedVariant);
          }
          this.setDisplayAttributeAndAssetGroups([gdagToUpdate]);
        }
      }
    }));
  }

  public getVariantsByBarcode(barcode: string): Observable<Variant[]> {
    return this.productAPI.GetVariantsFromBarcode(barcode);
  }

  setSmartFilterDataTableVariants(variants: Variant[]) {
    this._smartFilerDataTableVariants.next(variants);
  }

  public fetchNonHydratedDisplayAttributeGroupsIfEmpty() {
    const toFetchOrNotToFetch = (existingGdags: DisplayAttributeGroup[]) => iif(
      () => !existingGdags?.length,
      this.productAPI.GetDisplayAttributeGroups(),
      of(-1)
    );
    const filterNonFetch = (val: number | DisplayAttributeGroup[]): val is DisplayAttributeGroup[] => val !== -1;
    this.displayAttributeGroups$.pipe(
      take(1),
      switchMap(toFetchOrNotToFetch),
      take(1),
      filter(filterNonFetch)
    ).subscribe(groups => {
      this.connectAndSortDisplayAttributeGroups(groups);
    });
  }

  public fetchNonHydratedBrandAssetGroupsIfEmpty() {
    const toFetchOrNotToFetch = (existingBrands: AssetGroup[]) => iif(
      () => !existingBrands?.length,
      this.productAPI.GetBrandAssetGroups(),
      of(-1)
    );
    const filterNonFetch = (val: number | AssetGroup[]): val is AssetGroup[] => val !== -1;
    this._brands.pipe(
      take(1),
      switchMap(toFetchOrNotToFetch),
      take(1),
      filter(filterNonFetch)
    ).subscribe(brands => this._brands.next(brands));
  }

  public getHydratedDisplayAttributeGroups(ids: string) {
    return this.productAPI.GetDisplayAttributeGroups(ids).pipe(
      tap(gdags => {
        this.setDisplayAttributeAndAssetGroups(gdags);
      })
    );
  }

  public getHydratedAssetGroups(
    displayGroupId: string,
    replaceAssetGroups: boolean = false,
    updateLocal: boolean = true
  ): Observable<AssetGroup[]> {
    return this.productAPI.GetAssetGroups(displayGroupId, '').pipe(
      tap(ags => {
        if (updateLocal) this.setDisplayAttributeAndAssetGroups(null, ags, replaceAssetGroups);
      })
    );
  }

  public getHydratedBrandAssetGroups(brandDisplayGroupIds: string): Observable<AssetGroup[]> {
    return this.productAPI.GetAssetGroups('', brandDisplayGroupIds).pipe(
      tap(brands => this.setBrands(brands))
    );
  }

  public getHydratedDisplayAttributeAndAssetGroups(ids: string[]) {
    const idString = ids.join(',');
    const hydrateGDAGReq$ = this.productAPI.GetDisplayAttributeGroups(idString);
    return forkJoin([
      hydrateGDAGReq$,
      this.productAPI.GetAssetGroups(ids.join(','), '')
    ]).pipe(
      tap(([gdags, ags]) => {
        this.setDisplayAttributeAndAssetGroups(gdags, ags);
      })
    );
  }

  public createAssetGroup(req: CreateAssetGroupRequest): Observable<AssetGroup[]> {
    return this.productAPI.CreateAssetGroup(req).pipe(
      tap(ags => {
        this.setDisplayAttributeAndAssetGroups(null, ags);
      })
    );
  }

  public updateAssetGroup(req: AssetGroup): Observable<AssetGroup[]> {
    return this.productAPI.UpdateAssetGroup(req).pipe(
      tap(ags => {
        this.setDisplayAttributeAndAssetGroups(null, ags);
      })
    );
  }

  public uploadAsset(assetGroup: AssetGroup, f: BudsenseFile) {
    return MediaUtils.newAssetIsUnique(this.imageAPI, assetGroup, f).pipe(
      switchMap(newAssetIsUnique => {
        if (!newAssetIsUnique) return of([]);
        const uploadReq = ProductDomainModel.getUploadGroupAssetReq(assetGroup.id, f);
        return this.imageAPI.GenerateUploadUrl(uploadReq).pipe(
          switchMap((signedUploadUrl) => {
            return this.imageAPI.PutImageUploadUrl(signedUploadUrl.url, f.url.toString(), uploadReq.fileName).pipe(
              // provide delay based on file size
              delay(f.getUploadDelay()),
              switchMap((_) => {
                return this.pollForUploadedAssets(assetGroup, f, 10, 0);
              }),
            );
          })
        );
      })
    );
  }

  pollForUploadedAssets(
    assetGroup: AssetGroup,
    f: BudsenseFile,
    numOfRetries: number,
    delayTime: number
  ): Observable<AssetGroup[]> {
    let pollingFunc$ = defer(() => this.getHydratedAssetGroups(assetGroup?.displayAttributeGroupId, false, false));
    if (assetGroup?.isBrand) {
      pollingFunc$ = defer(() => this.getHydratedBrandAssetGroups(assetGroup?.displayAttributeGroupId));
    }
    return pollingFunc$.pipe(
      delay(delayTime),
      switchMap(ags => {
        const ag = ags.find(g => g.id === assetGroup.id);
        const uploadedAssetIndex = ag?.assets?.findIndex(a => a.fileName === f.name);
        if (uploadedAssetIndex > -1) {
          numOfRetries = 0;
        }
        if (numOfRetries > 0) {
          return this.pollForUploadedAssets(assetGroup, f, numOfRetries - 1, 3500);
        }
        numOfRetries = 0;
        return of(ags);
      })
    );
  }

  public deleteAssetGroup(req: AssetGroup): Observable<AssetGroup[]> {
    const displayGroupId = req.displayAttributeGroupId;
    return this.productAPI.DeleteAssetGroup(req).pipe(
      switchMap((_) => {
        return this.getHydratedAssetGroups(displayGroupId, true);
      })
    );
  }

  public deleteBrandAssetGroup(brand: AssetGroup): Observable<string> {
    return this.productAPI.DeleteAssetGroup(brand).pipe(
      tap(_ => {
        this.removeBrand(brand);
        this.removeAssociatedBrandGroupFromAssetGroups(brand);
      })
    );
  }

  public deleteBrandGroupAsset(brandGroupId: string, asset: Asset): Observable<AssetGroup[]> {
    return this.imageAPI.deleteAsset(asset?.id, asset?.md5Hash).pipe(
      switchMap((_) => {
        return this.getHydratedBrandAssetGroups(brandGroupId);
      })
    );
  }

  public deleteAssetGroupAsset(displayGroupId: string, asset: Asset): Observable<AssetGroup[]> {
    return this.imageAPI.deleteAsset(asset?.id, asset?.md5Hash).pipe(
      switchMap((_) => {
        return this.getHydratedAssetGroups(displayGroupId, true);
      })
    );
  }

  public createDisplayAttributeGroup(req: DisplayAttributeGroup): Observable<DisplayAttributeGroup> {
    return this.productAPI.CreateDisplayAttributeGroup(req).pipe(tap(gdag => {
      this.setDisplayAttributeAndAssetGroups([gdag]);
      if (gdag.variants?.length > 0) {
        this.updateAssignedVariants(gdag.variants);
      }
    }));
  }

  /**
   * this is the endpoint to use when updating groupDetails within a DisplayAttributeGroup object
   */
  public updateDisplayAttributeGroup(
    req: DisplayAttributeGroup,
    variantToRemove?: Variant
  ): Observable<DisplayAttributeGroup> {
    if (!!variantToRemove) {
      this.unassignVariant(variantToRemove);
      const i = req.variants?.findIndex(v => v.barcode === variantToRemove.barcode);
      if (i > -1) {
        req.variants.splice(i, 1);
      }
    }
    return this.productAPI.UpdateDisplayAttributeGroup(req).pipe(
      map(gdag => {
        this.setDisplayAttributeAndAssetGroups([gdag]);
        this.updateAssignedVariants(gdag.variants);
        return gdag;
      })
    );
  }

  public deleteDisplayAttributeGroup(req: DisplayAttributeGroup): Observable<string> {
    return this.productAPI.DeleteDisplayAttributeGroup(req).pipe(tap(_ => {
      const currentGdags = this._displayAttributeGroups.getValue()?.shallowCopy() || [];
      const i = currentGdags?.findIndex(cgd => cgd.id === req.id);
      if (i > -1) {
        currentGdags.splice(i, 1);
      }
      const updatedUnassignedVariants = req.variants?.shallowCopy()?.map(v => {
        v.displayAttributeGroupId = '';
        return v;
      });
      this.updateAssignedVariants(updatedUnassignedVariants);
      this.connectToDisplayAttributeGroups(currentGdags);
    }));
  }

  public createDisplayAttributeGroupDetails(
    req: DisplayAttributeGroupDetails
  ): Observable<DisplayAttributeGroupDetails> {
    return this.productAPI.CreateDisplayAttributeGroupDetails(req).pipe(
      tap(details => {
        this.displayAttributeGroups$.once(groups => {
          const affectedGroup = groups?.find(g => g?.id === details?.displayAttributeGroupId);
          if (exists(affectedGroup)) {
            const updated = window?.injector?.Deserialize?.instanceOf(DisplayAttributeGroup, affectedGroup);
            updated?.updateDisplayAttrGroupDetails(details);
            this.setDisplayAttributeAndAssetGroups([updated]);
          }
        });
      })
    );
  }

  public deleteDisplayAttributeGroupDetails(
    req: DisplayAttributeGroupDetails
  ): Observable<string> {
    return this.productAPI.DeleteDisplayAttributeGroupDetails(req).pipe(
      tap(() => {
        this.displayAttributeGroups$.once(groups => {
          const affectedGroup = groups?.find(g => g?.id === req?.displayAttributeGroupId);
          if (exists(affectedGroup)) {
            const updated = window?.injector?.Deserialize?.instanceOf(DisplayAttributeGroup, affectedGroup);
            updated?.removeDisplayAttrGroupDetails(req);
            this.setDisplayAttributeAndAssetGroups([updated]);
          }
        });
      })
    );
  }

  public addNewBrands(newBrands: AssetGroup[]) {
    const brandsShallowCopy = this._brands.getValue()?.shallowCopy() || [];
    brandsShallowCopy.push(...newBrands);
    this._brands.next(brandsShallowCopy);
  }

  public updateBrands(updatedBrands: AssetGroup[]) {
    this.brands$.once(brands => {
      const brandsShallowCopy = brands?.shallowCopy() || [];
      updatedBrands?.forEach(updatedBrand => {
        const brandIndex = brandsShallowCopy?.findIndex(brand => brand?.id === updatedBrand?.id);
        if (brandIndex > -1) {
          brandsShallowCopy.splice(brandIndex, 1, updatedBrand);
        }
      });
      this._brands.next(brandsShallowCopy);
    });
  }

  private updateAssignedVariants(variants: Variant[]) {
    // Find and replace any UBVs that come back from the new GDAG
    this.universalVariants$.pipe(take(1)).subscribe(ubvs => {
      const updatedUBVs = ubvs?.shallowCopy() ?? [];
      variants.forEach(v => {
        const ubvToReplaceIndex = updatedUBVs.findIndex(ubv => ubv.barcode === v.barcode);
        if (ubvToReplaceIndex > -1) {
          updatedUBVs.splice(ubvToReplaceIndex, 1, v);
        } else {
          updatedUBVs.push(v);
        }
      });
      this._universalVariants.next(updatedUBVs);
    });
  }

  private removeAssociatedBrandGroupFromAssetGroups(brand: AssetGroup) {
    // Find and replace any UBVs that come back from the new GDAG
    this.displayAttributeGroups$.once(gdags => {
      const updatedGDAGs = gdags?.shallowCopy() ?? [];
      updatedGDAGs.forEach(gdag => {
        if (gdag.assetGroups?.some(ag => ag.brandGroupId === brand.id)) {
          const updatedGDAG = window?.injector?.Deserialize?.instanceOf(DisplayAttributeGroup, gdag);
          const updatedAssetGroups = updatedGDAG.assetGroups?.shallowCopy() ?? [];
          updatedAssetGroups.forEach(ag => {
            ag.brandGroupId = '';
            ag.brand = null;
          });
          updatedGDAG.updateAssetGroups(updatedAssetGroups);
          updatedGDAGs.splice(updatedGDAGs.findIndex(g => g.id === gdag.id), 1, updatedGDAG);
        }
      });
      this.connectToDisplayAttributeGroups(updatedGDAGs);
    });
  }

  private removeBrand(brand: AssetGroup) {
    this.brands$.once(brands => {
      const brandsShallowCopy = brands?.shallowCopy() || [];
      const brandIndex = brandsShallowCopy?.findIndex(b => b.id === brand.id);
      if (brandIndex > -1) {
        brandsShallowCopy.splice(brandIndex, 1);
      }
      this._brands.next(brandsShallowCopy);
    });
  }

  setDisplayAttributeAndAssetGroups(
    gdags: DisplayAttributeGroup[],
    ags?: AssetGroup[],
    replaceAssetGroups?: boolean
  ) {
    this.displayAttributeGroups$.once(currentGdags => {
      const updatedGDAGs = currentGdags?.shallowCopy() || [];
      const gdagIdsForReplacingAgs = ags?.map(ag => ag?.displayAttributeGroupId);
      gdagIdsForReplacingAgs?.forEach(id => {
        const gdag = updatedGDAGs?.find(g => g.id === id);
        const currentGdag = window?.injector?.Deserialize?.instanceOf(DisplayAttributeGroup, gdag);
        if (!!currentGdag) {
          replaceAssetGroups
            ? currentGdag.assetGroups = []
            : currentGdag.assetGroups = currentGdag?.assetGroups?.shallowCopy() || [];
          updatedGDAGs?.splice(updatedGDAGs?.findIndex(g => g.id === id), 1, currentGdag);
        }
      });

      gdags?.forEach(gdag => {
        const currentGdagIndex = updatedGDAGs?.findIndex(g => g.id === gdag.id);
        const gdagDeepCopy = window?.injector?.Deserialize?.instanceOf(DisplayAttributeGroup, gdag);
        if (currentGdagIndex > -1) {
          updatedGDAGs.splice(currentGdagIndex, 1, gdagDeepCopy);
        } else {
          updatedGDAGs.push(gdagDeepCopy);
        }
      });

      updatedGDAGs?.forEach(gdag => {
        const gdagAssetGroups = ags?.filter(ag => ag.displayAttributeGroupId === gdag.id);
        if (!!gdagAssetGroups && gdagAssetGroups?.length > 0) {
          // Only need to update the asset groups for any display groups it is associated with
          gdag?.updateAssetGroups(gdagAssetGroups);
        }
      });

      this.connectAndSortDisplayAttributeGroups(updatedGDAGs);
    });
  }

  private setBrands(brands: AssetGroup[]) {
    const currentBrandsShallowCopy = this._brands.getValue()?.shallowCopy() || [];
    brands?.forEach(brand => {
      const brandIndex = currentBrandsShallowCopy?.findIndex(b => b.id === brand.id);
      if (brandIndex > -1) {
        currentBrandsShallowCopy.splice(brandIndex, 1, brand);
      }
    });
    this._brands.next(currentBrandsShallowCopy);
  }

  private unassignVariant(v: Variant) {
    v.displayAttributeGroupId = '';
    const currentUVs = this._universalVariants.getValue();
    const i = currentUVs?.findIndex(uv => uv.barcode === v.barcode);
    if (i > -1) {
      currentUVs[i] = v;
      this._universalVariants.next(currentUVs);
    }
  }

}
