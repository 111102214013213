import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, distinctUntilChanged, map, shareReplay, take } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { BaseModalViewModel } from '../../../../../models/base/base-modal-view-model';
import { MenuDomainModel } from '../../../../../domainModels/menu-domain-model';
import { SectionBlueprintCategory } from '../../../../../models/menu/dto/section-blueprint-category';
import { SearchUtils } from '../../../../../utils/search-utils';
import { SortUtils } from '../../../../../utils/sort-utils';

@Injectable()
export class FilterByCategoryModalViewModel extends BaseModalViewModel {

  constructor(
    private menuDomainModel: MenuDomainModel,
    private activeModal: NgbActiveModal,
    router: Router,
    ngbModal: NgbModal
  ) {
    super(router, ngbModal);
  }

  public categories$ = this.menuDomainModel.sectionBlueprintCategories$;
  protected _searchText: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public searchText$ = this._searchText.pipe(debounceTime(100), distinctUntilChanged());

  private _selectedCategories = new BehaviorSubject<SectionBlueprintCategory[]>(null);
  public selectedCategories$ = this._selectedCategories as Observable<SectionBlueprintCategory[]>;
  public selectedCategoryIds$ = this.selectedCategories$.pipe(
    map(selectedCategories => selectedCategories?.map(sc => sc?.id))
  );

  public categoriesFilteredBySearchString$ = combineLatest([
    this.categories$,
    this.searchText$.pipe(distinctUntilChanged())
  ]).pipe(
    debounceTime(100),
    map(([categories, searchText]) => (!!searchText
        ? SearchUtils.searchCategories(categories, searchText)
        : categories)),
    map(filteredCategories => filteredCategories?.sort(SortUtils.sortSectionBlueprintCategory)),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public setSearchText = (searchText: string): void => this._searchText.next(searchText);
  public setSelectedCategories = (selectedCategories: SectionBlueprintCategory[]) => {
    this._selectedCategories.next(selectedCategories);
  };

  public addToSelectedCategories(addedCategoryId: string) {
    combineLatest([
      this.categories$,
      this.selectedCategories$
    ]).pipe(take(1)).subscribe(([categories, selectedCategories]) => {
      const categoryToAdd = categories?.find(category => category?.id === addedCategoryId);
      if (!!categoryToAdd) {
        selectedCategories.push(categoryToAdd);
        this._selectedCategories.next(selectedCategories);
      }
    });
  }

  public removeFromSelectedCategories(categoryIdToRemove: string) {
    this.selectedCategories$.pipe(take(1)).subscribe(selectedCategories => {
      const removedCategoryIndex = selectedCategories?.findIndex(category => category?.id === categoryIdToRemove);
      if (removedCategoryIndex > -1) {
        selectedCategories.splice(removedCategoryIndex, 1);
        this._selectedCategories.next(selectedCategories);
      }
    });
  }

  public finishedFiltering() {
    this.selectedCategories$.pipe(take(1)).subscribe(selectedCategories => {
      this.activeModal.close(selectedCategories);
    });
  }

  public clearFilters() {
    this._selectedCategories.next([]);
    this._searchText.next('');
  }

}
