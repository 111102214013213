import { UniquelyIdentifiable } from '../../protocols/uniquely-identifiable';
import { Deserializable } from '../../protocols/deserializable';
import { SectionColumnConfigKey } from '../../../utils/section-column-config-key-type';
import { SectionColumnConfigDefaultState } from '../../../utils/section-column-config-default-state-type';
import { SectionColumnConfigDataValue } from '../../../utils/section-column-config-data-value-type';
import { FontStyle } from '../../../utils/font-style-type';
import { AdminSectionColumnConfigKey } from '../../../utils/admin-section-column-config-key-type';

export class SectionColumnConfig implements UniquelyIdentifiable, Deserializable {

  public key: string = null;
  public defaultState: SectionColumnConfigDefaultState = SectionColumnConfigDefaultState.Unknown;
  public columnName: string = null;
  public columnWidth: number = null;
  // Currently, only used for Secondary Price
  public dataValue: SectionColumnConfigDataValue = SectionColumnConfigDataValue.NotSet;
  public fontStyle: string = FontStyle.Normal;
  public fontColor: string = null;
  public columnColor: string = null;
  // ColumnOpacity is saved in the DB as a float 0.0-1.0 but is shown to the user as a % between 0-100
  public columnOpacity: number = null;
  // Not from API
  public hasCustomStyle: boolean = false;

  static getDefaultStateOptions(key: AdminSectionColumnConfigKey): SectionColumnConfigDefaultState[] {
    switch (key) {
      case AdminSectionColumnConfigKey.Quantity:
      case AdminSectionColumnConfigKey.Size:
      case AdminSectionColumnConfigKey.THC:
      case AdminSectionColumnConfigKey.CBD:
      case AdminSectionColumnConfigKey.Badges:
        return [
          SectionColumnConfigDefaultState.On,
          SectionColumnConfigDefaultState.Off,
          SectionColumnConfigDefaultState.Auto,
          SectionColumnConfigDefaultState.Disabled,
        ];
      default:
        return [
          SectionColumnConfigDefaultState.On,
          SectionColumnConfigDefaultState.Off,
          SectionColumnConfigDefaultState.Disabled,
        ];
    }
  }

  static getDataValueOptions(key: SectionColumnConfigKey): SectionColumnConfigDataValue[] {
    switch (key) {
      case SectionColumnConfigKey.SecondaryPrice:
        return [
          SectionColumnConfigDataValue.SecondaryPrice,
          SectionColumnConfigDataValue.PricePerUOM,
          SectionColumnConfigDataValue.OriginalPrice,
          SectionColumnConfigDataValue.SaleOriginalPrice,
          SectionColumnConfigDataValue.OriginalAndSalePrice,
          SectionColumnConfigDataValue.TaxesInPrice,
          SectionColumnConfigDataValue.TaxesInRoundedPrice,
          SectionColumnConfigDataValue.PreTaxPrice
        ];
    }
    return [];
  }

  static getGeneralColumnKeys(): AdminSectionColumnConfigKey[] {
    return [
      AdminSectionColumnConfigKey.Asset,
      AdminSectionColumnConfigKey.Badges,
      AdminSectionColumnConfigKey.Brand,
      AdminSectionColumnConfigKey.StrainType,
      AdminSectionColumnConfigKey.Quantity,
      AdminSectionColumnConfigKey.Size,
      AdminSectionColumnConfigKey.QuantityAndSize,
      AdminSectionColumnConfigKey.Stock
    ];
  }

  static getCannabinoidColumnKeys(): AdminSectionColumnConfigKey[] {
    return [
      AdminSectionColumnConfigKey.THC,
      AdminSectionColumnConfigKey.CBD,
    ];
  }

  static getSecondaryCannabinoidColumnKeys(): AdminSectionColumnConfigKey[] {
    return [
      AdminSectionColumnConfigKey.SecondaryCannabinoids,
    ];
  }

  static getTerpeneColumnKeys(): AdminSectionColumnConfigKey[] {
    return [
      AdminSectionColumnConfigKey.TopTerpene,
      AdminSectionColumnConfigKey.TotalTerpene,
    ];
  }

  static getIndividualTerpenesColumnKeys(): AdminSectionColumnConfigKey[] {
    return [
      AdminSectionColumnConfigKey.IndividualTerpenes,
    ];
  }

  static getPricingColumnKeys(): AdminSectionColumnConfigKey[] {
    return [
      AdminSectionColumnConfigKey.Price,
      AdminSectionColumnConfigKey.SecondaryPrice,
    ];
  }

  getUniqueIdentifier(): string {
    const key = this.key;
    const defaultState = this.defaultState;
    const columnName = this.columnName ?? '';
    const columnWidth = this.columnWidth?.toString() ?? '';
    const dataValue = this.dataValue;
    const fontStyle = this.fontStyle;
    const fontColor = this.fontColor ?? '';
    const columnColor = this.columnColor ?? '';
    const columnOpacity = this.columnOpacity?.toString() ?? '';
    const hasCustomStyle = this.hasCustomStyle;
    return `${key}
      -${defaultState}
      -${columnName}
      -${columnWidth}
      -${dataValue}
      -${fontStyle}
      -${fontColor}
      -${columnColor}
      -${columnOpacity}
      -${hasCustomStyle}`;
  }

  onDeserialize() {
    this.defaultState = this.defaultState || SectionColumnConfigDefaultState.Unknown;
    if (this.columnWidth === 0) {
      this.columnWidth = null;
    }
    if (this.columnOpacity === 0) {
      this.columnOpacity = null;
    }
    this.dataValue = this.dataValue || SectionColumnConfigDataValue.NotSet;
    this.hasCustomStyle = this.checkForCustomStyle();
  }

  private checkForCustomStyle(): boolean {
    return this.fontStyle !== FontStyle.Normal ||
      this.fontColor !== '' ||
      this.columnColor !== '' ||
      this.columnOpacity !== null;
  }

  // DTO === Data Transfer Object
  translateToDTO(): SectionColumnConfig | null {
    if (!this.defaultState || this.defaultState === SectionColumnConfigDefaultState.Disabled) {
      return null;
    }
    return this;
  }

}
