import { Injectable } from '@angular/core';
import { BehaviorSubject, defer, Observable } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HydratedSmartFilter } from '../../../../models/automation/hydrated-smart-filter';
import { BaseViewModel } from '../../../../models/base/base-view-model';
import { ProductDomainModel } from '../../../../domainModels/product-domain-model';
import { SmartFiltersDomainModel } from '../../../../domainModels/smart-filters-domain-model';
import { ToastService } from '../../../../services/toast-service';
import { SessionService } from '../../../../services/session-service';
import { ConfirmationModalComponent } from '../../../shared/components/confirmation-modal/confirmation-modal.component';
import { ModalUtils } from '../../../../utils/modal-utils';
import { ConfirmationOptions } from '../../../../models/shared/stylesheet/confirmation-options';
import { AddEditSmartFilterModalOpenedFrom } from '../../../../models/automation/enum/add-edit-smart-filter-modal-opened-from';
import { exists } from '../../../../functions/exists';

@Injectable()
export class AddEditSmartFilterViewModel extends BaseViewModel {

  // Edit Form
  public smartFilterReq = new HydratedSmartFilter();
  public existingSmartFilter = new BehaviorSubject<HydratedSmartFilter>(null);
  public isEditing: boolean = false;
  public changesMade = new BehaviorSubject<boolean>(false);
  public canSubmitForm = new BehaviorSubject<boolean>(false);
  public hydrateObject: boolean = false;

  public _smartFilterReq = new BehaviorSubject<HydratedSmartFilter>(null);

  public hasSystemIdentifier$ = this.existingSmartFilter.pipe(
    map(smartFilter => exists(smartFilter?.systemIdentifier))
  );

  // Accordion
  private _openProductAccordion = new BehaviorSubject<boolean>(false);
  public openProductAccordion$ = this._openProductAccordion as Observable<boolean>;

  private _openCannabinoidAccordion = new BehaviorSubject<boolean>(false);
  public openCannabinoidAccordion$ = this._openCannabinoidAccordion as Observable<boolean>;

  private _openTerpeneAccordion = new BehaviorSubject<boolean>(false);
  public openTerpeneAccordion$ = this._openTerpeneAccordion as Observable<boolean>;

  private _openAdvancedAccordion = new BehaviorSubject<boolean>(false);
  public openAdvancedAccordion$ = this._openAdvancedAccordion as Observable<boolean>;

  // Opened From
  public _openedFrom = new BehaviorSubject<AddEditSmartFilterModalOpenedFrom>(null);
  public openedFrom$ = this._openedFrom as Observable<AddEditSmartFilterModalOpenedFrom>;
  connectToOpenedFrom = (openedFrom: AddEditSmartFilterModalOpenedFrom) => this._openedFrom.next(openedFrom);

  listenForProperties = this.existingSmartFilter.notNull().subscribeWhileAlive({
    owner: this,
    next: sf => {
      if (sf?.hasProductPropertySet()) this._openProductAccordion.next(true);
      if (sf?.hasCannabinoidPropertySet()) this._openCannabinoidAccordion.next(true);
      if (sf?.hasTerpenePropertySet()) this._openTerpeneAccordion.next(true);
      if (sf?.hasAdvancedPropertySet()) this._openAdvancedAccordion.next(true);
    }
  });

  constructor(
    protected activeModal: NgbActiveModal,
    private productDomainModel: ProductDomainModel,
    private smartFiltersDomainModel: SmartFiltersDomainModel,
    private sessionService: SessionService,
    private toastService: ToastService,
    private ngbModal: NgbModal,
  ) {
    super();
  }

  saveChanges() {
    const lm = this.isEditing ? 'Saving Changes' : 'Creating Smart Filter';
    this.existingSmartFilter.pipe(
      take(1),
      tap(_ => this._loadingOpts.addRequest(lm)),
      switchMap(existingSmartFilter => {
        const updatePipe$ = defer(() => this.smartFiltersDomainModel.updateSmartFilter(existingSmartFilter));
        const createPipe$ = defer(() => this.smartFiltersDomainModel.createSmartFilter(this.smartFilterReq));
        return this.isEditing ? updatePipe$ : createPipe$;
      }),
      take(1)
    ).subscribe(returnedSmartFilter => {
      this._loadingOpts.removeRequest(lm);
      const successMsg = this.isEditing ? 'Your smart filter was updated' : 'Your smart filter was created';
      this.toastService.publishSuccessMessage(successMsg, 'Success!');
      this.activeModal.close(returnedSmartFilter);
    }, err => {
      this._loadingOpts.removeRequest(lm);
      this.toastService.publishErrorMessage(err, 'Error');
    });
  }

  showDeleteModal() {
    // Note - unable to use modalService.openGenericConfirmationModal in this scenario
    // because it causes a circular dependency
    const opts = new ConfirmationOptions();
    opts.title = 'DANGER ZONE: Delete Smart Filter';
    opts.bodyText = `DANGER. Are you sure you want to delete ${this.existingSmartFilter.getValue().name}? ` +
      'Deleting a smart filter will affect all customers that are using it!';
    opts.cancelText = 'Cancel';
    opts.continueText = 'Continue';
    const modalRef = this.ngbModal.open(
      ConfirmationModalComponent,
      ModalUtils.confirmationModalOptions()
    );
    const compInstance = modalRef.componentInstance as ConfirmationModalComponent;
    compInstance.setConfirmationOptions(opts);
    modalRef.result.then((cont) => {
      if (cont) { this.deleteSmartFilter(); }
    });
  }

  deleteSmartFilter() {
    const lm = 'Deleting your Smart Filter';
    this._loadingOpts.addRequest(lm);
    this.smartFiltersDomainModel.deleteSmartFilter(this.existingSmartFilter.getValue()).subscribe(_ => {
      this.toastService.publishSuccessMessage('Your Smart Filter was deleted', 'Smart Filters');
      this._loadingOpts.removeRequest(lm);
      this.activeModal.close();
    }, (err) => {
      this.toastService.publishError(err);
      this._loadingOpts.removeRequest(lm);
    });
  }

  formChanges(isPristine: boolean) {
    this.changesMade.next(false);
    if (isPristine) {
      this.changesMade.next(true);
    }
  }

  setCanSubmit(canSubmit: boolean) {
    this.canSubmitForm.next(canSubmit);
  }

  setFormObject(hydratedFilters: HydratedSmartFilter[]) {
    if (this.isEditing) {
      this.existingSmartFilter.next(hydratedFilters[0]);
    } else {
      this.smartFilterReq = hydratedFilters[0];
      this._smartFilterReq.next(hydratedFilters[0]);
    }
  }

}
