import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../../../../models/base/base-view-model';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { SectionColumnConfig } from '../../../../../../../models/menu/dto/section-column-config';
import { SectionColumnConfigKey } from '../../../../../../../utils/section-column-config-key-type';
import { SectionLayoutType } from '../../../../../../../utils/section-layout-type';
import { map, switchMap } from 'rxjs/operators';
import { ColumnOptionsModalViewModel } from '../../column-options-modal-view-model';
import { SectionColumnConfigDefaultState, SectionColumnConfigDefaultStateType } from '../../../../../../../utils/section-column-config-default-state-type';
import { InformationItem } from '../../../../../../../models/shared/information-item';

@Injectable()
export class ColumnGeneralOptionsFormViewModel extends BaseViewModel {

  constructor() {
    super();
  }

  private noAutoSectionDefaultStateOptions$ = window.types.noAutoSectionColumnConfigDefaultStates$;
  private autoSectionDefaultStateOptions$ = window.types.autoSectionColumnConfigDefaultStates$;
  private disableSectionDefaultStateOptions$ = window.types.disabledSectionColumnConfigDefaultStates$;

  private selectableDefaultStateMap =
    new Map<SectionColumnConfigKey, Observable<SectionColumnConfigDefaultStateType[]>> ([
      [SectionColumnConfigKey.Asset, this.noAutoSectionDefaultStateOptions$],
      [SectionColumnConfigKey.Badges, this.autoSectionDefaultStateOptions$],
      [SectionColumnConfigKey.Brand, this.autoSectionDefaultStateOptions$],
      [SectionColumnConfigKey.StrainType, this.noAutoSectionDefaultStateOptions$],
      [SectionColumnConfigKey.Quantity, this.autoSectionDefaultStateOptions$],
      [SectionColumnConfigKey.Size, this.autoSectionDefaultStateOptions$],
      [SectionColumnConfigKey.QuantityAndSize, this.autoSectionDefaultStateOptions$],
      [SectionColumnConfigKey.CBD, this.autoSectionDefaultStateOptions$],
      [SectionColumnConfigKey.THC, this.autoSectionDefaultStateOptions$],
      [SectionColumnConfigKey.CBDA, this.autoSectionDefaultStateOptions$],
      [SectionColumnConfigKey.CBG, this.autoSectionDefaultStateOptions$],
      [SectionColumnConfigKey.CBGA, this.autoSectionDefaultStateOptions$],
      [SectionColumnConfigKey.CBL, this.autoSectionDefaultStateOptions$],
      [SectionColumnConfigKey.CBLA, this.autoSectionDefaultStateOptions$],
      [SectionColumnConfigKey.CBN, this.autoSectionDefaultStateOptions$],
      [SectionColumnConfigKey.CBNA, this.autoSectionDefaultStateOptions$],
      [SectionColumnConfigKey.CBT, this.autoSectionDefaultStateOptions$],
      [SectionColumnConfigKey.CBC, this.autoSectionDefaultStateOptions$],
      [SectionColumnConfigKey.CBCA, this.autoSectionDefaultStateOptions$],
      [SectionColumnConfigKey.CBCV, this.autoSectionDefaultStateOptions$],
      [SectionColumnConfigKey.CBDV, this.autoSectionDefaultStateOptions$],
      [SectionColumnConfigKey.THC8, this.autoSectionDefaultStateOptions$],
      [SectionColumnConfigKey.THC9, this.autoSectionDefaultStateOptions$],
      [SectionColumnConfigKey.THCA, this.autoSectionDefaultStateOptions$],
      [SectionColumnConfigKey.THCV, this.autoSectionDefaultStateOptions$],
      [SectionColumnConfigKey.TAC, this.autoSectionDefaultStateOptions$],
      [SectionColumnConfigKey.TopTerpene, this.autoSectionDefaultStateOptions$],
      [SectionColumnConfigKey.TotalTerpene, this.autoSectionDefaultStateOptions$],
      [SectionColumnConfigKey.AlphaBisabolol, this.autoSectionDefaultStateOptions$],
      [SectionColumnConfigKey.AlphaPinene, this.autoSectionDefaultStateOptions$],
      [SectionColumnConfigKey.BetaCaryophyllene, this.autoSectionDefaultStateOptions$],
      [SectionColumnConfigKey.BetaEudesmol, this.autoSectionDefaultStateOptions$],
      [SectionColumnConfigKey.BetaMyrcene, this.autoSectionDefaultStateOptions$],
      [SectionColumnConfigKey.BetaPinene, this.autoSectionDefaultStateOptions$],
      [SectionColumnConfigKey.Bisabolol, this.autoSectionDefaultStateOptions$],
      [SectionColumnConfigKey.Borneol, this.autoSectionDefaultStateOptions$],
      [SectionColumnConfigKey.Camphene, this.autoSectionDefaultStateOptions$],
      [SectionColumnConfigKey.CaryophylleneOxide, this.autoSectionDefaultStateOptions$],
      [SectionColumnConfigKey.Delta3Carene, this.autoSectionDefaultStateOptions$],
      [SectionColumnConfigKey.Eucalyptol, this.autoSectionDefaultStateOptions$],
      [SectionColumnConfigKey.Geraniol, this.autoSectionDefaultStateOptions$],
      [SectionColumnConfigKey.Guaiol, this.autoSectionDefaultStateOptions$],
      [SectionColumnConfigKey.Humulene, this.autoSectionDefaultStateOptions$],
      [SectionColumnConfigKey.Linalool, this.autoSectionDefaultStateOptions$],
      [SectionColumnConfigKey.Limonene, this.autoSectionDefaultStateOptions$],
      [SectionColumnConfigKey.Ocimene, this.autoSectionDefaultStateOptions$],
      [SectionColumnConfigKey.ThreeCarene, this.autoSectionDefaultStateOptions$],
      [SectionColumnConfigKey.Terpinene, this.autoSectionDefaultStateOptions$],
      [SectionColumnConfigKey.Terpinolene, this.autoSectionDefaultStateOptions$],
      [SectionColumnConfigKey.TransNerolidol, this.autoSectionDefaultStateOptions$],
      [SectionColumnConfigKey.Valencene, this.autoSectionDefaultStateOptions$],
      [SectionColumnConfigKey.Price, this.noAutoSectionDefaultStateOptions$],
      [SectionColumnConfigKey.SecondaryPrice, this.noAutoSectionDefaultStateOptions$]
    ]);

  private _formColumnConfig = new BehaviorSubject<SectionColumnConfig>(null);
  public formColumnConfig$ = this._formColumnConfig as Observable<SectionColumnConfig>;
  connectToFormColumnConfig = (formColumnConfig: SectionColumnConfig) => this._formColumnConfig.next(formColumnConfig);

  private _columnSectionKey = new BehaviorSubject<SectionColumnConfigKey>(null);
  public columnSectionKey$ = this._columnSectionKey as Observable<SectionColumnConfigKey>;
  connectToColumnSectionKey = (columnSectionKey: SectionColumnConfigKey) => {
    this._columnSectionKey.next(columnSectionKey);
  };

  private _sectionLayoutType = new BehaviorSubject<SectionLayoutType>(null);
  public sectionLayoutType$ = this._sectionLayoutType as Observable<SectionLayoutType>;
  connectToSectionLayoutType = (sectionLayoutType: SectionLayoutType) => {
    this._sectionLayoutType.next(sectionLayoutType);
  };

  private _inputDisabled = new BehaviorSubject<boolean>(false);
  public inputDisabled$ = this._inputDisabled as Observable<boolean>;
  connectToInputDisabled = (inputDisabled: boolean) => this._inputDisabled.next(inputDisabled);

  public columnConfigDefaultState$ = this.formColumnConfig$.pipe(
    map(columnConfig => columnConfig?.defaultState)
  );

  public sectionDefaultStateOptions$ = combineLatest([
    this.sectionLayoutType$,
    this.columnSectionKey$
  ]).pipe(
    switchMap(([sectionLayoutType, columnSectionKey]) => {
      if (ColumnOptionsModalViewModel.disabledForGridLayout(columnSectionKey, sectionLayoutType)) {
        return this.disableSectionDefaultStateOptions$;
      }
      return this.selectableDefaultStateMap?.get(columnSectionKey);
    }),
    map(defaultStateOptions => {
      const unknownDefaultStateOpt = new SectionColumnConfigDefaultStateType(
        SectionColumnConfigDefaultState.Unknown,
        'Select Default State'
      );
      return [...defaultStateOptions, unknownDefaultStateOpt];
    })
  );

  public tooltip$ = this.columnSectionKey$.pipe(
    map(columnSectionKey => {
      switch (columnSectionKey) {
        case SectionColumnConfigKey.Asset:
          return 'Show product images in menu';
        case SectionColumnConfigKey.Badges:
          return 'Auto - only appears when 1 or more variants in section have a badge associated';
        case SectionColumnConfigKey.Brand:
          return 'Auto - only appears when 1 or more variants in section have a brand associated';
        case SectionColumnConfigKey.StrainType:
          return 'Show product strain type in menu';
        case SectionColumnConfigKey.Quantity:
          return 'Auto - only if 1 or more variants in section have QTY > 1. Will be disabled if menu is set to grid';
        case SectionColumnConfigKey.Size:
          return 'Auto - only if 2 or more sizes exist on section variants';
        case SectionColumnConfigKey.CBD:
          return 'Auto - only if 1 or more variants in section have cannabinoids and with a value of at least 1';
        case SectionColumnConfigKey.THC:
          return 'Auto - only if 1 or more variants in section have cannabinoids and with a value of at least 1';
        case SectionColumnConfigKey.Price:
          return 'Show Price in menu';
        case SectionColumnConfigKey.SecondaryPrice:
          return 'This column allows you to display a comparative price or display the price per unit of measure. ' +
            'Will be disabled if menu is set to grid.';
        default:
          return '';
      }
    })
  );

  public defaultStateInputDisabled$ = combineLatest([
    this.sectionLayoutType$,
    this.columnSectionKey$,
    this.formColumnConfig$
  ]).pipe(
    map(([layoutType, columnSectionKey, formColumnConfig]) => {
      return (layoutType?.isGrid() && columnSectionKey === SectionColumnConfigKey.Price) ||
        formColumnConfig?.defaultState === SectionColumnConfigDefaultState.Disabled;
    })
  );

  public hideColumnName$ = this.columnSectionKey$.pipe(
    map(key => {
      return key === SectionColumnConfigKey.Asset;
    })
  );

  public showColumnDataOptions$ = this.columnSectionKey$.pipe(
    map(key => {
      return key === SectionColumnConfigKey.Asset || key === SectionColumnConfigKey.SecondaryPrice;
    })
  );

  public columnDataOptionsLabel$ = this.columnSectionKey$.pipe(
    map(key => {
      if (key === SectionColumnConfigKey.Asset) {
        return 'Object Fit';
      } else if (key === SectionColumnConfigKey.SecondaryPrice) {
        return 'Use Value';
      }
    })
  );

  public columnDataOptionsToolTipModalTitle$ = this.columnSectionKey$.pipe(
    map(key => {
      switch (key) {
        case SectionColumnConfigKey.Asset:
          return 'Object Fit Information';
        case SectionColumnConfigKey.SecondaryPrice:
          return 'Secondary Price Information';
        case SectionColumnConfigKey.StrainType:
          return 'Strain Type Layout';
      }
    })
  );

  public columnDataOptionsDropdowns$ = combineLatest([
    this.columnSectionKey$,
    window.types.assetColumnConfigDataValues$,
    window.types.secondaryPriceColumnConfigDataValues$,
    window.types.strainTypeColumnConfigDataValues$
  ]).pipe(
    map(([
      key,
      assetDropdowns,
      secondaryPriceDropdowns,
      strainTypeDropdowns
    ]) => {
      if (key === SectionColumnConfigKey.Asset) {
        return assetDropdowns;
      } else if (key === SectionColumnConfigKey.SecondaryPrice) {
        return secondaryPriceDropdowns;
      } else if (key === SectionColumnConfigKey.StrainType) {
        return strainTypeDropdowns;
      }
    })
  );

  public columnDataOptionsToolTipModalItems$ = this.columnDataOptionsDropdowns$.pipe(
    map(dropdowns => {
      return dropdowns?.map(m => new InformationItem(m?.name, m?.getSelectionInformationDescription()));
    })
  );

  public widthRequired$ = this.inputDisabled$.pipe(
    map(inputDisabled => !inputDisabled)
  );

}
