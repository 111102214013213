import { MediaType } from '../enum/dto/media-type.enum';
import { MediaUtils } from '../../utils/media-utils';
import { UniquelyIdentifiable } from '../protocols/uniquely-identifiable';
import { Buffer } from 'buffer';
import { Observable } from 'rxjs';

export class BudsenseFile implements UniquelyIdentifiable {

  public name: string;
  public type: string;
  public url: string | ArrayBuffer;
  public size: number;
  public progress: number;
  public success: boolean;
  public failed: boolean;
  public failureError: string;
  public hasPreview: boolean;

  isImage(): boolean {
    return this.type.match(/image\/*/) !== null;
  }

  isVideo(): boolean {
    return this.type.match(/video\/*/) !== null;
  }

  replaceWithWebm() {
    // replace video file name and type with webm
    const fileType = this.name.split('.').pop();
    this.name = this.name.replace(`.${fileType}`, `.webm`);
    this.type = MediaType.WEBM;
  }

  getMediaType(): MediaType {
    return MediaUtils.getMediaType(this.name.split('.').pop().toLowerCase());
  }

  getSHA256Hash(): Observable<string> {
    const newFileContents = MediaUtils.stripFileContents(this.url.toString());
    const buff = Buffer.from(newFileContents, 'base64');
    const blob = new Blob([new Uint8Array(buff)]);
    return MediaUtils.getSHA256FromBlob(blob);
  }

  getUploadDelay(): number {
    let delayInSec: number;
    if (this.size < 1000000) {
      // Files under 1MB
      delayInSec = 5;
    } else if (this.size < 3000000) {
      // Files under 3MB
      delayInSec = 8;
    } else if (this.size < 7000000) {
      // Files under 7MB
      delayInSec = 10;
    } else {
      delayInSec = 12;
    }
    return delayInSec * 1000;
  }

  getUniqueIdentifier(): string {
    return `${this.name}
      -${this.type}
      -${this.url}
      -${this.size}
      -${this.progress}
      -${this.success}
      -${this.failed}
      -${this.failureError}
      -${this.hasPreview}`;
  }

}
