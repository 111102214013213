import { MediaType } from '../../enum/dto/media-type.enum';
import { UploadFilePath } from '../../enum/dto/upload-file.path';
import { Deserializable } from '../../protocols/deserializable';

export class GenerateUploadUrlRequest implements Deserializable {

  public mediaType: MediaType;
  public mediaId: string;
  public fileName: string;
  public mediaClass: UploadFilePath;
  public metadata: Map<string, string>;

  onDeserialize() {
  }

}
