import { Deserializable } from '../../protocols/deserializable';

export class ResetPasswordRequest implements Deserializable {

  public email: string = '';
  public password: string = '';
  public code: string = '';

  onDeserialize() {
  }

}
