import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { BaseComponent } from '../../../../../../../models/base/base-component';
import { SectionColumnConfigKey } from '../../../../../../../utils/section-column-config-key-type';
import { SectionColumnConfig } from '../../../../../../../models/menu/dto/section-column-config';
import { ViewModelConnector } from '@mobilefirstdev/base-angular';
import { ColumnGeneralOptionsFormViewModel } from './column-general-options-form-view-model';
import { SectionLayoutType } from '../../../../../../../utils/section-layout-type';
import { SectionColumnConfigDefaultState } from '../../../../../../../utils/section-column-config-default-state-type';
import { ReactiveFormDropDownComponent, ReactiveFormNumberComponent, ReactiveFormTextComponent } from '@mobilefirstdev/reactive-form';
import { ConfigChanges } from '../column-options-form/column-options-form-view-model';

@Component({
  selector: 'app-column-general-options-form',
  templateUrl: './column-general-options-form.component.html',
  styleUrls: ['./column-general-options-form.component.scss'],
  providers: [ColumnGeneralOptionsFormViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColumnGeneralOptionsFormComponent extends BaseComponent {

  constructor(
    public viewModel: ColumnGeneralOptionsFormViewModel
  ) {
    super();
  }

  @Input() @ViewModelConnector('connectToFormColumnConfig') formColumnConfig: SectionColumnConfig;
  @Input() @ViewModelConnector('connectToColumnSectionKey') columnSectionKey: SectionColumnConfigKey;
  @Input() formMergeKey: string = '';
  @Input() @ViewModelConnector('connectToInputDisabled') inputDisabled: boolean;
  @Input() @ViewModelConnector('connectToSectionLayoutType') sectionLayoutType: SectionLayoutType;
  @Output() defaultStateChanged: EventEmitter<SectionColumnConfigDefaultState>
    = new EventEmitter<SectionColumnConfigDefaultState>();
  @Output() changes: EventEmitter<ConfigChanges> = new EventEmitter<ConfigChanges>();

  @ViewChild('columnDefaultState') columnDefaultStateDropdown: ReactiveFormDropDownComponent;
  @ViewChild('columnName') columnNameInput: ReactiveFormTextComponent;
  @ViewChild('columnDataValue') columnDataValueDropdown: ReactiveFormDropDownComponent;
  @ViewChild('columnWidth') columnWidthInput: ReactiveFormNumberComponent;

  changeToDefaultState(newState: SectionColumnConfigDefaultState) {
    if (newState === SectionColumnConfigDefaultState.On || newState === SectionColumnConfigDefaultState.Auto) {
      const columnWidth = this.columnWidthInput.getMyValue();
      this.columnWidthInput
        .getSelfAsFormItem()
        .setValue(columnWidth || 10);
      const columnName = this.columnNameInput.getMyValue();
      this.columnNameInput
        .getSelfAsFormItem()
        .setValue(columnName);
      if (!!this.columnDataValueDropdown) {
        const columnDataValue = this.columnDataValueDropdown.getMyValue();
        if (!!columnDataValue) {
          this.columnDataValueDropdown
            .getSelfAsFormItem()
            .setValue(columnDataValue);
        }
      }
    } else {
      this.changes.emit({defaultState: newState});
    }
    this.defaultStateChanged.emit(newState);
  }

  changeToColumnWidth(newWidth: number) {
    const defaultStateFromForm = this.columnDefaultStateDropdown.getMyValue() as SectionColumnConfigDefaultState;
    this.changes.emit({defaultState: defaultStateFromForm, columnWidth: newWidth});
  }

}
