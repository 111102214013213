import { KeyValue } from '@angular/common';
import { AssetLibraryType } from './asset-library-type';
import { SectionSortOption, SectionSortType } from './section-sort-type';
import { SectionColumnConfigKey, SectionColumnConfigKeyType } from './section-column-config-key-type';
import { SyncType } from './sync-type-type';
import { MenuLabel } from './menu-label-type';
import type { Variant } from '../models/product/dto/variant';
import type { HydratedSmartFilter } from '../models/automation/hydrated-smart-filter';
import type { SelectableSmartFilter } from '../models/automation/protocols/selectable-smart-filter';
import type { Asset } from '../models/image/dto/asset';
import type { Label } from '../models/shared/label';
import type { VariantBadge } from '../models/product/dto/variant-badge';
import type { SectionBlueprintCategory } from '../models/menu/dto/section-blueprint-category';
import type { TypeDefinition } from './type-definition';
import type { DisplayAttributeGroup } from '../models/product/dto/display-attribute-group';
import type { DisplayAttributeGroupDetails } from '../models/product/dto/display-attribute-group-details';

/**
 * A simplified interface for sorting in JavaScript.
 *
 * Sort functions in JavaScript take in two parameters, a and b, and return a number.
 * If the number is negative, a is sorted before b.
 * If the number is positive, b is sorted before a.
 * If the number is zero, a and b are sorted in the same order.
 * Therefore, this is a handy dandy enum to transform these numbers into a human-readable format.
 */
export enum Move {
  ALeft = -1,
  ARight = 1,
  BLeft = 1,
  BRight = -1,
  Nothing = 0
}

export class SortUtils {

  static DEFAULT_SECTION_TERTIARY_SORT = SectionSortOption.TitleAsc;

  private static getMovement(num: number): Move {
    if (num < 0) return Move.ALeft;
    if (num > 0) return Move.ARight;
    return Move.Nothing;
  }

  static sharedSortId(s: string) {
    return s?.replace(/_((asc|ASC)|(desc|DESC))/, '');
  }

  private static getNonNullStringMovement(a: string, b: string): Move {
    if (!a) return Move.ARight;
    if (!b) return Move.BRight;
    return SortUtils.getMovement(a.localeCompare(b));
  }

  static numberAscending = (a: number, b: number): number => SortUtils.getMovement(a - b);
  static numberDescending = (a: number, b: number): number => SortUtils.getMovement(b - a);

  static nonNullStringAscending = (a: string, b: string): Move => SortUtils.getNonNullStringMovement(a, b);
  static nonNullStringDescending = (a: string, b: string): Move => SortUtils.getNonNullStringMovement(b, a);

  static sortSpecifiedStringLast = (value: string) => (a: string, b: string): Move => {
    const aIsSpecified = a === value;
    const bIsSpecified = b === value;
    if (aIsSpecified && !bIsSpecified) return Move.ARight;
    if (bIsSpecified && !aIsSpecified) return Move.BRight;
    return a.localeCompare(b);
  };

  static sortSpecifiedStringKeyLast = (value: string) => (a: KeyValue<string, any>, b: KeyValue<string, any>) => {
    const aKey = a.key;
    const bKey = b.key;
    return SortUtils.sortSpecifiedStringLast(value)(aKey, bKey);
  };

  static nullsLast(a: any, b: any) {
    // nulls sort after anything else
    if (a === null || a === undefined || a === '' || a === '--') {
      return 1;
    } else if (b === null || b === undefined || b === '' || b === '--') {
      return -1;
    } else {
      return 0;
    }
  }

  static compareNumerically = (a: string, b: string) => {
    return a.trim()?.localeCompare('' + b.trim(), 'en', { numeric: true });
  };
  static numericStringAsc = (a: string, b: string) => SortUtils.nullsLast(a, b) || SortUtils.compareNumerically(a, b);
  // Sort nulls last is done on purpose here
  static numericStringDesc = (a: string, b: string) => SortUtils.nullsLast(a, b) || SortUtils.compareNumerically(b, a);
  static numberAscNullsLast = (a: number, b: number) => SortUtils.nullsLast(a, b) || a - b;
  static numberDescNullsLast = (a: number, b: number) => SortUtils.nullsLast(a, b) || b - a;

  // Variants and Section

  static displayAttributeGroupsLastUpdatedFirst(a: DisplayAttributeGroup, b: DisplayAttributeGroup): number {
    return b.lastModified - a.lastModified;
  }

  static sortSectionBlueprintCategory = (a: SectionBlueprintCategory, b: SectionBlueprintCategory): number => {
    return a?.name?.localeCompare(b?.name);
  };

  static sortHydratedSmartFiltersByName = (a: HydratedSmartFilter, b: HydratedSmartFilter): number => {
    return a?.name?.localeCompare(b?.name);
  };

  static sortHydratedSmartFiltersByCategory = (a: HydratedSmartFilter, b: HydratedSmartFilter): number => {
    return a?.category?.name.localeCompare(b?.category?.name);
  };

  static sortSelectableSmartFilterByName = (a: SelectableSmartFilter, b: SelectableSmartFilter): number => {
    return a?.getSelectionName()?.localeCompare(b?.getSelectionName());
  };

  static defaultSecondarySort(a, b: Variant): number {
    return a.name.localeCompare(b.name);
  }

  static variantBarcode = (v1: Variant, v2: Variant): number => v1?.barcode.localeCompare(v2?.barcode);

  static variantAndAssetByBarcode =
    (va1: {variant: Variant; asset: Asset}, va2: {variant: Variant; asset: Asset}): number => {
      return SortUtils.variantBarcode(va1?.variant, va2?.variant);
    };

  static assetLibraryTypeSort(
    a: string,
    b: string
  ): number {
    const getOrderNumber = (assetLibraryType: string) => {
      switch (assetLibraryType) {
        case AssetLibraryType.PrimaryBrand:   return 1;
        case AssetLibraryType.AlternateBrand: return 2;
        case AssetLibraryType.Product:        return 3;
        case AssetLibraryType.Package:        return 4;
        case AssetLibraryType.Brand:          return 5;
        case AssetLibraryType.Marketing:      return 6;
        default:                              return 7;
      }
    };
    const aOrderNumber = getOrderNumber(a);
    const bOrderNumber = getOrderNumber(b);
    return aOrderNumber - bOrderNumber;
  }

  // Locations

  static sortLocationByNameAsc = (a: { name: string }, b: { name: string }): Move => {
    return SortUtils.nonNullStringAscending(a?.name, b?.name);
  };

  // Labels

  static sortSystemLabelsByDefaultPriority = (a: Label, b: Label): number => {
    const getOrderNumber = (labelSystemKey: MenuLabel) => {
      switch (labelSystemKey) {
        case MenuLabel.Sale:
          return 0;
        case MenuLabel.Featured:
          return 1;
        case MenuLabel.New:
          return 2;
        case MenuLabel.LowStock:
          return 3;
        case MenuLabel.Restocked:
          return 4;
      }
    };
    const aOrderNumber = getOrderNumber(MenuLabel[a.id]);
    const bOrderNumber = getOrderNumber(MenuLabel[b.id]);
    return aOrderNumber - bOrderNumber;
  };

  static sortLabelsAlphabetically = (a: Label, b: Label): number => {
    return a?.text?.localeCompare(b?.text, 'en', { numeric: true });
  };

  static sortLabelsByPriority = (a: Label, b: Label): number => {
    // If the labels have the same priority (ie: -1) then sort them alphabetically
    if (a?.priority === b?.priority) {
      return SortUtils.compareNumerically(a?.text, b?.text);
    }
    // Move all labels with priority of -1 to the end of the list
    if (a?.priority === -1) {
      return 1;
    }
    if (b?.priority === -1) {
      return -1;
    }
    return a?.priority - b?.priority;
  };

  static sortLabelsForLabelPicker = (a: Label, b: Label): number => {
    // We want the featured label to be at the start of the list, followed by the rest alphabetically
    if (a.id === MenuLabel.Featured) {
      return -1;
    } else if (b.id === MenuLabel.Featured) {
      return 1;
    }
    return a?.text?.localeCompare(b?.text, 'en', { numeric: true });
  };

  // Badges

  static sortBadges = (a: VariantBadge, b: VariantBadge): number => {
    return a.id.localeCompare(b.id);
  };

  static sortBadgesByName = (a: VariantBadge, b: VariantBadge): number => {
    return a?.name?.localeCompare(b?.name);
  };

  // Companies

  static sortCompaniesByNameAsc = (a: { name: string }, b: { name: string }): number => {
    return SortUtils.numericStringAsc(a?.name, b?.name);
  };

  // Other

  static sortDisplayAttributeGroupDetails = (
    a: DisplayAttributeGroupDetails,
    b: DisplayAttributeGroupDetails
  ): Move => {
    if (a?.isDefaultSharedGroupForDisplayAttribute()) {
      return Move.ALeft;
    } else if (b?.isDefaultSharedGroupForDisplayAttribute()) {
      return Move.BLeft;
    } else {
      return SortUtils.numericStringAsc(a?.groupName, b?.groupName);
    }
  };

  static sortTypeDefinitionsByName = (a: TypeDefinition, b: TypeDefinition): number => {
    return SortUtils.numericStringAsc(a?.name, b?.name);
  };

  static sortSectionSortTypesByName = (a: SectionSortType, b: SectionSortType): number => {
    return SortUtils.numericStringAsc(a?.name, b?.name);
  };

  static columnOptionKeySortAsc = (a: SectionColumnConfigKeyType, b: SectionColumnConfigKeyType): number => {
    const priority = (key: SectionColumnConfigKey) => Object.keys(SectionColumnConfigKey)?.findIndex(k => k === key);
    const aOrderNumber = priority(a?.value);
    const bOrderNumber = priority(b?.value);
    return SortUtils.numberAscNullsLast(aOrderNumber, bOrderNumber);
  };

  static sortSyncTypes = (a: SyncType, b: SyncType): Move => {
    const getOrderNumber = (syncType: SyncType) => {
      switch (syncType) {
        case SyncType.FullProductInfo:        return 0;
        case SyncType.Product:                return 1;
        case SyncType.Inventory:              return 2;
        case SyncType.LotInfo:                return 3;
        case SyncType.Pricing:                return 4;
        case SyncType.DisplayNames:           return 5;
        case SyncType.SmartFilters:           return 6;
        case SyncType.SmartDisplayAttributes: return 7;
        case SyncType.Promotions:             return 8;
        case SyncType.Labels:                 return 9;
        case SyncType.Location:               return 10;
      }
    };
    const aOrderNumber = getOrderNumber(a);
    const bOrderNumber = getOrderNumber(b);
    return SortUtils.numberAscending(aOrderNumber, bOrderNumber);
  };

}
