import { Deserializable } from '../protocols/deserializable';
import { LookAheadItem } from '../../views/shared/components/search-with-look-ahead/look-ahead-list/look-ahead-item/protocol/look-ahead-item';
import { UniquelyIdentifiable } from '../protocols/uniquely-identifiable';
import { ProductType } from '../../utils/product-type-definition';
import { SmartFilterCategory } from './smart-filter-category';
import { Variant } from '../product/dto/variant';
import { SmartFilterSystemIdentifiersType } from '../../utils/smart-filter-system-identifiers-type-definition';
import { DisplayAttributeGroupDetails } from '../product/dto/display-attribute-group-details';
import { exists } from '../../functions/exists';
import { Terpene } from '../../utils/terpene-type-definition';
import { SecondaryCannabinoids } from '../../utils/secondary-cannabinoids-type-definition';

/**
 * TODO remove comment after SF V3
 * new:
 * itemCount
 * primarySort
 * secondarySort
 * usePurpose
 * packagedQuantities
 * unitSizes
 * productName
 * brandName
 * pricingCodes
 * requirePricingTier
 * labelIds
 * badgeIds
 */
export class SmartFilter implements Deserializable, LookAheadItem, UniquelyIdentifiable {

  public locationId: number = null;
  public id: string = '';
  public ignoredVariantIds: string[] = [];
  public dateCreated: number = null;
  public lastUpdated: number = null;
  public categoryId: string = '';
  public advancedFilter: boolean = null;
  // General Info
  public name: string = '';
  public itemCount: number = null;
  public primarySort: string = '';
  public secondarySort: string = '';
  public category: SmartFilterCategory;
  public systemIdentifier: SmartFilterSystemIdentifiersType;
  // Filter Attributes
  public usePurpose: string = '';
  public productType: ProductType = null;
  public variantType: string = '';
  public classification: string = '';
  public minPrice: number = null;
  public maxPrice: number = null;
  public minTHC: number = null;
  public maxTHC: number = null;
  public minCBD: number = null;
  public maxCBD: number = null;
  // Secondary Cannabinoids
  public minCBDA: number = null;
  public maxCBDA: number = null;
  public minCBG: number = null;
  public maxCBG: number = null;
  public minCBGA: number = null;
  public maxCBGA: number = null;
  public minCBL: number = null;
  public maxCBL: number = null;
  public minCBLA: number = null;
  public maxCBLA: number = null;
  public minCBN: number = null;
  public maxCBN: number = null;
  public minCBNA: number = null;
  public maxCBNA: number = null;
  public minCBT: number = null;
  public maxCBT: number = null;
  public minCBC: number = null;
  public maxCBC: number = null;
  public minCBCA: number = null;
  public maxCBCA: number = null;
  public minCBCV: number = null;
  public maxCBCV: number = null;
  public minCBDV: number = null;
  public maxCBDV: number = null;
  public minTHC8: number = null;
  public maxTHC8: number = null;
  public minTHC9: number = null;
  public maxTHC9: number = null;
  public minTHCA: number = null;
  public maxTHCA: number = null;
  public minTHCV: number = null;
  public maxTHCV: number = null;
  public minTAC: number = null;
  public maxTAC: number = null;
  // Terpenes
  public minTotalTerpene: number = null;
  public maxTotalTerpene: number = null;
  public topTerpene: string = '';
  public minAlphaBisabolol: number = null;
  public maxAlphaBisabolol: number = null;
  public minAlphaPinene: number = null;
  public maxAlphaPinene: number = null;
  public minBetaCaryophyllene: number = null;
  public maxBetaCaryophyllene: number = null;
  public minBetaEudesmol: number = null;
  public maxBetaEudesmol: number = null;
  public minBetaMyrcene: number = null;
  public maxBetaMyrcene: number = null;
  public minBetaPinene: number = null;
  public maxBetaPinene: number = null;
  public minBisabolol: number = null;
  public maxBisabolol: number = null;
  public minBorneol: number = null;
  public maxBorneol: number = null;
  public minCamphene: number = null;
  public maxCamphene: number = null;
  public minCaryophylleneOxide: number = null;
  public maxCaryophylleneOxide: number = null;
  public minDelta3Carene: number = null;
  public maxDelta3Carene: number = null;
  public minEucalyptol: number = null;
  public maxEucalyptol: number = null;
  public minGeraniol: number = null;
  public maxGeraniol: number = null;
  public minGuaiol: number = null;
  public maxGuaiol: number = null;
  public minHumulene: number = null;
  public maxHumulene: number = null;
  public minLinalool: number = null;
  public maxLinalool: number = null;
  public minLimonene: number = null;
  public maxLimonene: number = null;
  public minOcimene: number = null;
  public maxOcimene: number = null;
  public minThreeCarene: number = null;
  public maxThreeCarene: number = null;
  public minTerpinene: number = null;
  public maxTerpinene: number = null;
  public minTerpinolene: number = null;
  public maxTerpinolene: number = null;
  public minTransNerolidol: number = null;
  public maxTransNerolidol: number = null;
  public minValencene: number = null;
  public maxValencene: number = null;
  // Advanced Filter Attributes
  public packagedQuantities: string = '';
  public unitSizes: string = '';
  public productName: string = '';
  public brandName: string = '';
  public strain: string = '';
  public searchTerm: string = ''; // General Search Term
  public minInventory: number = null;
  public maxInventory: number = null;
  public pricingCodes: string = '';
  public activeSale: boolean = null;
  public requirePricingTier: boolean = null;
  public labelIds: string[] = [];
  public badgeIds: string[] = [];
  public hidden: boolean = null;
  public isCompanySmartFilter: boolean = null;
  public isCuratedSmartFilter: boolean = null;

  onDeserialize() {
    if (this.minTHC === 0) this.minTHC = null;
    if (this.maxTHC === 0) this.maxTHC = null;
    if (this.minCBD === 0) this.minCBD = null;
    if (this.maxCBD === 0) this.maxCBD = null;
    if (this.minCBDA === 0) this.minCBDA = null;
    if (this.maxCBDA === 0) this.maxCBDA = null;
    if (this.minCBG === 0) this.minCBG = null;
    if (this.maxCBG === 0) this.maxCBG = null;
    if (this.minCBGA === 0) this.minCBGA = null;
    if (this.maxCBGA === 0) this.maxCBGA = null;
    if (this.minCBL === 0) this.minCBL = null;
    if (this.maxCBL === 0) this.maxCBL = null;
    if (this.minCBLA === 0) this.minCBLA = null;
    if (this.maxCBLA === 0) this.maxCBLA = null;
    if (this.minCBN === 0) this.minCBN = null;
    if (this.maxCBN === 0) this.maxCBN = null;
    if (this.minCBNA === 0) this.minCBNA = null;
    if (this.maxCBNA === 0) this.maxCBNA = null;
    if (this.minCBT === 0) this.minCBT = null;
    if (this.maxCBT === 0) this.maxCBT = null;
    if (this.minCBC === 0) this.minCBC = null;
    if (this.maxCBC === 0) this.maxCBC = null;
    if (this.minCBCA === 0) this.minCBCA = null;
    if (this.maxCBCA === 0) this.maxCBCA = null;
    if (this.minCBCV === 0) this.minCBCV = null;
    if (this.maxCBCV === 0) this.maxCBCV = null;
    if (this.minCBDV === 0) this.minCBDV = null;
    if (this.maxCBDV === 0) this.maxCBDV = null;
    if (this.minTHC8 === 0) this.minTHC8 = null;
    if (this.maxTHC8 === 0) this.maxTHC8 = null;
    if (this.minTHC9 === 0) this.minTHC9 = null;
    if (this.maxTHC9 === 0) this.maxTHC9 = null;
    if (this.minTHCA === 0) this.minTHCA = null;
    if (this.maxTHCA === 0) this.maxTHCA = null;
    if (this.minTHCV === 0) this.minTHCV = null;
    if (this.maxTHCV === 0) this.maxTHCV = null;
    if (this.minTAC === 0) this.minTAC = null;
    if (this.maxTAC === 0) this.maxTAC = null;
    if (this.minTotalTerpene === 0) this.minTotalTerpene = null;
    if (this.maxTotalTerpene === 0) this.maxTotalTerpene = null;

    if (this.minAlphaBisabolol === 0) this.minAlphaBisabolol = null;
    if (this.maxAlphaBisabolol === 0) this.maxAlphaBisabolol = null;

    if (this.minAlphaPinene === 0) this.minAlphaPinene = null;
    if (this.maxAlphaPinene === 0) this.maxAlphaPinene = null;

    if (this.minBetaCaryophyllene === 0) this.minBetaCaryophyllene = null;
    if (this.maxBetaCaryophyllene === 0) this.maxBetaCaryophyllene = null;

    if (this.minBetaEudesmol === 0) this.minBetaEudesmol = null;
    if (this.maxBetaEudesmol === 0) this.maxBetaEudesmol = null;

    if (this.minBetaMyrcene === 0) this.minBetaMyrcene = null;
    if (this.maxBetaMyrcene === 0) this.maxBetaMyrcene = null;

    if (this.minBetaPinene === 0) this.minBetaPinene = null;
    if (this.maxBetaPinene === 0) this.maxBetaPinene = null;

    if (this.minBisabolol === 0) this.minBisabolol = null;
    if (this.maxBisabolol === 0) this.maxBisabolol = null;

    if (this.minBorneol === 0) this.minBorneol = null;
    if (this.maxBorneol === 0) this.maxBorneol = null;

    if (this.minCamphene === 0) this.minCamphene = null;
    if (this.maxCamphene === 0) this.maxCamphene = null;

    if (this.minCaryophylleneOxide === 0) this.minCaryophylleneOxide = null;
    if (this.maxCaryophylleneOxide === 0) this.maxCaryophylleneOxide = null;

    if (this.minDelta3Carene === 0) this.minDelta3Carene = null;
    if (this.maxDelta3Carene === 0) this.maxDelta3Carene = null;

    if (this.minEucalyptol === 0) this.minEucalyptol = null;
    if (this.maxEucalyptol === 0) this.maxEucalyptol = null;

    if (this.minGeraniol === 0) this.minGeraniol = null;
    if (this.maxGeraniol === 0) this.maxGeraniol = null;

    if (this.minGuaiol === 0) this.minGuaiol = null;
    if (this.maxGuaiol === 0) this.maxGuaiol = null;

    if (this.minHumulene === 0) this.minHumulene = null;
    if (this.maxHumulene === 0) this.maxHumulene = null;

    if (this.minLinalool === 0) this.minLinalool = null;
    if (this.maxLinalool === 0) this.maxLinalool = null;

    if (this.minLimonene === 0) this.minLimonene = null;
    if (this.maxLimonene === 0) this.maxLimonene = null;

    if (this.minOcimene === 0) this.minOcimene = null;
    if (this.maxOcimene === 0) this.maxOcimene = null;

    if (this.minThreeCarene === 0) this.minThreeCarene = null;
    if (this.maxThreeCarene === 0) this.maxThreeCarene = null;

    if (this.minTerpinene === 0) this.minTerpinene = null;
    if (this.maxTerpinene === 0) this.maxTerpinene = null;

    if (this.minTerpinolene === 0) this.minTerpinolene = null;
    if (this.maxTerpinolene === 0) this.maxTerpinolene = null;

    if (this.minTransNerolidol === 0) this.minTransNerolidol = null;
    if (this.maxTransNerolidol === 0) this.maxTransNerolidol = null;

    if (this.minValencene === 0) this.minValencene = null;
    if (this.maxValencene === 0) this.maxValencene = null;

    if (this.minInventory === 0) this.minInventory = null;
    if (this.maxInventory === 0) this.maxInventory = null;
    if (this.minPrice === 0) this.minPrice = null;
    if (this.maxPrice === 0) this.maxPrice = null;
    if (this.itemCount === 0) this.itemCount = null;
    this.ignoredVariantIds = Array.from(this.ignoredVariantIds || []);
    this.labelIds = Array.from(this.labelIds || []);
    this.badgeIds = Array.from(this.badgeIds || []);
    this.category = window.injector.Deserialize.instanceOf(SmartFilterCategory, this.category);
  }

  getAdditionalColumns(): string {
    let count = 0;
    if (this.activeSale) { count++; }
    if (this.minTHC) { count++; }
    if (this.maxTHC) { count++; }
    if (this.minCBD) { count++; }
    if (this.maxCBD) { count++; }
    if (this.minCBDA) { count++; }
    if (this.maxCBDA) { count++; }
    if (this.minCBG) { count++; }
    if (this.maxCBG) { count++; }
    if (this.minCBGA) { count++; }
    if (this.maxCBGA) { count++; }
    if (this.minCBL) { count++; }
    if (this.maxCBL) { count++; }
    if (this.minCBLA) { count++; }
    if (this.maxCBLA) { count++; }
    if (this.minCBN) { count++; }
    if (this.maxCBN) { count++; }
    if (this.minCBNA) { count++; }
    if (this.maxCBNA) { count++; }
    if (this.minCBT) { count++; }
    if (this.maxCBT) { count++; }
    if (this.minCBC) { count++; }
    if (this.maxCBC) { count++; }
    if (this.minCBCA) { count++; }
    if (this.maxCBCA) { count++; }
    if (this.minCBCV) { count++; }
    if (this.maxCBCV) { count++; }
    if (this.minCBDV) { count++; }
    if (this.maxCBDV) { count++; }
    if (this.minTHC8) { count++; }
    if (this.maxTHC8) { count++; }
    if (this.minTHC9) { count++; }
    if (this.maxTHC9) { count++; }
    if (this.minTHCA) { count++; }
    if (this.maxTHCA) { count++; }
    if (this.minTHCV) { count++; }
    if (this.maxTHCV) { count++; }
    if (this.minTAC) { count++; }
    if (this.maxTAC) { count++; }
    if (this.topTerpene) { count++; }
    if (this.minTotalTerpene) { count++; }
    if (this.maxTotalTerpene) { count++; }
    if (this.minAlphaBisabolol) { count++; }
    if (this.maxAlphaBisabolol) { count++; }
    if (this.minAlphaPinene) { count++; }
    if (this.maxAlphaPinene) { count++; }
    if (this.minBetaCaryophyllene) { count++; }
    if (this.maxBetaCaryophyllene) { count++; }
    if (this.minBetaEudesmol) { count++; }
    if (this.maxBetaEudesmol) { count++; }
    if (this.minBetaMyrcene) { count++; }
    if (this.maxBetaMyrcene) { count++; }
    if (this.minBetaPinene) { count++; }
    if (this.maxBetaPinene) { count++; }
    if (this.minBisabolol) { count++; }
    if (this.maxBisabolol) { count++; }
    if (this.minBorneol) { count++; }
    if (this.maxBorneol) { count++; }
    if (this.minCamphene) { count++; }
    if (this.maxCamphene) { count++; }
    if (this.minCaryophylleneOxide) { count++; }
    if (this.maxCaryophylleneOxide) { count++; }
    if (this.minDelta3Carene) { count++; }
    if (this.maxDelta3Carene) { count++; }
    if (this.minEucalyptol) { count++; }
    if (this.maxEucalyptol) { count++; }
    if (this.minGeraniol) { count++; }
    if (this.maxGeraniol) { count++; }
    if (this.minGuaiol) { count++; }
    if (this.maxGuaiol) { count++; }
    if (this.minHumulene) { count++; }
    if (this.maxHumulene) { count++; }
    if (this.minLinalool) { count++; }
    if (this.maxLinalool) { count++; }
    if (this.minLimonene) { count++; }
    if (this.maxLimonene) { count++; }
    if (this.minOcimene) { count++; }
    if (this.maxOcimene) { count++; }
    if (this.minThreeCarene) { count++; }
    if (this.maxThreeCarene) { count++; }
    if (this.minTerpinene) { count++; }
    if (this.maxTerpinene) { count++; }
    if (this.minTerpinolene) { count++; }
    if (this.maxTerpinolene) { count++; }
    if (this.minTransNerolidol) { count++; }
    if (this.maxTransNerolidol) { count++; }
    if (this.minValencene) { count++; }
    if (this.maxValencene) { count++; }
    if (this.minInventory) { count++; }
    if (this.maxInventory) { count++; }
    if (this.minPrice) { count++; }
    if (this.maxPrice) { count++; }

    switch (count) {
      case 0:
        return '-';
      default:
        return `+${count}`;
    }
  }

  getStandardizedCategoryName(): string {
    return this.category?.name?.trim()?.toLowerCase();
  }

  lookAheadDisabled(): boolean {
    return false;
  }

  lookAheadDisabledMessage(): string {
    return '';
  }

  matchesVariant(variant: Variant): boolean {
    if (exists(this.productType) && variant.productType !== this.productType) {
      return false;
    }

    if (exists(this.variantType) && variant.variantType !== this.variantType) {
        return false;
    }

    if (exists(this.strain) && variant.strain !== this.strain) {
      return false;
    }

    if (exists(this.classification) && variant.classification !== this.classification) {
      return false;
    }

    if (exists(this.searchTerm) && variant.name?.includes(this.searchTerm)) {
      return false;
    }

    if (exists(this.searchTerm) && variant.description?.includes(this.searchTerm)) {
      return false;
    }

    if (exists(this.minTHC) && variant.getNumericTHC() < this.minTHC) {
      return false;
    }

    if (exists(this.maxTHC) && variant.getNumericTHC() > this.maxTHC) {
      return false;
    }

    if (exists(this.minCBD) && variant.getNumericCBD() < this.minCBD) {
      return false;
    }

    return !(exists(this.maxCBD) && variant.getNumericCBD() > this.maxCBD);
  }

  isCurated(): boolean {
    return this.locationId === -1;
  }

  matchesDisplayAttributeGroupDetails(groupDetails: DisplayAttributeGroupDetails): boolean {
    let cannabinoidMatch = true;

    const secondaryCannabinoids = Object.values(SecondaryCannabinoids);
    secondaryCannabinoids?.forEach(sc => {
      const min = `min${sc}`;
      const max = `max${sc}`;
      if (exists(this[min])
        && groupDetails?.getNumericValueOfCannabinoidOrTerpene(min as keyof DisplayAttributeGroupDetails) < this[min]
      ) {
        cannabinoidMatch = false;
      }
      if (exists(this[max])
        && groupDetails?.getNumericValueOfCannabinoidOrTerpene(max as keyof DisplayAttributeGroupDetails) > this[max]
      ) {
        cannabinoidMatch = false;
      }
    });

    if (!cannabinoidMatch) {
      return false;
    }

    let terpeneMatch = true;

    if (exists(this.topTerpene) && groupDetails?.topTerpene !== this.topTerpene) {
      return false;
    }
    if (exists(this.minTotalTerpene)
      && groupDetails?.getNumericValueOfCannabinoidOrTerpene('minTotalTerpene') < this.minTotalTerpene
    ) {
      return false;
    }

    if (exists(this.maxTotalTerpene)
      && groupDetails?.getNumericValueOfCannabinoidOrTerpene('maxTotalTerpene') > this.maxTotalTerpene
    ) {
      return false;
    }

    const terpenes = Object.values(Terpene);
    terpenes?.forEach(t => {
      const terpeneNoSpaces = t?.toString()?.stripWhiteSpace();
      const min = `min${terpeneNoSpaces}`;
      const max = `max${terpeneNoSpaces}`;
      if ((exists(this[min]) || exists(this[max])) && !groupDetails?.presentTerpenes?.contains(t)) {
        terpeneMatch = false;
      }
    });
    return terpeneMatch;
  }

    getUniqueIdentifier(): string {
      return `${this.id}
      -${this.activeSale}
      -${this.classification}
      -${this.ignoredVariantIds?.sort()?.join(',')}
      -${this.locationId}
      -${this.minTHC}
      -${this.maxTHC}
      -${this.minCBD}
      -${this.maxCBD}
      -${this.minCBDA}
      -${this.maxCBDA}
      -${this.minCBG}
      -${this.maxCBG}
      -${this.minCBGA}
      -${this.maxCBGA}
      -${this.minCBL}
      -${this.maxCBL}
      -${this.minCBLA}
      -${this.maxCBLA}
      -${this.minCBN}
      -${this.maxCBN}
      -${this.minCBNA}
      -${this.maxCBNA}
      -${this.minCBT}
      -${this.maxCBT}
      -${this.minCBC}
      -${this.maxCBC}
      -${this.minCBCA}
      -${this.maxCBCA}
      -${this.minCBCV}
      -${this.maxCBCV}
      -${this.minCBDV}
      -${this.maxCBDV}
      -${this.minTHC}
      -${this.maxTHC8}
      -${this.minTHC8}
      -${this.maxTHC9}
      -${this.minTHC9}
      -${this.maxTHCA}
      -${this.minTHCV}
      -${this.maxTHCV}
      -${this.minTAC}
      -${this.maxTAC}
      -${this.topTerpene}
      -${this.minTotalTerpene}
      -${this.maxTotalTerpene}
      -${this.minAlphaBisabolol}
      -${this.maxAlphaBisabolol}
      -${this.minAlphaPinene}
      -${this.maxAlphaPinene}
      -${this.minBetaCaryophyllene}
      -${this.maxBetaCaryophyllene}
      -${this.maxBetaEudesmol}
      -${this.maxBetaEudesmol}
      -${this.maxBetaMyrcene}
      -${this.maxBetaMyrcene}
      -${this.maxBetaPinene}
      -${this.maxBetaPinene}
      -${this.minBisabolol}
      -${this.maxBisabolol}
      -${this.minBorneol}
      -${this.maxBorneol}
      -${this.minCamphene}
      -${this.maxCamphene}
      -${this.minCaryophylleneOxide}
      -${this.maxCaryophylleneOxide}
      -${this.minDelta3Carene}
      -${this.maxDelta3Carene}
      -${this.minEucalyptol}
      -${this.maxEucalyptol}
      -${this.minGeraniol}
      -${this.maxGeraniol}
      -${this.minGuaiol}
      -${this.maxGuaiol}
      -${this.minHumulene}
      -${this.maxHumulene}
      -${this.minLinalool}
      -${this.maxLinalool}
      -${this.minLimonene}
      -${this.maxLimonene}
      -${this.minOcimene}
      -${this.maxOcimene}
      -${this.minThreeCarene}
      -${this.maxThreeCarene}
      -${this.minTerpinene}
      -${this.maxTerpinene}
      -${this.minTerpinolene}
      -${this.maxTerpinolene}
      -${this.minTransNerolidol}
      -${this.maxTransNerolidol}
      -${this.minValencene}
      -${this.maxValencene}
      -${this.minInventory}
      -${this.maxInventory}
      -${this.minPrice}
      -${this.maxPrice}
      -${this.name}
      -${this.productType}
      -${this.searchTerm}
      -${this.strain}
      -${this.variantType}
      -${this.advancedFilter}
      -${this.lastUpdated}
      -${this.itemCount}
      -${this.primarySort}
      -${this.secondarySort}
      -${this.usePurpose}
      -${this.packagedQuantities}
      -${this.unitSizes}
      -${this.productName}
      -${this.brandName}
      -${this.pricingCodes}
      -${this.requirePricingTier}
      -${this.isCompanySmartFilter}
      -${this.isCuratedSmartFilter}
      -${this.category?.getUniqueIdentifier()}
      -${this.labelIds?.sort()?.join(',')}
      -${this.badgeIds?.sort()?.join(',')}`;
    }

}
