import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormInputItem, FormInputType, FormItemType } from '../../../../../models/shared/stylesheet/form-input-item';
import { FormGroupStyling } from '../../../../../models/shared/stylesheet/form-group-styling';
import { LoadingOptions } from '../../../../../models/shared/loading-options';
import { AuthViewModel } from '../../../viewModels/auth-view-model';
import { ActivatedRoute, Router } from '@angular/router';
import { Viewable } from '../../../../../models/protocols/viewable';
import { Routable } from '../../../../../models/protocols/routable';
import { ResetPasswordRequest } from '../../../../../models/account/requests/reset-password-request';
import { RouteUtils } from '../../../../../utils/route-utils';
import { PasswordValidatorDirective } from '../../../../shared/components/form-group/validators/password-validator.directive';
import { BsError } from '../../../../../models/shared/bs-error';
import { ToastService } from '../../../../../services/toast-service';
import { BaseComponent } from '../../../../../models/base/base-component';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: [
    './reset-password.component.scss',
    '../auth.component.scss'
  ]
})
export class ResetPasswordComponent extends BaseComponent implements OnInit, Viewable, Routable, OnDestroy {

  // View
  public resetPassFormItems: FormInputItem[] = [];
  public styling: FormGroupStyling = new FormGroupStyling();
  private _loadingOpts = new BehaviorSubject<LoadingOptions>(LoadingOptions.default());
  public loadingOpts$ = this._loadingOpts as Observable<LoadingOptions>;
  public isLoading$ = this.loadingOpts$.pipe(map(opts => opts?.isLoading));
  // Variables
  public req: ResetPasswordRequest = new ResetPasswordRequest();

  constructor(
    public viewModel: AuthViewModel,
    private route: ActivatedRoute,
    private router: Router,
    private toastService: ToastService
  ) {
    super();
  }

  override ngOnInit(): void {
    this.bindUrlParams();
    this.setupViews();
  }

  override ngOnDestroy(): void {
    this.destroy();
    this.viewModel.destroy();
  }

  bindUrlParams() {
    this.route.queryParams.subscribe((params) => {
      this.req.email = decodeURIComponent(decodeURIComponent(params.Email || params.email || ''));
      this.req.code = decodeURIComponent(decodeURIComponent(params.Code || params.code || ''));
    });
    this.router.navigate([RouteUtils.cleanedUrlRoute(this.router.url)], {queryParams: {}});
  }

  override setupViews() {
    this.setupFormItems();
    this.setupFormStyling();
  }

  setupFormItems() {
    this.resetPassFormItems = [];
    // Set up form items
    const emailInput = new FormInputItem();
    emailInput.itemType = FormItemType.Input;
    emailInput.inputName = 'email';
    emailInput.label = 'Email';
    emailInput.placeholder = 'Enter your email';
    emailInput.bindingProperty = 'email';
    emailInput.inputType = FormInputType.Email;
    emailInput.required = true;
    this.resetPassFormItems.push(emailInput);

    const codeInput = new FormInputItem();
    codeInput.itemType = FormItemType.Input;
    codeInput.inputName = 'code';
    codeInput.label = 'Password Reset Code';
    codeInput.placeholder = 'Enter the password reset code sent to your email.';
    codeInput.bindingProperty = 'code';
    codeInput.inputType = FormInputType.Number;
    codeInput.required = true;
    this.resetPassFormItems.push(codeInput);

    const passwordInput = new FormInputItem();
    passwordInput.itemType = FormItemType.Input;
    passwordInput.inputName = 'password';
    passwordInput.label = 'New Password';
    passwordInput.placeholder = 'Enter a new password.';
    passwordInput.bindingProperty = 'password';
    passwordInput.inputType = FormInputType.Password;
    passwordInput.required = true;
    passwordInput.customValidator = new PasswordValidatorDirective();
    this.resetPassFormItems.push(passwordInput);

    const confirmPasswordInput = new FormInputItem();
    confirmPasswordInput.itemType = FormItemType.Input;
    confirmPasswordInput.inputName = 'confirmPassword';
    confirmPasswordInput.label = 'Confirm Password';
    confirmPasswordInput.placeholder = 'Retype your new password.';
    confirmPasswordInput.bindingProperty = 'confirmPassword';
    confirmPasswordInput.inputType = FormInputType.Password;
    confirmPasswordInput.required = true;
    confirmPasswordInput.mustMatchInputName = 'password';
    confirmPasswordInput.customValidator = new PasswordValidatorDirective();
    this.resetPassFormItems.push(confirmPasswordInput);
  }

  setupFormStyling() {
    // Set up form styling
    this.styling.primaryButtonFloat = 'left';
    this.styling.submitButtonText = 'Reset Password';
  }

  formSubmitted(req: ResetPasswordRequest) {
    const loadingMess = 'Resetting password.';
    if (!this._loadingOpts.containsRequest(loadingMess)) {
      this._loadingOpts.addRequest(loadingMess);
      this.viewModel.resetPassword(req).subscribe((_) => {
        this._loadingOpts.removeRequest(loadingMess);
      }, (error: BsError) => {
        this._loadingOpts.removeRequest(loadingMess);
        this.toastService.publishError(error);
      });
    }
  }

}
