<div class="auth-flow-wrapper">
  <app-loading *ngIf="isLoading$ | async" [options]="loadingOpts$ | async"> </app-loading>

  <div class="auth-flow-container">
    <!--  Title  -->
    <div class="auth-flow-title">
      {{ viewModel.getAuthFlowTitle() }}
    </div>
    <div class="auth-flow-subtext">
      {{ viewModel.getAuthFlowSubtext() }}
    </div>
    <!-- Form -->
    <app-form-group
      (formSubmitted)="formSubmitted($event)"
      [formItems]="newPassFormItems"
      [formObject]="req"
      [styling]="styling">
    </app-form-group>
  </div>
</div>
