import { NavItemViewModel } from '../nav-item-view-model';
import { Injectable } from '@angular/core';
import { BsError } from '../../../../../models/shared/bs-error';
import { Router } from '@angular/router';
import { AccountDomainModel } from '../../../../../domainModels/account-domain-model';
import { ToastService } from '../../../../../services/toast-service';
import { throwError } from 'rxjs';

@Injectable()
export class SignOutNavItemViewModel extends NavItemViewModel {

  constructor(
    protected accountDomainModel: AccountDomainModel,
    protected toastService: ToastService,
    router: Router,
  ) {
    super(router);
  }

  signOut() {
    const loadingMess = 'Signing Out';
    this.loadingOpts.addRequest(loadingMess);
    return this.accountDomainModel.signOut().subscribe((_) => {
      this.loadingOpts.removeRequest(loadingMess);
      this.router.navigate(['/auth/sign-in']).then(() => {
      });
    }, (error: BsError) => {
      this.loadingOpts.removeRequest(loadingMess);
      this.toastService.publishError(error);
      throwError(error);
    });
  }

}
