<form (ngSubmit)="submitForm()" [formGroup]="form" [ngClass]="{ 'bs-textfield-form': styling.includePadding }">
  <div class="bs-form-container">
    <ng-container *ngFor="let item of formItems; let itemIndex = index">
      <!--   Hidden Item - used to maintain odd/even count when using overrideFullWidth   -->
      <div *ngIf="item.itemType === itemTypes.Hidden" class="form-input-container"></div>

      <!--   Spacer Item - used to take up the space of a form item, without displaying any UI   -->
      <div
        *ngIf="item.itemType === itemTypes.Spacer"
        [ngClass]="{
          'two-column': styling.numberColumns === 2 && !item.overrideFullWidth,
          'two-column-expand-last': styling.numberColumns === ClientTypes.FormGroupTypes.TwoColumnExpandLast,
          'two-column-inner-padding': styling.numberColumns === ClientTypes.FormGroupTypes.TwoColumnInnerPadding,
          'override-full-width': item.overrideFullWidth
        }"
        class="row bs-form-group">
        <div [ngClass]="{ inline: item.inlineLabel }" class="bs-form-group-item"></div>
      </div>

      <!--   Divider Item   -->
      <div *ngIf="item.itemType === itemTypes.Divider" class="form-input-container">
        <div [ngClass]="item.customClass" class="form-input-divider"></div>
      </div>

      <!--   Title Item   -->
      <div *ngIf="item.itemType === itemTypes.Title" class="form-input-container">
        <div [ngStyle]="{ margin: styling.numberColumns === 2 ? '0 0.75rem' : '0' }" class="form-input-title">
          {{ item.label }}
        </div>
        <div *ngIf="item.tooltipText && item.tooltipText !== ''" class="tooltip-container">
          <img
            [ngbTooltip]="item.tooltipText"
            [placement]="getTooltipPlacement(itemIndex)"
            [src]="'assets/icons/dark/outline/information-circle.svg'"
            alt="" />
        </div>
        <!--    Tooltip opens information modal    -->
        <div *ngIf="item.tooltipModalInfoItems?.length > 0" class="tooltip-container">
          <button (click)="openTooltipInfoModal(item)" class="inline-tooltip">
            <img [src]="'assets/icons/dark/outline/information-circle.svg'" alt="" style="opacity: 1" />
          </button>
        </div>
      </div>

      <div
        class="row bs-form-group {{ item.customClass }}"
        *ngIf="
          item.itemType !== itemTypes.Divider &&
          item.itemType !== itemTypes.Title &&
          item.itemType !== itemTypes.Hidden &&
          item.itemType !== itemTypes.Spacer
        "
        [ngClass]="{
          'has-error': item.hasErrors(),
          'two-column': styling.numberColumns === 2 && !item.overrideFullWidth,
          'two-column-expand-last': styling.numberColumns === ClientTypes.FormGroupTypes.TwoColumnExpandLast,
          'two-column-inner-padding': styling.numberColumns === ClientTypes.FormGroupTypes.TwoColumnInnerPadding,
          'override-full-width': item.overrideFullWidth,
          'single-line-input': item.itemType === itemTypes.Switch
        }">
        <div [ngClass]="{ inline: item.inlineLabel }" class="bs-form-group-item">
          <label
            [hidden]="item.hideLabel || item.itemType === itemTypes.Switch"
            [ngClass]="{ 'inline-label': item.inlineLabel }"
            class="control-label bs-textfield-label"
            [ngStyle]="{ width: item.inlineLabelWidthPercent * 2 + '%' }"
            for="{{ item.inputName }}">
            {{ item.label }}
            <span *ngIf="item.required && item.showRequiredAsterisk" class="required-star"> *</span>
            <span *ngIf="item.tooltipText && item.tooltipText !== ''" class="tooltip-container">
              <img
                [ngbTooltip]="item.tooltipText"
                [placement]="getTooltipPlacement(itemIndex)"
                [src]="'assets/icons/dark/outline/information-circle.svg'"
                alt="" />
            </span>
            <!--    Tooltip opens information modal    -->
            <span *ngIf="item.tooltipModalInfoItems?.length > 0" class="tooltip-container">
              <button (click)="openTooltipInfoModal(item)" class="inline-tooltip">
                <img [src]="'assets/icons/dark/outline/information-circle.svg'" alt="" style="opacity: 1" />
              </button>
            </span>
          </label>

          <div [ngClass]="{ flex: item.inputHasButton }" class="input-container">
            <!--   Input Item   -->
            <div
              *ngIf="item.itemType === itemTypes.Input"
              [ngClass]="{
                'inline-textfield-container': item.inlineLabel,
                'inline-button-container': item.inputHasButton
              }"
              class="form-input-container">
              <div class="input-container-block">
                <input
                  (change)="!settingUpForm ? item.handleSearchableItemSelected($event) : null"
                  (keyup)="item.inputType === 'search' ? item.emitTypeAheadValue($event) : handleInputKeyUp($event)"
                  [attr.disabled]="!item.enabled ? true : null"
                  [attr.list]="item.inputName.concat('List')"
                  [ngClass]="{
                    'search-textfield': item.inputType === 'search',
                    'inline-textfield': item.inlineLabel
                  }"
                  [ngStyle]="{
                    background: item.inputType === 'color' ? item.getValue() || '#F2F2F2' : '#F2F2F2'
                  }"
                  [readOnly]="!item.editable"
                  [type]="item.inputType === inputTypes.Search ? 'text' : item.inputType"
                  class="form-control bs-textfield"
                  formControlName="{{ item.inputName }}"
                  id="{{ item.inputName }}"
                  name="{{ item.inputName }}"
                  [step]="item.inputStep"
                  [autocapitalize]="item.autoCapitalize"
                  [autocomplete]="item.autoComplete ? 'on' : 'off'"
                  placeholder="{{ item.placeholder }}" />
                <datalist *ngIf="item.inputType === inputTypes.Search" [id]="item.inputName.concat('List')">
                  <ng-container *ngFor="let sItem of item.searchable">
                    <option *ngIf="(item?.typeAheadString | async)?.length > 2" value="{{ sItem.lookupKey }}"></option>
                  </ng-container>
                </datalist>
                <small *ngIf="!item.hideError" [ngStyle]="{ opacity: item.hasErrors() ? 1.0 : 0 }" class="help-block">
                  <span> {{ item.getErrorMessage() }} </span>
                </small>
              </div>
            </div>

            <!--    Dropdown Item    -->
            <div
              *ngIf="item.itemType === itemTypes.Dropdown"
              [ngClass]="{
                'inline-textfield-container': item.inlineLabel,
                'inline-button-container': item.inputHasButton
              }"
              class="form-input-container">
              <div class="input-container-block">
                <select
                  (ngModelChange)="!settingUpForm ? item.handleValueChanged() : null"
                  [attr.disabled]="!item.enabled ? true : null"
                  class="form-control bs-dropdown"
                  formControlName="{{ item.inputName }}"
                  id="{{ item.inputName }}"
                  name="{{ item.inputName }}">
                  <option *ngIf="item.placeholder" disabled selected value="{{ item.dropdownIsObject ? 'null' : '' }}">
                    {{ item.placeholder }}
                  </option>
                  <option *ngFor="let drop of item.dropdownOptions" [ngValue]="drop.getSelectionValue()">
                    {{ drop.getSelectionTitle() }}
                  </option>
                </select>
                <small *ngIf="!item.hideError" [ngStyle]="{ opacity: item.hasErrors() ? 1.0 : 0 }" class="help-block">
                  <span> {{ item.getErrorMessage() }} </span>
                </small>
              </div>
            </div>

            <!--    Switch Item    -->
            <div
              *ngIf="item.itemType === itemTypes.Switch"
              [ngClass]="{ 'inline-textfield-container': item.inlineLabel }"
              class="form-input-container form-input-switch">
              <div class="input-container-block">
                <div class="bs-switch-container">
                  <div class="switch-label-container">
                    {{ item.label }}
                    <div *ngIf="item.tooltipText && item.tooltipText !== ''" class="tooltip-container">
                      <img
                        [ngbTooltip]="item.tooltipText"
                        [placement]="getTooltipPlacement(itemIndex)"
                        [src]="'assets/icons/dark/outline/information-circle.svg'"
                        alt="" />
                    </div>
                    <!--    Tooltip opens information modal    -->
                    <div *ngIf="item.tooltipModalInfoItems?.length > 0" class="tooltip-container">
                      <button (click)="openTooltipInfoModal(item)" class="inline-tooltip">
                        <img [src]="'assets/icons/dark/outline/information-circle.svg'" alt="" style="opacity: 1" />
                      </button>
                    </div>
                  </div>

                  <label class="bs-switch">
                    <input
                      (change)="!settingUpForm ? item.handleValueChanged() : null"
                      [value]="item.getValue()"
                      [checked]="item.getValue()"
                      [attr.disabled]="!item.enabled ? true : null"
                      class="form-control bs-switch-input"
                      formControlName="{{ item.inputName }}"
                      id="{{ item.inputName }}"
                      name="{{ item.inputName }}"
                      type="checkbox" />
                    <span [ngClass]="{ disabled: !item.enabled }" class="bs-slider"></span>
                  </label>
                </div>
              </div>
            </div>

            <!--    Textarea Item    -->
            <div
              *ngIf="item.itemType === itemTypes.Textarea"
              [ngClass]="{ 'inline-textfield-container': item.inlineLabel }"
              class="form-input-container">
              <textarea
                class="form-control bs-text-area"
                (keyup)="handleInputKeyUp($event)"
                (change)="!settingUpForm ? item.handleValueChanged() : null"
                cols="50"
                formControlName="{{ item.inputName }}"
                name="{{ item.inputName }}"
                id="{{ item.inputName }}"
                placeholder="{{ item.placeholder }}"
                rows="4"
                [attr.disabled]="!item.enabled ? true : null"
                [readOnly]="!item.editable"></textarea>
              <small *ngIf="!item.hideError" [ngStyle]="{ opacity: item.hasErrors() ? 1.0 : 0 }" class="help-block">
                <span> {{ item.getErrorMessage() }} </span>
              </small>
            </div>

            <!--    Checkbox Group Item    -->
            <div
              *ngIf="item.itemType === itemTypes.CheckboxGroup"
              [ngClass]="{ 'inline-textfield-container': item.inlineLabel }"
              class="form-input-container">
              <div class="grouped-checkbox-container">
                <app-checkbox-container
                  (changed)="groupedCheckboxesChanged(item, $event)"
                  [checkBoxContainerOptions]="item.groupCheckboxOptions"
                  [checkBoxes]="item.groupCheckboxes">
                </app-checkbox-container>
              </div>
              <small *ngIf="!item.hideError" [ngStyle]="{ opacity: item.hasErrors() ? 1.0 : 0 }" class="help-block">
                <span> {{ item.getErrorMessage() }} </span>
              </small>
            </div>

            <!--    Form input button - Available for input or dropdown   -->
            <div [hidden]="!item.inputHasButton" class="form-input-button-container">
              <button
                (click)="item.inputButtonClicked.emit($event)"
                [disabled]="!item.enabled"
                [ngClass]="{ disabled: !item.enabled }"
                type="button"
                class="bs-button">
                <img
                  *ngIf="!!item.inputButtonIconSrc"
                  [src]="item.inputButtonIconSrc"
                  alt="button-icon"
                  style="opacity: 1; margin-right: 0" />
                {{ item.inputButtonText }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <!--   End Form Checkbox     -->
    <div
      *ngIf="options.includeEndFormCheckbox"
      [ngClass]="styling.checkboxClass"
      class="form-input-container form-end-checkbox-container">
      <app-checkbox (clickEvent)="checkboxClicked($event)" [checked]="checkboxValue" [label]="styling.checkboxLabel">
      </app-checkbox>
    </div>

    <div
      *ngIf="
        styling.resetButtonText !== '' ||
        styling.cancelButtonText !== '' ||
        styling.submitButtonText !== '' ||
        styling.secondaryButtonText !== ''
      "
      class="form-group-buttons-container">
      <div class="form-group primary-button-container" [ngStyle]="{ float: styling.primaryButtonFloat }">
        <button
          (click)="resetForm()"
          [hidden]="styling.resetButtonText === ''"
          [ngStyle]="{ margin: styling.getButtonMargin() }"
          class="bs-button outlined-button"
          type="reset">
          {{ styling.resetButtonText }}
        </button>
        <button
          (click)="cancelForm()"
          [hidden]="styling.cancelButtonText === ''"
          [ngStyle]="{ margin: styling.getButtonMargin() }"
          class="bs-button neutral-button"
          type="button">
          {{ styling.cancelButtonText }}
        </button>
        <button
          (click)="submitForm()"
          [disabled]="!canSubmitForm()"
          [hidden]="styling.submitButtonText === ''"
          [ngClass]="{ disabled: !canSubmitForm() }"
          [ngStyle]="{ margin: styling.getButtonMargin() }"
          class="bs-button preferred-button"
          type="submit">
          {{ styling.submitButtonText }}
        </button>
      </div>

      <div class="form-group secondary-button-container" [ngStyle]="{ float: styling.secondaryButtonFloat }">
        <button
          (click)="secondaryButtonPressed.emit()"
          [hidden]="styling.secondaryButtonText === ''"
          [ngStyle]="{ margin: styling.getButtonMargin(true) }"
          class="bs-button {{ styling.secondaryButtonClass }}"
          type="button">
          {{ styling.secondaryButtonText }}
        </button>
      </div>
    </div>
  </div>
</form>
