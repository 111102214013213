import { Deserializable } from '../../protocols/deserializable';
import { Cachable } from '../../protocols/cachable';
import { DateUtils } from '../../../utils/date-utils';
import { DatatableData } from '../../protocols/datatable-data';
import { UniquelyIdentifiable } from '../../protocols/uniquely-identifiable';
import { Pagable } from '../../protocols/pagable';
import { HasChildIds } from '../../protocols/has-child-ids';
import { ProductType } from '../../../utils/product-type-definition';
import { VariantType } from '../../../utils/variant-type-definition';
import { CannabisUnitOfMeasure } from '../../../utils/cannabis-unit-of-measure-type';
import { UnitOfMeasure } from '../../../utils/unit-of-measure-type';
import { StrainType } from '../../../utils/strain-type-type';

export class Variant implements Deserializable, Cachable, DatatableData, UniquelyIdentifiable, Pagable, HasChildIds {

  static readonly invalidParsedCannabinoid = -1;
  // DTO
  public barcode: string;
  public confirmed: boolean = false;
  public displayAttributeGroupId: string;
  public assetGroupId: string;
  public name: string;
  public brand: string;
  public manufacturer: string;
  public description: string;
  public shortDescription: string;
  public terpenes: string;
  public terpeneMap: Map<string, number>;
  public classification: StrainType;
  public unitSize: number;
  public unitOfMeasure: UnitOfMeasure;
  public cannabisUnitOfMeasure: CannabisUnitOfMeasure;
  public packagedQuantity: number;
  public productType: ProductType;
  public variantType: VariantType;
  public strain: string;
  public THC: string;
  public minTHC: string;
  public maxTHC: string;
  public CBD: string;
  public minCBD: string;
  public maxCBD: string;
  public cultivatorVerified: boolean = false;
  public delisted: boolean = false;
  public dateCreated: number;
  public lastModified: number;
  // Cache
  public cachedTime: number;

  static buildArrayCacheKey(companyId, locationId: number, productId: string): string {
    return `UBVs-${companyId}-${locationId}-${productId}`;
  }

  static buildCacheKey(companyId, locationId: number, variantId: string): string {
    return `UBV-${companyId}-${locationId}-${variantId}`;
  }

  static getNumericValue = (v: string): number => {
    if (!!v) {
      if (v.includes('-')) {
        v = v.split('-')[0];
      }
      return Number(v.replace(/[^0-9.]+/g, ''));
    } else {
      return Variant.invalidParsedCannabinoid;
    }
  };

  public onDeserialize() {
    this.terpeneMap = window.injector.Deserialize.genericMap<string, number>(this.terpeneMap);
  }

  cacheExpirySeconds(): number {
    return DateUtils.secondsInOneHour();
  }

  cacheKey(companyId, locationId: number): string {
    return Variant.buildCacheKey(companyId, locationId, this.barcode);
  }

  isExpired(): boolean {
    const expiresAt = this.cachedTime + this.cacheExpirySeconds();
    return DateUtils.currentTimestampInSeconds() > expiresAt;
  }

  // Datatable Methods

  getChildrenUniqueIds(): string[] {
    return [this.barcode];
  }

  getColor(): string {
    return '#333333';
  }

  getTextColor(): string {
    return '#fefefe';
  }

  // Incomplete variant

  isIncompleteOrUnconfirmed() {
    return this.isUnconfirmed() || this.isIncomplete();
  }

  isIncomplete(): boolean {
    const ipc = this.incompletePropertyCount();
    if (this.productType === ProductType.Accessories || this.productType === ProductType.Other) {
      // Never show Accessories or Other as incomplete
      return false;
    } else {
      return ipc > 0;
    }
  }

  isUnconfirmed(): boolean {
    return !this.confirmed;
  }

  incompletePropertyCount(): number {
    return this.incompleteProperties()?.length ?? 0;
  }

  incompleteProperties(): string[] {
    const incompleteProperties = [];
    if (this.packagedQuantity === 0) {
      incompleteProperties.push('packagedQuantity');
    }
    if (this.brand === '') {
      incompleteProperties.push('brand');
    }
    if (!this.classification) {
      if (this.classification.toString() === '') {
        incompleteProperties.push('classification');
      }
    }
    if (this.unitSize === 0) {
      incompleteProperties.push('unitSize');
    }
    if (!this.unitOfMeasure) {
      if (this.unitOfMeasure.toString() === '') {
        incompleteProperties.push('unitOfMeasure');
      }
    }
    if (this.cannabisUnitOfMeasure?.toString() === '') {
      incompleteProperties.push('cannabisUnitOfMeasure');
    }
    if (!this.productType) {
      if (this.productType.toString() === '') {
        incompleteProperties.push('productType');
      }
    }
    if (!this.variantType) {
      if (this.variantType.toString() === '') {
        incompleteProperties.push('variantType');
      }
    }
    if (this.THC === '') {
      incompleteProperties.push('THC');
    }
    if (this.CBD === '') {
      incompleteProperties.push('CBD');
    }
    if (!this.minCBD) {
      if (this.minCBD?.toString() === '') {
        incompleteProperties.push('minCBD');
      }
    }
    if (!this.maxCBD) {
      if (this.maxCBD?.toString() === '') {
        incompleteProperties.push('maxCBD');
      }
    }
    if (!this.minTHC) {
      if (this.minTHC?.toString() === '') {
        incompleteProperties.push('minTHC');
      }
    }
    if (!this.maxTHC) {
      if (this.maxTHC?.toString() === '') {
        incompleteProperties.push('maxTHC');
      }
    }
    return incompleteProperties;
  }

  // Unassigned Variant
  isUnassigned() {
    return this.displayAttributeGroupId === '';
  }

  isUnassignedToAssetGroup(): boolean {
    return !!this.displayAttributeGroupId && (!this.assetGroupId || this.assetGroupId === this.displayAttributeGroupId);
  }

  // Update methods

  getNumericTHC(): number {
    const thc = this.THC;
    return Variant.getNumericValue(thc);
  }

  getNumericMinTHC(): number {
    const minTHC = this.minTHC !== '' ? this.minTHC : null;
    return Variant.getNumericValue(minTHC);
  }

  getNumericMaxTHC(): number {
    const maxTHC = this.maxTHC !== '' ? this.maxTHC : null;
    return Variant.getNumericValue(maxTHC);
  }

  getNumericCBD(): number {
    const cbd = this.CBD;
    return Variant.getNumericValue(cbd);
  }

  getNumericMinCBD(): number {
    const minCBD = this.minCBD !== '' ? this.minCBD : null;
    return Variant.getNumericValue(minCBD);
  }

  getNumericMaxCBD(): number {
    const maxCBD = this.maxCBD !== '' ? this.maxCBD : null;
    return Variant.getNumericValue(maxCBD);
  }

  getNumericSize(): number {
    switch (this.variantType) {
      case VariantType.Flower:
      case VariantType.BulkFlower:
      case VariantType.MilledFlower:
      case VariantType.Oil:
      case VariantType.Vape:
      case VariantType.VapeCartridge:
      case VariantType.Concentrate:
        return this.unitSize;
      default:
        return this.packagedQuantity;
    }
  }

  getSize(): string {
    switch (this.variantType) {
      case VariantType.Flower:
      case VariantType.BulkFlower:
      case VariantType.MilledFlower:
      case VariantType.Oil:
      case VariantType.Vape:
      case VariantType.VapeCartridge:
      case VariantType.Concentrate:
        return `${this.unitSize} ${this.unitOfMeasure}`;
      default:
        return `${this.packagedQuantity} pk`;
    }
  }

  getGridName() {
    switch (this.variantType) {
      case VariantType.Flower:
      case VariantType.BulkFlower:
      case VariantType.MilledFlower:
        return this.unitSize > 0 ? `${this.unitSize} g` : null;
      case VariantType.Oil:
      case VariantType.Vape:
      case VariantType.VapeCartridge:
      case VariantType.Concentrate:
        return this.unitSize > 0 ? `${this.unitSize} ${this.unitOfMeasure}` : null;
      case VariantType.Capsule:
        return this.packagedQuantity > 0 ? `${this.packagedQuantity} caps` : null;
      default:
        return this.packagedQuantity > 0 ? `${this.packagedQuantity} pk` : null;
    }
  }

  formattedSizing(): string {
    let sizeString = '';
    if ((this.packagedQuantity > 0) && (this.unitSize > 0) && (this.unitOfMeasure !== UnitOfMeasure.NA)) {
      const n = this.packagedQuantity;
      if (this.variantType === VariantType.Capsule) {
        sizeString = `${n} cap${n > 1 ? 's' : ''}`;
      } else if (this.productType === ProductType.Edible) {
        sizeString = `${n} pack`;
      } else {
        sizeString = this.packagedQuantity + ' x ' + this.unitSize + this.unitOfMeasure;
      }
    }
    return sizeString;
  }

  getSwitchEnabled(): boolean {
    return true;
  }

  getUniqueIdentifier(): string {
    // generate unique id
    return `${this.barcode}
      -${this.confirmed}
      -${this.displayAttributeGroupId}
      -${this.assetGroupId}
      -${this.name}
      -${this.brand}
      -${this.manufacturer}
      -${this.description}
      -${this.shortDescription}
      -${this.terpenes}
      -${this.classification}
      -${this.unitSize}
      -${this.unitOfMeasure}
      -${this.cannabisUnitOfMeasure}
      -${this.packagedQuantity}
      -${this.productType}
      -${this.variantType}
      -${this.strain}
      -${this.THC}
      -${this.minTHC}
      -${this.maxTHC}
      -${this.CBD}
      -${this.minCBD}
      -${this.maxCBD}
      -${this.dateCreated}
      -${this.lastModified}
      -${this.cultivatorVerified}
      -${this.delisted}`;
  }

  getStartKey(): string {
    return this.barcode;
  }

  getTHCWithUnits(): string {
    const THC = this.getNumericTHC();
    let thcWithUnits = '';
    if (THC !== -1 && (this?.cannabisUnitOfMeasure !== CannabisUnitOfMeasure.NA)) {
      thcWithUnits = `${THC}${this.cannabisUnitOfMeasure}`;
    }
    return thcWithUnits || '';
  }

  getMinTHCWithUnits(): string {
    const minVal = this.getNumericMinTHC();
    let rangeWithUnits: string = '';
    if (minVal !== Variant.invalidParsedCannabinoid && (this?.cannabisUnitOfMeasure !== CannabisUnitOfMeasure.NA)) {
      rangeWithUnits = `${minVal}${this.cannabisUnitOfMeasure}`;
    }
    return rangeWithUnits || '';
  }

  getMaxTHCWithUnits(): string {
    const maxVal = this.getNumericMaxTHC();
    let rangeWithUnits: string = '';
    if (maxVal !== Variant.invalidParsedCannabinoid && (this?.cannabisUnitOfMeasure !== CannabisUnitOfMeasure.NA)) {
      rangeWithUnits = `${maxVal}${this.cannabisUnitOfMeasure}`;
    }
    return rangeWithUnits || '';
  }

  getCBDWithUnits(): string {
    const CBD = this.getNumericCBD();
    let cbdWithUnits = '';
    if (CBD !== -1 && (this?.cannabisUnitOfMeasure !== CannabisUnitOfMeasure.NA)) {
      cbdWithUnits = `${CBD}${this.cannabisUnitOfMeasure}`;
    }
    return cbdWithUnits || '';
  }

  getMinCBDWithUnits(): string {
    const minVal = this.getNumericMinCBD();
    let rangeWithUnits: string = '';
    if (minVal !== Variant.invalidParsedCannabinoid && (this?.cannabisUnitOfMeasure !== CannabisUnitOfMeasure.NA)) {
      rangeWithUnits = `${minVal}${this.cannabisUnitOfMeasure}`;
    }
    return rangeWithUnits || '';
  }

  getMaxCBDWithUnits(): string {
    const maxVal = this.getNumericMaxCBD();
    let rangeWithUnits: string = '';
    if (maxVal !== Variant.invalidParsedCannabinoid && (this?.cannabisUnitOfMeasure !== CannabisUnitOfMeasure.NA)) {
      rangeWithUnits = `${maxVal}${this.cannabisUnitOfMeasure}`;
    }
    return rangeWithUnits || '';
  }

  getChildIds(): string[] {
    return [this.barcode];
  }

  getId(): string {
    return this.barcode;
  }

}
