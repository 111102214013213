export class RangeSliderOptions {

  public label: string;
  public min: number;
  public max: number;
  public value: number;
  public step: number;
  public showThumbLabel: boolean = true;
  public disabled: boolean = false;
  public verticalOrientation: boolean = false;
  public sliderOnly: boolean = false;
  public inverted: boolean = false;

  static default(): RangeSliderOptions {
    const opt = new RangeSliderOptions();
    opt.min = 0;
    opt.max = 100;
    opt.step = 1;
    return opt;
  }

}
