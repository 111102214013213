import { Section } from '../models/menu/dto/section';
import { SafeResourceUrl } from '@angular/platform-browser';
import { Asset } from '../models/image/dto/asset';
import { UniquelyIdentifiable } from '../models/protocols/uniquely-identifiable';

export class DistinctUtils {

  public static distinctImageUrl = (prev: string | SafeResourceUrl, curr: string | SafeResourceUrl) => prev === curr;
  public static distinctStrings = (prev: string[], curr: string[]) => prev?.sort().join(',') === curr?.sort().join(',');
  public static distinctAsset = (prev: Asset, curr: Asset) => {
    return prev?.getUniqueIdentifier() === curr?.getUniqueIdentifier();
  };
  public static distinctNumber = (a: number, b: number) => a === b;
  public static distinctSection = (prev: Section, curr: Section) => {
    return prev?.getUniqueIdentifier() === curr?.getUniqueIdentifier();
  };
  public static distinctUniquelyIdentifiable = <T extends UniquelyIdentifiable>(prev: T, curr: T) => {
    return prev?.getUniqueIdentifier() === curr?.getUniqueIdentifier();
  };
  public static distinctUniquelyIdentifiableArray = <T extends UniquelyIdentifiable>(prev: T[], curr: T[]) => {
    const prevLength = prev?.length || 0;
    const currLength = curr?.length || 0;
    if (prevLength !== currLength) return false;
    const prevId = prev?.map(m => m?.getUniqueIdentifier())?.sort()?.join(',');
    const currId = curr?.map(m => m?.getUniqueIdentifier())?.sort()?.join(',');
    return prevId === currId;
  };

}
