import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthViewModel } from '../../../viewModels/auth-view-model';
import { ActivatedRoute, Router } from '@angular/router';
import { Routable } from '../../../../../models/protocols/routable';
import { SignInRequest } from '../../../../../models/account/requests/sign-in-request';
import { AuthFlowTitle } from '../../../../../models/account/enum/auth-flow.enum';
import { FormInputItem } from '../../../../../models/shared/stylesheet/form-input-item';
import { FormGroupStyling } from '../../../../../models/shared/stylesheet/form-group-styling';
import { Viewable } from '../../../../../models/protocols/viewable';
import { LoadingOptions } from '../../../../../models/shared/loading-options';
import { BsError } from '../../../../../models/shared/bs-error';
import { ToastService } from '../../../../../services/toast-service';
import { RouteUtils } from '../../../../../utils/route-utils';
import { BaseComponent } from '../../../../../models/base/base-component';
import { FormOptions } from '../../../../../models/shared/stylesheet/form-options';
import { SharedAuthForm } from '../../forms/shared-auth-form';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: [
    './sign-in.component.scss',
    '../auth.component.scss'
  ]
})
export class SignInComponent extends BaseComponent implements OnInit, Viewable, Routable, OnDestroy {

  // View
  public signInFormItems: FormInputItem[] = [];
  public formStyling: FormGroupStyling = new FormGroupStyling();
  public formOptions: FormOptions = new FormOptions();
  public loadingOpts: LoadingOptions = LoadingOptions.default();
  // Variables
  public req: SignInRequest = new SignInRequest();

  constructor(
    public viewModel: AuthViewModel,
    private route: ActivatedRoute,
    private router: Router,
    private toastService: ToastService,
  ) {
    super();
  }

  override ngOnInit(): void {
    this.bindUrlParams();
    this.setupViews();
  }

  override ngOnDestroy(): void {
    this.destroy();
    this.viewModel.destroy();
  }

  override setupViews() {
    this.setupFormItems();
    this.setupFormStyling();
    this.setupFormOptions();
  }

  setupFormItems() {
    this.signInFormItems = [];
    // Set up form items
    this.signInFormItems.push(SharedAuthForm.emailInput());
    this.signInFormItems.push(SharedAuthForm.passwordInput());
  }

  setupFormStyling() {
    // Set up form styling
    this.formStyling.primaryButtonFloat = 'left';
    this.formStyling.submitButtonText = 'Continue';
    this.formStyling.secondaryButtonFloat = 'right';
    this.formStyling.secondaryButtonText = 'Forgot Password?';
    this.formStyling.secondaryButtonClass = 'text-button';
    this.formStyling.checkboxLabel = 'Remember me';
  }

  setupFormOptions() {
    this.formOptions.includeEndFormCheckbox = true;
    this.formOptions.checkboxBindingProperty = ['rememberMe', this.req.rememberMe];
  }

  bindUrlParams() {
    this.route.queryParams.subscribe((params) => {
      this.req.email = decodeURIComponent(decodeURIComponent(params.Email || params.email || ''));
      this.req.password = decodeURIComponent(decodeURIComponent(params.Password || params.password || ''));
    });
    this.router.navigate([RouteUtils.cleanedUrlRoute(this.router.url)], {queryParams: {}});
  }

  getAuthFlowTitle(): string {
    return AuthFlowTitle(this.viewModel.authFlow);
  }

  // View Actions

  formSubmitted(req: SignInRequest) {
    const loadingMess = 'Signing In.';
    if (!this.loadingOpts.containsRequest(loadingMess)) {
      this.loadingOpts.addRequest(loadingMess);
      this.viewModel.signIn(req).subscribe((_) => {
        this.loadingOpts.removeRequest(loadingMess);
      }, (error: BsError) => {
        this.loadingOpts.removeRequest(loadingMess);
        this.toastService.publishError(error);
      });
    }
  }

  secondaryButtonPressed() {
    const tempEmail = FormInputItem.getItemValue(this.signInFormItems, 'email');
    this.router.navigate(['auth/forgot-password'], {
      queryParams: {
        Email: tempEmail
      }
    }).then(() => {
    });
  }

}
