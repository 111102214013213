import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { SessionService } from '../session-service';

@Injectable({
  providedIn: 'root'
})
export class PermissionGuard  {

  constructor(
    public sessionService: SessionService,
    public router: Router
  ) {
  }

  public findTerm = (href, term) => {
    if (href.includes(term)) {
      return href;
    }
  };

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return new Observable<boolean>(subscriber => {
      this.sessionService.sessionContainer$.firstNotNull().subscribe((_) => {
        subscriber.next(this.routeIsPermitted(state));
        subscriber.complete();
      });
    });
  }

  routeIsPermitted(state: RouterStateSnapshot): boolean {
    let isPermitted = true;
    const sess = this.sessionService.sessionContainer.getValue();
    if (sess && sess.user) {
      switch (state.url) {
        // Example
        case this.findTerm(state.url, '/products'):
        case this.findTerm(state.url, '/account'):
        case this.findTerm(state.url, '/sign-out'):
          // Handle case so that all other routes are blocked if productOnlyAccess.
          // The above routes are accessible for all users
          break;
        default:
          if (sess.user.productOnlyAccess) {
            isPermitted = false;
          }
      }
    }
    if (!isPermitted) {
      if (sess.user.productOnlyAccess) {
        this.router.navigate(['products/universalvariants']).then(() => {
        });
      } else {
        this.router.navigate(['']).then(() => {
        });
      }
    }
    return isPermitted;
  }

}
