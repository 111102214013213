import { AccountDomainModel } from '../../../domainModels/account-domain-model';
import { Injectable } from '@angular/core';
import { HydratedAdminUser } from '../../../models/account/dto/hydrated-admin-user';
import { LoadingOptions } from '../../../models/shared/loading-options';
import { UpdateUserInfoRequest } from '../../../models/account/requests/update-user-info-request';
import { BaseViewModel } from '../../../models/base/base-view-model';
import { BsError } from '../../../models/shared/bs-error';
import { ToastService } from '../../../services/toast-service';
import { ConfirmCodeRequest } from '../../../models/account/requests/confirm-code-request';
import { SessionService } from '../../../services/session-service';
import { ConfirmationCodeValidatorDirective } from '../../shared/components/form-group/validators/confirmation-code-validator.directive';
import { ConfirmationFlow } from '../../../models/account/enum/confirmation-flow.enum';
import { BehaviorSubject, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AccountViewModel extends BaseViewModel {

  public user: HydratedAdminUser;

  // Form
  public emailConfirmationReq: ConfirmCodeRequest = new ConfirmCodeRequest();
  public accountInfoReq: UpdateUserInfoRequest = new UpdateUserInfoRequest();

  public disableSaveChanges: boolean = true;
  public confirmationCodeValid$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public emailConfirmationCodeCustomValidator = [new ConfirmationCodeValidatorDirective()];
  public unsavedChanges: boolean = false;

  // Loading
  protected override _loadingOpts = new BehaviorSubject(AccountViewModel.getLoadingSpinner());

  constructor(
    private domainModel: AccountDomainModel,
    private toastService: ToastService,
    private session: SessionService,
  ) {
    super();
    this.setupBindings();
  }

  private static getLoadingSpinner(): LoadingOptions {
    const opts = LoadingOptions.default();
    opts.loadingText = 'Updating Profile';
    opts.isLoading = false;
    opts.fullscreen = false;
    return opts;
  }

  private setFormParams() {
    if (this.user.firstName !== '' && !!this.user.firstName) {
      this.accountInfoReq.firstName = this.user.firstName;
    }
    if (this.user.lastName !== '' && !!this.user.lastName) {
      this.accountInfoReq.lastName = this.user.lastName;
    }
    if (this.user.email !== '' && !!this.user.email) {
      this.accountInfoReq.email = this.user.email;
    }
  }

  private setupBindings() {
    const sub = this.domainModel.session.sessionContainer$.notNull().subscribe((sess) => {
      if (sess) {
        this.user = sess.user;
        this.setFormParams();
      }
    });
    this.subscriptions.push(sub);
  }

  resendCode() {
    const loadingMess = 'Resending Confirmation Code';
    if (!this._loadingOpts.containsRequest(loadingMess)) {
      this._loadingOpts.addRequest(loadingMess);
      this.resendEmailConfirmation(loadingMess);
    }
  }

  emailConfirmationSubmitted(req: ConfirmCodeRequest) {
    req.accessToken = this.session.getAuthToken();
    req.userId = this.session.getUserId();
    req.flow = ConfirmationFlow.ConfirmationFlow_Email;
    const loadingMess = 'Confirming Email';
    if (!this._loadingOpts.containsRequest(loadingMess)) {
      this._loadingOpts.addRequest(loadingMess);
      this.confirmEmail(req, loadingMess);
    }
  }

  accountInformationSubmitted(req: UpdateUserInfoRequest) {
    const loadingMess = 'Updating Profile';
    if (!this._loadingOpts.containsRequest(loadingMess)) {
      this._loadingOpts.addRequest(loadingMess);
      this.updateUser(req, loadingMess);
    }
  }

  public canDeactivate(): boolean | Promise<any> {
    return !this.unsavedChanges;
  }

  private updateUser(update: UpdateUserInfoRequest, loadingMess: string) {
    const userCopy = window.injector.Deserialize.instanceOf(HydratedAdminUser, this.user);
    userCopy.firstName = update.firstName;
    userCopy.lastName = update.lastName;
    userCopy.email = update.email;
    this.domainModel.updateUser(userCopy).subscribe((_) => {
      this._loadingOpts.removeRequest(loadingMess);
      this.toastService.publishSuccessMessage('Completed', 'Profile Updated');
    }, (error: BsError) => {
      this._loadingOpts.removeRequest(loadingMess);
      this.toastService.publishError(error);
      throwError(error);
    });
  }

  private confirmEmail(req: ConfirmCodeRequest, loadingMess: string) {
    this.domainModel.confirmEmail(req).subscribe((_) => {
      this._loadingOpts.removeRequest(loadingMess);
      this.toastService.publishSuccessMessage('Your email has been confirmed.', 'Email Confirmation');
    }, (error: BsError) => {
      this._loadingOpts.removeRequest(loadingMess);
      this.toastService.publishError(error);
      throwError(error);
    });
  }

  private resendEmailConfirmation(loadingMess: string) {
    const e = this.session.getUserEmail();
    this.domainModel.resendEmailConfirmationCode(e).subscribe((_) => {
      this._loadingOpts.removeRequest(loadingMess);
      this.toastService.publishSuccessMessage('Check your email.', 'Code Resent');
    }, (error: BsError) => {
      this._loadingOpts.removeRequest(loadingMess);
      this.toastService.publishError(error);
      throwError(error);
    });
  }

}
