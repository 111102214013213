import { TypeDefinition } from './type-definition';

export enum SectionType {
  Product = 'SectionType_Product',
  Spotlight = 'SectionType_Spotlight',
  Title = 'SectionType_Title',
  Image = 'SectionType_Image',
  PageBreak = 'SectionType_PageBreak',
  CategoryCard = 'SectionType_CategoryCard',
  CardStack = 'SectionType_CardStack',
  ProductGroup = 'SectionType_ProductGroup',
  PrintReportNewProducts = 'SectionType_PrintReportNewProducts',
  PrintReportRestockedProducts = 'SectionType_PrintReportRestockedProducts'
}

export class SectionTypeDefinition extends TypeDefinition {

  override value: SectionType;

  public supportsBlueprintCreation(): boolean {
    return this.value === SectionType.Product || this.value === SectionType.ProductGroup;
  }

  public isProductMenuType(): boolean {
    return this.value === SectionType.Product || this.value === SectionType.Title || this.value === SectionType.Image;
  }

  public isSmartPlaylistType(): boolean {
    return this.value === SectionType.ProductGroup;
  }

}
