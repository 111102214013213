import { AccountDomainModel } from '../../../domainModels/account-domain-model';
import { AuthFlow, AuthFlowSubtext, AuthFlowTitle } from '../../../models/account/enum/auth-flow.enum';
import { SignInRequest } from '../../../models/account/requests/sign-in-request';
import { BehaviorSubject, Observable } from 'rxjs';
import { HydratedAdminUser } from '../../../models/account/dto/hydrated-admin-user';
import { Injectable, OnDestroy } from '@angular/core';
import { ForgotPasswordRequest } from '../../../models/account/requests/forgot-password-request';
import { CodeDeliveryDetails } from '../../../models/account/dto/code-delivery-details';
import { ResetPasswordRequest } from '../../../models/account/requests/reset-password-request';
import { SignInNewPasswordRequest } from '../../../models/account/requests/sign-in-new-password-request';
import { CacheService } from '../../../services/cache-service';
import { BaseViewModel } from '../../../models/base/base-view-model';
import { switchMap, take, tap } from 'rxjs/operators';

@Injectable()
export class AuthViewModel extends BaseViewModel implements OnDestroy {

  // Observables
  public nextAuthFlow: BehaviorSubject<AuthFlow> = new BehaviorSubject<AuthFlow>(null);
  public authSuccess: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private _user: BehaviorSubject<HydratedAdminUser|null> = new BehaviorSubject<HydratedAdminUser|null>(null);
  public user$ = this._user as Observable<HydratedAdminUser|null>;

  constructor(
    private domainModel: AccountDomainModel,
    private cacheService: CacheService
  ) {
    super();
    this.setupBindings();
  }

  // Properties
  public authFlow: AuthFlow;

  override ngOnDestroy(): void {
    this.destroy();
  }

  setupBindings() {
    // Bind to SessionContainer to navigate to necessary Auth flow
    const sessUserSub = this.domainModel.session.sessionContainer$.notNull().subscribe((sess) => {
      if (sess?.user?.session?.challenge) {
        // Navigate the user to the new password page
        this.nextAuthFlow.next(AuthFlow.SetNewPassword);
      } else if (sess?.user?.session?.validSession()) {
        // Navigate the user to the account page
        this.authSuccess.next(true);
      } else if (sess?.codeDeliveryDetails) {
        // Navigate the user to the reset password page
        this.nextAuthFlow.next(AuthFlow.ResetPassword);
      } else {
        // Navigate to the Sign in flow. If reset flow succeeds but the user has explicitly signed out,
        // then no refresh token exists on server, so force a new log in
        this.nextAuthFlow.next(AuthFlow.SignIn);
      }
    });
    this.pushSub(sessUserSub);
  }

  // Auth Methods

  public signIn(req: SignInRequest): Observable<HydratedAdminUser> {
    return this.tapIntoUser(this.domainModel.signIn(req));
  }

  public forgotPassword(req: ForgotPasswordRequest): Observable<CodeDeliveryDetails> {
    return this.domainModel.forgotPassword(req);
  }

  public resetPassword(req: ResetPasswordRequest): Observable<HydratedAdminUser> {
    return this.tapIntoUser(this.domainModel.resetPassword(req));
  }

  public signInNewPassword(req: SignInNewPasswordRequest): Observable<HydratedAdminUser> {
    return this.user$.pipe(take(1), switchMap(user => {
      req.userId = user?.userId;
      req.session = user?.session?.challenge?.authSession;
      return this.tapIntoUser(this.domainModel.signInNewPassword(req));
    }));
  }

  private tapIntoUser(userPipe$: Observable<HydratedAdminUser|null>): Observable<HydratedAdminUser|null> {
    return userPipe$.pipe(tap(user => this._user.next(user)));
  }

  // Auth Flow

  getAuthFlowTitle(): string {
    return AuthFlowTitle(this.authFlow);
  }

  getAuthFlowSubtext(): string {
    return AuthFlowSubtext(this.authFlow);
  }

  clearCaches() {
    this.cacheService.clearSessionCache();
    this.cacheService.clearPersistentCache();
  }

}
