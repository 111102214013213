import { Deserializable } from '../../protocols/deserializable';

export class SignInRequest implements Deserializable {

  public email: string = '';
  public password: string = '';
  public rememberMe: boolean = true;

  onDeserialize() {
  }

}
