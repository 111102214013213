import { TypeDefinition } from './type-definition';

export enum AdminSectionColumnConfigKey {
  Asset = 'Asset',
  Badges = 'Badges',
  Brand = 'Brand',
  StrainType = 'ClassificationType', // Underlying value must remain ClassificationType for backwards compatibility
  Quantity = 'Quantity',
  Size = 'Size',
  QuantityAndSize = 'QuantityAndSize',
  Stock = 'Stock',
  THC = 'THC',
  CBD = 'CBD',
  SecondaryCannabinoids = 'SecondaryCannabinoids',
  TopTerpene = 'TopTerpene',
  TotalTerpene = 'TotalTerpene',
  IndividualTerpenes = 'IndividualTerpenes',
  Price = 'Price',
  SecondaryPrice = 'SecondaryPrice',
}

export class AdminSectionColumnConfigKeyType extends TypeDefinition {

  override value: AdminSectionColumnConfigKey;

}
