import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../../models/base/base-view-model';
import { AccountDomainModel } from '../../../../../domainModels/account-domain-model';
import { ToastService } from '../../../../../services/toast-service';
import { BsError } from '../../../../../models/shared/bs-error';
import { BehaviorSubject } from 'rxjs';
import { LoadingOptions } from '../../../../../models/shared/loading-options';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class SignOutViewModel extends BaseViewModel {

  constructor(
    protected router: Router,
    protected accountDomainModel: AccountDomainModel,
    protected toastService: ToastService,
  ) {
    super();
  }

  public override loadingOpts$ = new BehaviorSubject<LoadingOptions>(LoadingOptions.default());
  public override isLoading$ = this.loadingOpts$.pipe(map(it => it?.isLoading));

  signOut() {
    const loadingMess = 'Signing Out';
    this.loadingOpts$.addRequest(loadingMess);
    return this.accountDomainModel.signOut().subscribe({
      complete: () => {
        this.loadingOpts$.removeRequest(loadingMess);
        this.router.navigate(['/auth/sign-in']).then();
      },
      error: (error: BsError) => {
        this.loadingOpts$.removeRequest(loadingMess);
        this.toastService.publishError(error);
        this.router.navigate(['/auth/sign-in']).then();
      }
    });
  }

}
