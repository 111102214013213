import { Deserializable } from '../../protocols/deserializable';

export class ForgotPasswordRequest implements Deserializable {

  public email: string = '';

  onDeserialize() {
  }

}
