import { Deserializable } from '../../protocols/deserializable';
import { Cachable } from '../../protocols/cachable';
import { DateUtils } from '../../../utils/date-utils';
import { LabelsFormReq } from '../shared/labels-form-req';
import { UniquelyIdentifiable } from '../../protocols/uniquely-identifiable';
import { DefaultSectionColumnConfig } from '../../menu/dto/default-section-column-config';
import { VariantType } from '../../../utils/variant-type-definition';
import { CannabisUnitOfMeasure } from '../../../utils/cannabis-unit-of-measure-type';
import { CannabinoidDisplayType } from '../../../utils/cannabinoid-display-type-definition';
import { LabelStyle } from '../../enum/shared/label-style.enum';
import { SecondaryCannabinoids } from '../../../utils/secondary-cannabinoids-type-definition';
import { Terpene } from '../../../utils/terpene-type-definition';

// For default label colors, we want to choose something uncommon to ensure the user does not choose it as
// their initial selection
export const DEFAULT_COLOR_PICKER_COLOR = '#F1F2F3';

export class CompanyConfiguration implements Deserializable, Cachable, UniquelyIdentifiable {

  public companyId: number;
  public integrationDisabled: boolean = false;
  public lastProductSync: number;
  public lastInventorySync: number;
  public lastLocationSync: number;
  public lastPricingSync: number;
  public lastLotInfoSync: number;
  public lastDisplayNameSync: number;
  public lowStockThreshold: number;
  public restockThresholdHours: number;
  public labelColorMap: Map<string, string>;
  public labelTextColorMap: Map<string, string>;
  public labelTextMap: Map<string, string>;
  public labelStyle: LabelStyle;
  public defaultCannabisUnitOfMeasure: Map<VariantType, CannabisUnitOfMeasure>;
  public defaultColumnConfigs: DefaultSectionColumnConfig[];
  public syncPOSCannabinoid: boolean = false;
  public syncPOSTerpene: boolean = false;
  public syncPOSLabels: boolean = false;
  public syncDisplayNames: boolean = false;
  public showDiscontinuedProducts: boolean;
  public cannabinoidDisplayType: CannabinoidDisplayType = CannabinoidDisplayType.Exact;
  public enabledCannabinoids: SecondaryCannabinoids[] = [];
  public enabledTerpenes: Terpene[] = [];
  // Cache
  cachedTime: number;

  static buildCacheKey(id: string): string {
    return `CompanyConfiguration-${id}`;
  }

  public onDeserialize() {
    if (!this.labelColorMap) {
      this.labelColorMap = new Map<string, string>();
    } else if (!(this.labelColorMap instanceof Map)) {
      this.labelColorMap = window.injector.Deserialize.genericMap(this.labelColorMap);
    }
    if (!this.labelTextColorMap) {
      this.labelTextColorMap = new Map<string, string>();
    } else if (!(this.labelTextColorMap instanceof Map)) {
      this.labelTextColorMap = window.injector.Deserialize.genericMap(this.labelTextColorMap);
    }
    if (!this.labelTextMap) {
      this.labelTextMap = new Map<string, string>();
    } else if (!(this.labelTextMap instanceof Map)) {
      this.labelTextMap = window.injector.Deserialize.genericMap(this.labelTextMap);
    }
    if (!this.cannabinoidDisplayType) {
      this.cannabinoidDisplayType = CannabinoidDisplayType.Exact;
    }
    if (!this.defaultCannabisUnitOfMeasure) {
      this.defaultCannabisUnitOfMeasure = new Map<VariantType, CannabisUnitOfMeasure>();
    } else if (!(this.defaultCannabisUnitOfMeasure instanceof Map)) {
      this.defaultCannabisUnitOfMeasure = window.injector.Deserialize.genericMap(this.defaultCannabisUnitOfMeasure);
    }
    this.defaultColumnConfigs = Array.from(this.defaultColumnConfigs || []);
    this.enabledCannabinoids = Array.from(this.enabledCannabinoids || []);
    this.enabledTerpenes = Array.from(this.enabledTerpenes || []);
  }

  cacheExpirySeconds(): number {
    return DateUtils.secondsInOneHour();
  }

  cacheKey(): string {
    return CompanyConfiguration.buildCacheKey(this.companyId.toString());
  }

  isExpired(): boolean {
    const expiresAt = this.cachedTime + this.cacheExpirySeconds();
    return DateUtils.currentTimestampInSeconds() > expiresAt;
  }

  setPropertiesFromLabelsFormReq(req: LabelsFormReq): CompanyConfiguration {
    this.labelTextMap.clear();
    this.labelTextMap.set('Featured', req.featureLabelText);
    this.labelTextMap.set('Sale', req.saleLabelText);
    this.labelTextMap.set('LowStock', req.lowStockLabelText);
    this.labelTextMap.set('Restocked', req.restockLabelText);
    this.labelColorMap.clear();
    this.labelColorMap.set('Featured', req.featureLabelColor);
    this.labelColorMap.set('Sale', req.saleLabelColor);
    this.labelColorMap.set('LowStock', req.lowStockLabelColor);
    this.labelColorMap.set('Restocked', req.restockLabelColor);
    this.labelTextColorMap.clear();
    const featuredVal = req.featureLabelTextColor === DEFAULT_COLOR_PICKER_COLOR ? '' : req.featureLabelTextColor;
    this.labelTextColorMap.set('Featured', featuredVal);
    const saleVal = req.saleLabelTextColor === DEFAULT_COLOR_PICKER_COLOR ? '' : req.saleLabelTextColor;
    this.labelTextColorMap.set('Sale', saleVal);
    const lowStockVal = req.lowStockLabelTextColor === DEFAULT_COLOR_PICKER_COLOR ? '' : req.lowStockLabelTextColor;
    this.labelTextColorMap.set('LowStock', lowStockVal);
    const restockedVal = req.restockLabelTextColor === DEFAULT_COLOR_PICKER_COLOR ? '' : req.restockLabelTextColor;
    this.labelTextColorMap.set('Restocked', restockedVal);
    this.lowStockThreshold = parseInt(req.lowStockThreshold, 10);
    this.restockThresholdHours = parseInt(req.restockThreshold, 10);
    return this;
  }

  getUniqueIdentifier(...opts: any): string {
    const defaultCannabisUnit: string[] = [];
    this.defaultCannabisUnitOfMeasure?.forEach((val, key) => defaultCannabisUnit.push(`${key}-${val}`));
    const defaultCannabisUnitId = defaultCannabisUnit.sort().join(',') ?? '';
    const enabledCannabinoids = this.enabledCannabinoids?.sort().join(',') ?? '';
    const enabledTerpenes = this.enabledTerpenes?.sort()?.join(',') ?? '';

    return `${this.companyId}
      -${this.lastProductSync}
      -${this.lastInventorySync}
      -${this.lastLocationSync}
      -${this.lastPricingSync}
      -${this.lastLotInfoSync}
      -${this.lastDisplayNameSync}
      -${this.integrationDisabled}
      -${this.syncPOSCannabinoid}
      -${this.syncPOSTerpene}
      -${this.syncPOSLabels}
      -${this.syncDisplayNames}
      -${this.showDiscontinuedProducts}
      -${this.cannabinoidDisplayType}
      -${this.labelStyle}
      -${defaultCannabisUnitId}
      -${enabledCannabinoids}
      -${enabledTerpenes}`;
  }

}

