import { HydratedAdminUser } from '../account/dto/hydrated-admin-user';
import { DateUtils } from '../../utils/date-utils';
import { CodeDeliveryDetails } from '../account/dto/code-delivery-details';
import { Cachable } from '../protocols/cachable';
import { DefaultCacheKey } from '../enum/shared/default-cache-key.enum';

export class SessionContainer implements Cachable {

  // Shared properties
  public user: HydratedAdminUser;
  public codeDeliveryDetails: CodeDeliveryDetails;
  // Session Settings
  public sessionStartTime: number;
  public rememberSession: boolean;
  public cachedTime: number;

  constructor(user?: HydratedAdminUser) {
    this.user = user;
  }

  public validSession(): boolean {
    let valid = false;
    if (this.user &&
      this.user.session &&
      this.user.session.validSession()) {
      valid = true;
    }
    return valid;
  }

  cacheExpirySeconds(): number {
    return DateUtils.secondsInOneWeek();
  }

  cacheKey(): string {
    return DefaultCacheKey.SessionContainer;
  }

  isExpired(): boolean {
    const expiresAt = this.cachedTime + this.cacheExpirySeconds();
    return DateUtils.currentTimestampInSeconds() > expiresAt;
  }

  onDeserialize() {
    this.user = window.injector.Deserialize.instanceOf(HydratedAdminUser, this.user);
    this.codeDeliveryDetails = window.injector.Deserialize.instanceOf(CodeDeliveryDetails, this.codeDeliveryDetails);
  }

}
