import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { SessionService } from '../services/session-service';
import { SignInRequest } from '../models/account/requests/sign-in-request';
import { AccountAPI } from '../api/account-api';
import { catchError, map } from 'rxjs/operators';
import { HydratedAdminUser } from '../models/account/dto/hydrated-admin-user';
import { SessionContainer } from '../models/shared/session-container';
import { ForgotPasswordRequest } from '../models/account/requests/forgot-password-request';
import { CodeDeliveryDetails } from '../models/account/dto/code-delivery-details';
import { ResetPasswordRequest } from '../models/account/requests/reset-password-request';
import { SignInNewPasswordRequest } from '../models/account/requests/sign-in-new-password-request';
import { ChangePasswordRequest } from '../models/account/requests/change-password-request';
import { CacheService } from '../services/cache-service';
import { DefaultCacheKey } from '../models/enum/shared/default-cache-key.enum';
import { BsError } from '../models/shared/bs-error';
import { BaseDomainModel } from '../models/base/base-domain-model';
import { Session } from '../models/account/dto/session';
import { ConfirmCodeRequest } from '../models/account/requests/confirm-code-request';
import { ImageAPI } from '../api/image-api';
import { CachePolicy } from '../models/enum/shared/cachable-image-policy.enum';
import { CreateUserRequest } from '../models/account/requests/create-user-request';
import { AdminUser } from '../models/account/dto/admin-user';
import { ToastService } from '../services/toast-service';

@Injectable({
  providedIn: 'root'
})

export class AccountDomainModel extends BaseDomainModel {

  public refreshSessionResult: BehaviorSubject<Session> = new BehaviorSubject<Session>(null);

  // Users
  private users: BehaviorSubject<AdminUser[]> = new BehaviorSubject<AdminUser[]>(null);
  public users$ = this.users.asObservable();

  public user$ = this.session.sessionContainer$.notNull().pipe(map(session => session?.user));

  constructor(
    public session: SessionService,
    private accountAPI: AccountAPI,
    private imageAPI: ImageAPI,
    private cacheService: CacheService,
    private toastService: ToastService,
  ) {
    super();
    this.setupBindings();
  }

  public isAuthenticated(forceRefresh: boolean = false): Observable<Session> {
    // get session container
    let sess: SessionContainer = this.session.sessionContainer.getValue() as SessionContainer;
    if (sess === null || !sess) {
      sess = this.session.getCachedSession();
    }
    // Check session validity and refresh
    if (sess?.validSession() && !this.session.refreshingSession.getValue() && !forceRefresh) {
      // Valid Session
      return of(sess.user.session);
    } else {
      // Attempt to refresh session
      return new Observable<Session>(observer => {
        if (this.session.refreshingSession.getValue()) {
          this.refreshSessionResult.firstNotNull().subscribe((rSess) => {
            observer.next(rSess);
          });
        } else {
          this.session.refreshingSession.next(true);
          const req = this.session.getRefreshSessionReq(sess);
          if (req) {
            this.accountAPI.RefreshSession(req).subscribe((user) => {
              const remember = this.session.sessionContainer.getValue()?.rememberSession;
              this.session.setUser(user, true, remember);
              setTimeout(() => {
                // Add small delay so the session can set before continuing API calls.
                this.session.refreshingSession.next(false);
                this.refreshSessionResult.next(user.session);
                observer.next(user.session);
              }, 1000);
            }, (_: BsError) => {
              this.session.refreshingSession.next(false);
              this.refreshSessionResult.next(null);
              this.session.destroySession.next(true);
              observer.next(null);
              this.toastService.publishErrorMessage(
                'For security reasons, your token has expired. Please log in again.',
                'You have been signed out.'
              );
              return this.signOut().pipe(
                map(() => null)
              );
            });
          } else {
            this.session.refreshingSession.next(false);
            this.refreshSessionResult.next(null);
            observer.next(null);
          }
        }
      });
    }
  }

  public signOut(): Observable<any> {
    const req = this.session.getSignOutReq();
    return this.accountAPI.SignOut(req).pipe(
      map((r) => {
        // Clear the user session from the persistent cache
        this.cacheService.removeCachedObject(DefaultCacheKey.SessionContainer, CachePolicy.Persistent);
        this.cacheService.clearSessionCache();
        this.session.destroySession.next(true);
        return r;
      }),
      catchError(err => {
        // If sign out request fails, we want to kill all caches any destroy session
        this.cacheService.removeCachedObject(DefaultCacheKey.SessionContainer, CachePolicy.Persistent);
        this.cacheService.clearSessionCache();
        this.session.destroySession.next(true);
        return err;
      })
    );
  }

  // Auth Methods

  public signIn(req: SignInRequest): Observable<HydratedAdminUser> {
    return this.accountAPI.SignIn(req).pipe(
      map((user) => {
        // Clear existing session from cache
        this.cacheService.removeCachedObject(DefaultCacheKey.SessionContainer);
        this.cacheService.removeCachedObject(DefaultCacheKey.SessionContainer, CachePolicy.Persistent);
        // Set the new user in the session
        this.session.setUser(user, true, req.rememberMe);
        return user;
      })
    );
  }

  public forgotPassword(req: ForgotPasswordRequest): Observable<CodeDeliveryDetails> {
    return this.accountAPI.GetForgotPasswordCode(req.email).pipe(
      map((deliveryDetails) => {
        this.session.setDeliveryDetails(deliveryDetails);
        return deliveryDetails;
      })
    );
  }

  public resetPassword(req: ResetPasswordRequest): Observable<HydratedAdminUser> {
    return this.accountAPI.ResetForgottenPassword(req).pipe(
      map((user) => {
        this.session.setUser(user, true);
        return user;
      })
    );
  }

  public signInNewPassword(req: SignInNewPasswordRequest): Observable<HydratedAdminUser> {
    // Populate request from current session
    return this.accountAPI.SignInNewPassword(req).pipe(
      map((user) => {
        this.session.setUser(user, true);
        return user;
      })
    );
  }

  private setupBindings() {
    // Bind to destroy session
    const destroySess = this.session.destroySession.notNull().subscribe((_) => {
      this.session.refreshingSession.next(false);
      this.refreshSessionResult.next(null);
    });
    this.pushSub(destroySess);
  }

  public changePassword(req: ChangePasswordRequest): Observable<HydratedAdminUser> {
    return this.accountAPI.ChangePassword(req).pipe(
      map((user) => {
        this.session.setUser(user, true);
        return user;
      })
    );
  }

  public updateUser(req: HydratedAdminUser): Observable<HydratedAdminUser> {
    return this.accountAPI.UpdateUser(req).pipe(
      map((user) => {
        this.session.setUser(user, true);
        return user;
      })
    );
  }

  public confirmEmail(req: ConfirmCodeRequest): Observable<HydratedAdminUser> {
    return this.accountAPI.ConfirmCode(req).pipe(
      map((user) => {
        this.session.setUser(user, true);
        return user;
      })
    );
  }

  public resendEmailConfirmationCode(email: string): Observable<CodeDeliveryDetails> {
    return this.accountAPI.ResendCode(email, this.session.getAuthToken());
  }

  // Admin Functions

  public getUsers(): Observable<HydratedAdminUser[]> {
    return this.accountAPI.AdminGetUsers().pipe(
      map(users => {
        this.users.next(users);
        return users;
      })
    );
  }

  public createUser(req: CreateUserRequest): Observable<HydratedAdminUser> {
    return this.accountAPI.AdminCreateUser(req).pipe(
      map(newUser => {
        const users = this.users.getValue();
        users.push(newUser);
        this.users.next(users);
        return newUser;
      })
    );
  }

  public deleteUser(user: AdminUser): Observable<string> {
    return this.accountAPI.AdminDeleteUser(user).pipe(
      map(s => {
        const users = this.users.getValue();
        const i = users.findIndex(u => u.userId === user.userId);
        if (i > -1) {
          users.splice(i, 1);
        }
        this.users.next(users);
        return s;
      })
    );
  }

  public updateAdminUser(user: AdminUser): Observable<AdminUser> {
    return this.accountAPI.AdminUpdateUser(user).pipe(
      map(updatedUser => {
        const users = this.users.getValue();
        const i = users.findIndex(u => u.userId === user.userId);
        if (i > -1) {
          users[i] = updatedUser;
        }
        this.users.next(users);
        return user;
      })
    );
  }

  public resendConfirmationEmail(email: string): Observable<CodeDeliveryDetails> {
    return this.accountAPI.ResendCode(email);
  }

}
