<div [ngClass]="{ 'label-group-padding': styling.includePadding }" class="bs-label-group-container">
  <ng-container *ngFor="let item of labelItems">
    <!--   Divider Item   -->
    <div *ngIf="item.itemType === itemTypes.Divider" class="label-group-container">
      <div class="label-group-divider"></div>
    </div>

    <!--   Title Item   -->
    <div *ngIf="item.itemType === itemTypes.Title" class="label-group-container">
      <div class="label-group-title">
        {{ item.value }}
      </div>
      <span *ngIf="item.tooltipText && item.tooltipText !== ''" class="tooltip-container">
        <ng-template #item.label>
          {{ item.tooltipText }}
        </ng-template>
        <img [ngbTooltip]="item.tooltipText" [src]="'assets/icons/dark/outline/information-circle.svg'" alt="" />
      </span>
    </div>

    <div
      *ngIf="item.itemType !== itemTypes.Divider && item.itemType !== itemTypes.Title"
      [ngClass]="{
        'two-column':
          styling.numberColumns === 2 &&
          item.itemType !== itemTypes.ButtonListItem &&
          item.itemType !== itemTypes.SmallText
      }"
      [ngStyle]="{
        margin:
          item.itemType === itemTypes.ButtonListItem || item.itemType === itemTypes.IconListItem
            ? '0 0.75rem'
            : '0.75rem'
      }"
      class="row bs-label-group">
      <div [ngClass]="{ inline: item.inlineLabel }" class="bs-label-group-item">
        <label
          [hidden]="item.hideLabel"
          [ngClass]="{
            'inline-label': item.inlineLabel,
            'equal-inline-label': item.inlineLabel && item.equalInlineLabel
          }"
          class="control-label bs-textfield-label">
          {{ item.label }}
          <span *ngIf="item.tooltipText && item.tooltipText !== ''" class="tooltip-container">
            <ng-template #item.label>
              {{ item.tooltipText }}
            </ng-template>
            <img [ngbTooltip]="item.tooltipText" [src]="'assets/icons/dark/outline/information-circle.svg'" alt="" />
          </span>
        </label>

        <!--   Text Item   -->
        <div
          *ngIf="item.itemType === itemTypes.Text"
          [ngClass]="{ 'inline-textfield-container': item.inlineLabel }"
          class="label-group-container">
          <div class="label-item">
            {{ item.value }}
          </div>
        </div>

        <!--   Small Text Item   -->
        <div
          *ngIf="item.itemType === itemTypes.SmallText"
          [ngClass]="{ 'inline-textfield-container': item.inlineLabel }"
          class="label-group-container">
          <div class="small-text-item">
            {{ item.value }}
          </div>
        </div>

        <!--   Label Item   -->
        <div
          *ngIf="item.itemType === itemTypes.Label"
          [ngClass]="{ 'inline-textfield-container': item.inlineLabel }"
          class="label-group-container">
          <div class="label-item">
            <div [hidden]="item.value" class="label-item">-</div>
            <span
              [hidden]="!item.value || item.value === ''"
              [ngStyle]="{ 'background-color': item.backgroundColor, color: item.color }"
              class="bs-label neutral-label">
              {{ item.value }}
            </span>
          </div>
        </div>

        <!--   Button List Item   -->
        <div *ngIf="item.itemType === itemTypes.ButtonListItem" class="label-group-container">
          <div class="label-item">
            <div class="label-list-item">
              {{ item.value }}
            </div>
            <button
              (click)="item.actionEmitter.next(item)"
              [ngClass]="item.actionButtonClass"
              class="bs-button label-list-button float-right">
              {{ item.actionButtonText }}
            </button>
          </div>
        </div>

        <!--  Icon List Item   -->
        <div
          *ngIf="item.itemType === itemTypes.IconListItem"
          [ngClass]="{ inline: item.inlineLabel, 'equal-inline-label': item.inlineLabel && item.equalInlineLabel }"
          class="label-group-container">
          <div class="label-item">
            <div class="label-list-img float-right">
              <img [hidden]="!item.iconSrc" [src]="item.iconSrc" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
