import { Deserializable } from '../../protocols/deserializable';
import { CodeDeliveryDetails } from './code-delivery-details';
import { Session } from './session';
import { AdminUser } from './admin-user';
import { Asset } from '../../image/dto/asset';

export class HydratedAdminUser extends AdminUser implements Deserializable {

  public profilePicture: Asset;
  public codeDeliveryDetails: CodeDeliveryDetails[];
  public session: Session;

  public override onDeserialize() {
    super.onDeserialize();
    this.profilePicture = window.injector.Deserialize.instanceOf(Asset, this.profilePicture);
    this.codeDeliveryDetails = window.injector.Deserialize.arrayOf(CodeDeliveryDetails, this.codeDeliveryDetails);
    this.session = window.injector.Deserialize.instanceOf(Session, this.session);
  }

  public user(): AdminUser {
    return window.injector.Deserialize.instanceOf(AdminUser, this);
  }

  public profilePictureExists(): boolean {
    return this?.hasProfilePicture && !this?.profilePicture.isEmpty();
  }

}
