<div class="auth-flow-wrapper">
  <app-loading *ngIf="loadingOpts?.isLoading" [options]="loadingOpts"> </app-loading>

  <div class="auth-flow-container">
    <!--  Title  -->
    <div class="auth-flow-title">
      {{ viewModel.getAuthFlowTitle() }}
    </div>
    <!-- Form -->
    <app-form-group
      (formSubmitted)="formSubmitted($event)"
      (secondaryButtonPressed)="secondaryButtonPressed()"
      [formItems]="signInFormItems"
      [options]="formOptions"
      [formObject]="req"
      [styling]="formStyling">
    </app-form-group>
  </div>
</div>
