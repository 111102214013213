import { AccountDomainModel } from '../../../domainModels/account-domain-model';
import { Injectable } from '@angular/core';
import { HydratedAdminUser } from '../../../models/account/dto/hydrated-admin-user';
import { ChangePasswordRequest } from '../../../models/account/requests/change-password-request';
import { FormGroupStyling } from '../../../models/shared/stylesheet/form-group-styling';
import { FormInputItem, FormInputType, FormItemType } from '../../../models/shared/stylesheet/form-input-item';
import { LoadingOptions } from '../../../models/shared/loading-options';
import { PasswordValidatorDirective } from '../../shared/components/form-group/validators/password-validator.directive';
import { BsError } from '../../../models/shared/bs-error';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from '../../../services/toast-service';
import { BehaviorSubject, Subject } from 'rxjs';
import { FormOptions } from '../../../models/shared/stylesheet/form-options';
import { BaseModalViewModel } from '../../../models/base/base-modal-view-model';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ChangePasswordViewModel extends BaseModalViewModel {

  public user: HydratedAdminUser;
  public styling: FormGroupStyling = new FormGroupStyling();
  public options: FormOptions = new FormOptions();
  public changePasswordFormItems: FormInputItem[] = [];
  public req: ChangePasswordRequest = new ChangePasswordRequest('');
  protected override _loadingOpts = new BehaviorSubject(ChangePasswordViewModel.getLoadingSpinner());

  public override dismissModalSubject: Subject<boolean> = new Subject<boolean>();

  constructor(
    private domainModel: AccountDomainModel,
    private activeModal: NgbActiveModal,
    private toastService: ToastService,
    router: Router,
    ngbModal: NgbModal,
  ) {
    super(router, ngbModal);
    this.setupForm();
    this.subscribe();
  }

  private static getLoadingSpinner(): LoadingOptions {
    const opts = LoadingOptions.default();
    opts.loadingText = 'Changing Password';
    opts.isLoading = false;
    return opts;
  }
  setupForm() {
    this.setupFormItems();
    this.setupFormStyling();
    this.setupFormOpts();
  }

  setupFormItems() {
    this.changePasswordFormItems = [];

    const oldPassword = new FormInputItem();
    oldPassword.itemType = FormItemType.Input;
    oldPassword.inputName = 'oldPassword';
    oldPassword.label = 'Old Password';
    oldPassword.placeholder = 'Old Password';
    oldPassword.bindingProperty = 'oldPassword';
    oldPassword.inputType = FormInputType.Password;
    oldPassword.required = true;
    this.changePasswordFormItems.push(oldPassword);

    const newPassword = new FormInputItem();
    newPassword.itemType = FormItemType.Input;
    newPassword.inputName = 'newPassword';
    newPassword.label = 'New Password';
    newPassword.placeholder = 'New Password';
    newPassword.bindingProperty = 'newPassword';
    newPassword.inputType = FormInputType.Password;
    newPassword.required = true;
    newPassword.customValidator = new PasswordValidatorDirective();
    this.changePasswordFormItems.push(newPassword);

    const confirmNew = new FormInputItem();
    confirmNew.itemType = FormItemType.Input;
    confirmNew.inputName = 'confirmNewPassword';
    confirmNew.label = 'Confirm New Password';
    confirmNew.placeholder = 'New Password';
    confirmNew.bindingProperty = 'newPassword';
    confirmNew.mustMatchInputName = 'newPassword';
    confirmNew.inputType = FormInputType.ConfirmPassword;
    confirmNew.required = true;
    confirmNew.customValidator = new PasswordValidatorDirective();
    this.changePasswordFormItems.push(confirmNew);
  }

  setupFormStyling() {
    // Set up form styling
    this.styling.includePadding = false;
  }

  setupFormOpts() {
    this.options.clearOnSubmit = true;
  }

  subscribe() {
    const s = this.domainModel.session.sessionContainer$.notNull().subscribe((sess) => {
      if (sess.user) {
        this.user = sess.user;
      }
    });
    this.pushSub(s);
  }

  formSubmitted(req: ChangePasswordRequest) {
    const loadingMess = 'Changing Password';
    if (!this._loadingOpts.containsRequest(loadingMess)) {
      this._loadingOpts.addRequest(loadingMess);
      req.accessToken = this.domainModel.session.getAuthToken();
      this.changePassword(req, loadingMess);
    }
  }

  dismissModal() {
    this.dismissModalSubject.next(true);
  }

  changePassword(cpr: ChangePasswordRequest, loadingMess: string) {
    this.domainModel.changePassword(cpr).subscribe((_) => {
      // Reset for form object
      this.req = new ChangePasswordRequest('');
      this._loadingOpts.removeRequest(loadingMess);
      this.toastService.publishSuccessMessage('Completed', 'Password Successfully Changed.');
      this.dismissModal();
    }, (error: BsError) => {
      this._loadingOpts.removeRequest(loadingMess);
      this.toastService.publishError(error);
    });
  }

}
