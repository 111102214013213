import { FormInputItem, FormInputType, FormItemType } from '../../../../models/shared/stylesheet/form-input-item';
import { AutoCapitalize } from '../../../../models/enum/shared/input-options.enum';

export class SharedAuthForm {

  static emailInput(): FormInputItem {
    const emailInput = new FormInputItem();
    emailInput.itemType = FormItemType.Input;
    emailInput.inputName = 'email';
    emailInput.label = 'Email';
    emailInput.placeholder = 'Enter your email';
    emailInput.bindingProperty = 'email';
    emailInput.inputType = FormInputType.Email;
    emailInput.required = true;
    emailInput.autoCapitalize = AutoCapitalize.Off;
    return emailInput;
  }

  static passwordInput(): FormInputItem {
    const passwordInput = new FormInputItem();
    passwordInput.itemType = FormItemType.Input;
    passwordInput.inputName = 'password';
    passwordInput.label = 'Password';
    passwordInput.placeholder = 'Enter your password';
    passwordInput.bindingProperty = 'password';
    passwordInput.inputType = FormInputType.Password;
    passwordInput.required = true;
    passwordInput.autoCapitalize = AutoCapitalize.Off;
    // Must ensure all existing employees have pw matching requirements before enforcing on sign in
    // passwordInput.customValidator = new PasswordValidatorDirective();
    return passwordInput;
  }

}
