export enum AutoCapitalize {
  // No auto capitalization is applied (all letters default to lowercase)
  On = 'on',
  // The first letter of each sentence defaults to a capital letter; all other letters default to lowercase
  Off = 'off',
  // The first letter of each word defaults to a capital letter; all other letters default to lowercase
  Words = 'words',
  // All letters should default to uppercase
  Characters = 'characters'
}
