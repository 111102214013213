import { Component, OnDestroy, OnInit } from '@angular/core';
import { Viewable } from '../../../../../models/protocols/viewable';
import { Routable } from '../../../../../models/protocols/routable';
import { FormInputItem, FormInputType, FormItemType } from '../../../../../models/shared/stylesheet/form-input-item';
import { FormGroupStyling } from '../../../../../models/shared/stylesheet/form-group-styling';
import { LoadingOptions } from '../../../../../models/shared/loading-options';
import { AuthViewModel } from '../../../viewModels/auth-view-model';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '../../../../../services/toast-service';
import { ForgotPasswordRequest } from '../../../../../models/account/requests/forgot-password-request';
import { RouteUtils } from '../../../../../utils/route-utils';
import { BsError } from '../../../../../models/shared/bs-error';
import { BaseComponent } from '../../../../../models/base/base-component';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: [
    './forgot-password.component.scss',
    '../auth.component.scss'
  ]
})
export class ForgotPasswordComponent extends BaseComponent implements OnInit, Viewable, Routable, OnDestroy {

  // View
  public forgotPassFormItems: FormInputItem[] = [];
  public styling: FormGroupStyling = new FormGroupStyling();
  private _loadingOpts = new BehaviorSubject<LoadingOptions>(LoadingOptions.default());
  public loadingOpts$ = this._loadingOpts as Observable<LoadingOptions>;
  public isLoading$ = this.loadingOpts$.pipe(map(opts => opts?.isLoading));
  // Variables
  public req: ForgotPasswordRequest = new ForgotPasswordRequest();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private toastService: ToastService,
    public viewModel: AuthViewModel,
  ) {
    super();
  }

  override ngOnInit(): void {
    this.bindUrlParams();
    this.setupViews();
  }

  override ngOnDestroy(): void {
    this.destroy();
    this.viewModel.destroy();
  }

  bindUrlParams() {
    this.route.queryParams.subscribe((params) => {
      this.req.email = decodeURIComponent(decodeURIComponent(params.Email || params.email || ''));
    });
    this.router.navigate([RouteUtils.cleanedUrlRoute(this.router.url)], {queryParams: {}}).then(() => {
    });
  }

  override setupViews() {
    this.setupFormItems();
    this.setupFormStyling();
  }

  setupFormItems() {
    this.forgotPassFormItems = [];
    // Set up form items
    const emailInput = new FormInputItem();
    emailInput.itemType = FormItemType.Input;
    emailInput.inputName = 'email';
    emailInput.label = 'Email';
    emailInput.placeholder = 'Enter your email';
    emailInput.bindingProperty = 'email';
    emailInput.inputType = FormInputType.Email;
    emailInput.required = true;
    this.forgotPassFormItems.push(emailInput);
  }

  setupFormStyling() {
    // Set up form styling
    this.styling.primaryButtonFloat = 'left';
    this.styling.submitButtonText = 'Send Reset Code';
    this.styling.secondaryButtonFloat = 'right';
    this.styling.secondaryButtonText = 'Return to Sign In';
    this.styling.secondaryButtonClass = 'text-button';
  }

  formSubmitted(req: ForgotPasswordRequest) {
    const loadingMess = 'Sending forgot password email.';
    if (!this._loadingOpts.containsRequest(loadingMess)) {
      this._loadingOpts.addRequest(loadingMess);
      this.viewModel.forgotPassword(req).subscribe((_) => {
        this._loadingOpts.removeRequest(loadingMess);
      }, (error: BsError) => {
        this._loadingOpts.removeRequest(loadingMess);
        this.toastService.publishError(error);
      });
    }
  }

  secondaryButtonPressed() {
    const tempEmail = FormInputItem.getItemValue(this.forgotPassFormItems, 'email');
    this.router.navigate(['/auth/sign-in'], {
      queryParams: {
        Email: tempEmail
      }
    }).then(() => {
    });
  }

}
