import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroupComponent } from '../../../../shared/components/form-group/form-group.component';
import { ComponentCanDeactivate } from '../../../../../models/protocols/component-can-deactivate';
import { ChangePasswordViewModel } from '../../../viewModels/change-password-view-model';
import { Viewable } from '../../../../../models/protocols/viewable';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseModalComponent } from '../../../../../models/base/base-modal.component';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent extends BaseModalComponent
  implements OnInit, OnDestroy, Viewable, ComponentCanDeactivate {

  @ViewChild('passwordForm') passwordForm: FormGroupComponent;

  constructor(
    public viewModel: ChangePasswordViewModel,
    private activeModal: NgbActiveModal,
  ) {
    super(activeModal);
  }

  override ngOnInit(): void {
    this.setupBindings();
  }

  override setupBindings() {
    const s = this.viewModel.dismissModalSubject.subscribe((close) => {
      if (close) { this.cancel(); }
    });
    this.pushSub(s);
  }

  override cancel() {
    this.passwordForm.resetForm();
    this.activeModal.close(null);
  }

  submitTheForm() {
    this.passwordForm.submitForm();
  }

  canDeactivate(): boolean | Promise<any> {
    return !this.passwordForm.shouldDisplayAbandonDialog();
  }

  override ngOnDestroy(): void {
    this.destroy();
    this.viewModel.destroy();
  }

}
