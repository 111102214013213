import { TypeDefinition } from './type-definition';

/** The order of these keys determines the sort order of the columns */
export enum SectionColumnConfigKey {
  Asset = 'Asset',
  Badges = 'Badges',
  Brand = 'Brand',
  StrainType = 'ClassificationType', // Underlying value must remain ClassificationType for backwards compatibility
  Quantity = 'Quantity',
  Size = 'Size',
  QuantityAndSize = 'QuantityAndSize',
  Stock = 'Stock',
  THC = 'THC',
  CBD = 'CBD',
  CBDA = 'CBDA',
  CBG = 'CBG',
  CBGA = 'CBGA',
  CBL = 'CBL',
  CBLA = 'CBLA',
  CBN = 'CBN',
  CBNA = 'CBNA',
  CBT = 'CBT',
  CBC = 'CBC',
  CBCA = 'CBCA',
  CBCV = 'CBCV',
  CBDV = 'CBDV',
  THC8 = 'THC8',
  THC9 = 'THC9',
  THCA = 'THCA',
  THCV = 'THCV',
  TAC = 'TAC',
  TopTerpene = 'TopTerpene',
  TotalTerpene = 'TotalTerpene',
  AlphaBisabolol = 'AlphaBisabolol',
  AlphaPinene = 'AlphaPinene',
  BetaCaryophyllene = 'BetaCaryophyllene',
  BetaEudesmol = 'BetaEudesmol',
  BetaMyrcene = 'BetaMyrcene',
  BetaPinene = 'BetaPinene',
  Bisabolol = 'Bisabolol',
  Borneol = 'Borneol',
  Camphene = 'Camphene',
  CaryophylleneOxide = 'CaryophylleneOxide',
  Delta3Carene = 'Delta3Carene',
  Eucalyptol = 'Eucalyptol',
  Geraniol = 'Geraniol',
  Guaiol = 'Guaiol',
  Humulene = 'Humulene',
  Linalool = 'Linalool',
  Limonene = 'Limonene',
  Ocimene = 'Ocimene',
  ThreeCarene = 'ThreeCarene',
  Terpinene = 'Terpinene',
  Terpinolene = 'Terpinolene',
  TransNerolidol = 'TransNerolidol',
  Valencene = 'Valencene',
  Price = 'Price',
  SecondaryPrice = 'SecondaryPrice',
}

export class SectionColumnConfigKeyType extends TypeDefinition {

  override value: SectionColumnConfigKey;

  public isStockKey(): boolean {
    return this.value === SectionColumnConfigKey.Stock;
  }

  public isCannabinoidKey(): boolean {
    return this.isPrimaryCannabinoidKey() || this.isSecondaryCannabinoidKey();
  }

  public isPrimaryCannabinoidKey(): boolean {
    return this.value === SectionColumnConfigKey.THC || this.value === SectionColumnConfigKey.CBD;
  }

  public isSecondaryCannabinoidKey(): boolean {
    const secondaryCannabinoidKeys = [
      SectionColumnConfigKey.CBDA,
      SectionColumnConfigKey.CBG,
      SectionColumnConfigKey.CBGA,
      SectionColumnConfigKey.CBL,
      SectionColumnConfigKey.CBLA,
      SectionColumnConfigKey.CBN,
      SectionColumnConfigKey.CBNA,
      SectionColumnConfigKey.CBT,
      SectionColumnConfigKey.CBC,
      SectionColumnConfigKey.CBCA,
      SectionColumnConfigKey.CBCV,
      SectionColumnConfigKey.CBDV,
      SectionColumnConfigKey.THC8,
      SectionColumnConfigKey.THC9,
      SectionColumnConfigKey.THCA,
      SectionColumnConfigKey.THCV,
      SectionColumnConfigKey.TAC,
    ];
    return secondaryCannabinoidKeys.contains(this.value);
  }

  public isTerpeneKey(): boolean {
    return this.isCollectiveTerpeneKey() || this.isIndividualTerpeneKey();
  }

  public isCollectiveTerpeneKey(): boolean {
    return this.value === SectionColumnConfigKey.TopTerpene || this.value === SectionColumnConfigKey.TotalTerpene;
  }

  public isIndividualTerpeneKey(): boolean {
    const terpeneKeys = [
      SectionColumnConfigKey.AlphaBisabolol,
      SectionColumnConfigKey.AlphaPinene,
      SectionColumnConfigKey.BetaCaryophyllene,
      SectionColumnConfigKey.BetaEudesmol,
      SectionColumnConfigKey.BetaMyrcene,
      SectionColumnConfigKey.BetaPinene,
      SectionColumnConfigKey.Bisabolol,
      SectionColumnConfigKey.Borneol,
      SectionColumnConfigKey.Camphene,
      SectionColumnConfigKey.CaryophylleneOxide,
      SectionColumnConfigKey.Delta3Carene,
      SectionColumnConfigKey.Eucalyptol,
      SectionColumnConfigKey.Geraniol,
      SectionColumnConfigKey.Guaiol,
      SectionColumnConfigKey.Humulene,
      SectionColumnConfigKey.Linalool,
      SectionColumnConfigKey.Limonene,
      SectionColumnConfigKey.Ocimene,
      SectionColumnConfigKey.ThreeCarene,
      SectionColumnConfigKey.Terpinene,
      SectionColumnConfigKey.Terpinolene,
      SectionColumnConfigKey.TransNerolidol,
      SectionColumnConfigKey.Valencene,
    ];
    return terpeneKeys.contains(this.value);
  }

}
