import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { AccountViewModel } from '../../viewModels/account-view-model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalUtils } from '../../../../utils/modal-utils';
import { ChangePasswordComponent } from '../modals/change-password/change-password.component';
import { FormGroupComponent } from '../../../shared/components/form-group/form-group.component';
import { BaseComponent } from '../../../../models/base/base-component';
import { UpdateUserInfoRequest } from '../../../../models/account/requests/update-user-info-request';
import { ConfirmCodeRequest } from '../../../../models/account/requests/confirm-code-request';
import { ComponentCanDeactivate } from '../../../../models/protocols/component-can-deactivate';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent extends BaseComponent implements AfterViewInit, ComponentCanDeactivate {

  // View References
  @ViewChild('accountData') accountInfoForm: FormGroupComponent;
  @ViewChild('emailConfirmation') emailConfirmationForm: FormGroupComponent;

  constructor(
    public viewModel: AccountViewModel,
    private modalService: NgbModal,
  ) {
    super();
  }

  override ngAfterViewInit() {
    super.ngAfterViewInit(); // This will call setupBindings()
  }

  accountInfoFormSubmitted(hydratedFormObject: UpdateUserInfoRequest): void {
    this.viewModel?.accountInformationSubmitted(hydratedFormObject);
  }

  emailConfirmationSubmit(hydratedFormObject: ConfirmCodeRequest): void {
    this.viewModel?.emailConfirmationSubmitted(hydratedFormObject);
  }

  openChangePasswordModal(): void {
    const modalRef = this.modalService.open(
      ChangePasswordComponent,
      ModalUtils.defaultModalOptions()
    );
    modalRef.result.then((_) => {
    });
  }

  resendCode(): void {
    this.viewModel.resendCode();
  }

  canDeactivate(): boolean | Promise<any> {
    return this.viewModel.canDeactivate();
  }

}
