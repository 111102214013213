import { Component } from '@angular/core';
import { SessionService } from '../../../services/session-service';
import { Router } from '@angular/router';
import { BaseComponent } from '../../../models/base/base-component';
import { LoadingOptions } from '../../../models/shared/loading-options';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-default-layout',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss']
})
export class DefaultLayoutComponent extends BaseComponent {

  private _loadingOpts = new BehaviorSubject(DefaultLayoutComponent.getLoadingOpts());
  public loadingOpts$ = this._loadingOpts as Observable<LoadingOptions>;
  public isLoading$ = this.loadingOpts$.pipe(map(opts => opts?.isLoading));
  public sidebarMinimized = false;

  constructor(
    private sessionService: SessionService,
    public router: Router
  ) {
    super();
  }

  override setupBindings() {
    this.sessionService.refreshingSession.notNull().subscribe((ref) => {
      const lm = 'Refreshing Session';
      if (!this._loadingOpts.containsRequest(lm) && ref) {
        this._loadingOpts.addRequest(lm);
      } else if (this._loadingOpts.containsRequest(lm) && !ref) {
        this._loadingOpts.removeRequest(lm);
      }
    });
    this.sessionService.sessionContainer$.subscribe((sess) => {
      if (!sess) {
        this.sidebarMinimized = true;
      } else {
        this.sidebarMinimized = !sess.validSession();
      }
    });
  }

  private static getLoadingOpts(): LoadingOptions {
    const opts = LoadingOptions.default(false, true);
    opts.zIndex = 100;
    return opts;
  }

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

}
