import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { AccountComponent } from './components/account/account.component';
import { AccountRoutingModule } from './account-routing.module';
import { AuthComponent } from './components/auth/auth.component';
import { SignInComponent } from './components/auth/sign-in/sign-in.component';
import { ForgotPasswordComponent } from './components/auth/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/auth/reset-password/reset-password.component';
import { NewPasswordComponent } from './components/auth/new-password/new-password.component';
import { ChangePasswordComponent } from './components/modals/change-password/change-password.component';
import { AccountViewModel } from './viewModels/account-view-model';
import { ChangePasswordViewModel } from './viewModels/change-password-view-model';
import { AuthViewModel } from './viewModels/auth-view-model';
import { ReactiveFormModule } from '@mobilefirstdev/reactive-form';
import { SignOutComponent } from './components/auth/sign-out/sign-out.component';

@NgModule({
  declarations: [
    AccountComponent,
    AuthComponent,
    SignInComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    NewPasswordComponent,
    ChangePasswordComponent,
    SignOutComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    AccountRoutingModule,
    ReactiveFormModule
  ],
  providers: [
    AuthViewModel,
    AccountViewModel,
    ChangePasswordViewModel,
  ]
})
export class AccountModule {
}
