<div class="modal-body">
  <app-loading *ngIf="viewModel.isLoading$ | async" [options]="viewModel?.loadingOpts$ | async"> </app-loading>
  <div class="bs-section-title mt-16px">Change Password</div>
  <div class="change-password-subheader">{{ viewModel?.user?.getFullName() }}</div>
  <hr class="mt-28px mb-24px" />
  <!-- Form -->
  <app-form-group
    #passwordForm
    (formSubmitted)="viewModel?.formSubmitted($event)"
    [options]="viewModel?.options"
    [formItems]="viewModel?.changePasswordFormItems"
    [formObject]="viewModel?.req"
    [styling]="viewModel?.styling">
  </app-form-group>
  <hr class="mb-32px" />
  <div class="modal-footer-flex-end">
    <button (click)="cancel()" class="bs-button neutral-button">Cancel</button>
    <button
      (click)="submitTheForm()"
      [disabled]="!this.passwordForm?.canSubmitForm()"
      [ngClass]="{ disabled: !this.passwordForm?.canSubmitForm() }"
      class="bs-button preferred-button">
      Change Password
    </button>
  </div>
</div>
