import { Injectable } from '@angular/core';

import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseModalViewModel } from '../../../../models/base/base-modal-view-model';
import { HydratedVariantBadge } from '../../../../models/product/dto/hydrated-variant-badge';
import { Asset } from '../../../../models/image/dto/asset';
import { LoadingOptions } from '../../../../models/shared/loading-options';
import { BadgeReq } from '../../../../models/company/shared/badge-req';
import { BudsenseFile } from '../../../../models/shared/budsense-file';
import { SafeResourceUrl } from '@angular/platform-browser';
import { ProductDomainModel } from '../../../../domainModels/product-domain-model';
import { ToastService } from '../../../../services/toast-service';
import { SessionService } from '../../../../services/session-service';
import { VariantBadge } from '../../../../models/product/dto/variant-badge';
import { BsError } from '../../../../models/shared/bs-error';
import { AssetSize } from '../../../../models/enum/dto/asset-size.enum';
import { SelectableBadgeSection } from '../../../../models/product/shared/selectable-badge-section';
import { ConfirmationOptions } from '../../../../models/shared/stylesheet/confirmation-options';
import { ModalService } from '../../../../services/modal.service';

@Injectable()
export class EditBadgeViewModel extends BaseModalViewModel {

  protected override _loadingOpts = new BehaviorSubject(EditBadgeViewModel.getLoadingSpinner());
  public addingNewBadge: boolean = false;
  public badge: HydratedVariantBadge;
  private asset = new BehaviorSubject<Asset>(null);
  public badgeAsset$ = this.asset.asObservable();
  public originalBadgeName: string;
  public badgeReq: BadgeReq = new BadgeReq();
  public imgToUpload: BudsenseFile;
  public imgToUpload$ = new BehaviorSubject<BudsenseFile>(null);
  public replaceImg: boolean = false;
  public displayImg: ReplaySubject<string | SafeResourceUrl> = new ReplaySubject<string | SafeResourceUrl>(1);

  public selectableBadgeSections$ = new BehaviorSubject<SelectableBadgeSection[]>([]);
  private selectableBadgeSubSectionMap = new Map<string, SelectableBadgeSection[]>();
  public selectableBadgeSubSections$ = new BehaviorSubject<SelectableBadgeSection[]>([]);
  public disableSubSection$ = new BehaviorSubject<boolean>(true);

  constructor(
    private productDomainModel: ProductDomainModel,
    private toastService: ToastService,
    private session: SessionService,
    private modalService: ModalService,
    router: Router,
    ngbModal: NgbModal,
  ) {
    super(router, ngbModal);
    this.setupForm();
  }

  setBadge(b: HydratedVariantBadge) {
    this.badge = b;
    this.asset.next(b?.image);
    if (!b?.image) {
      this.replaceImg = true;
    }
    this.originalBadgeName = this.badge.name;
    const req = new BadgeReq();
    req.badgeName = b.name;
    req.sectionTitle = b.category ? b.category.charAt(0).toUpperCase() + b.category.slice(1) : '';
    req.subSectionTitle = b.subCategory ? b.subCategory.charAt(0).toUpperCase() + b.subCategory.slice(1) : '';
    this.badgeReq = req;
    this.selectableBadgeSubSections$.next(this.selectableBadgeSubSectionMap.get(b.category));
    if (this.badgeReq.sectionTitle !== '') {
      this.disableSubSection$.next(false);
    }
    this.subToBadgeImg(b);
  }

  private static getLoadingSpinner(): LoadingOptions {
    const opts = LoadingOptions.default();
    opts.loadingText = '';
    opts.isLoading = false;
    opts.fullscreen = false;
    return opts;
  }

  setupForm() {
    this.setupBadgeSections();
  }

  private setupBadgeSections() {
    this.selectableBadgeSections$.next(this.generateSectionDropdownOptions());
    this.generateSubSectionDropDownOptions();
  }

  generateSectionDropdownOptions(): SelectableBadgeSection[] {
    const array = [];
    this.productDomainModel.curatedBadgeSections$.pipe(map(sections => {
        sections.forEach(section => {
          const sec = new SelectableBadgeSection();
          sec.name = section.title.charAt(0).toUpperCase() + section.title.slice(1);
          array.push(sec);
        });
      })).subscribe();
    return array.sort((a, b) => a.name.localeCompare(b.name));
  }

  generateSubSectionDropDownOptions() {
    this.productDomainModel.curatedBadgeSections$.pipe(map(sections => {
      sections.forEach(section => {
        section.subSectionBadges.forEach((badges, subCategory) => {
          this.addToSubSectionMenu(subCategory, section.title);
        });
      });
    })).subscribe();
  }

  addNewSection() {
    this.modalService.openAddNewSectionModal(
      (newSectionName) => {
        if (newSectionName) {
          const newSection = new SelectableBadgeSection();
          newSection.name = newSectionName.charAt(0).toUpperCase() + newSectionName.slice(1);
          const newBadgeSections = this.selectableBadgeSections$.getValue();
          newBadgeSections.push(newSection);
          this.selectableBadgeSections$.next(newBadgeSections);
        }
      }
    );
  }

  addNewSubSection(sectionName: any) {
    this.modalService.openEditSectionModal(
      sectionName,
      this.selectableBadgeSections$.getValue(),
      (newSubSectionName) => {
        if (newSubSectionName) {
          this.addToSubSectionMenu(newSubSectionName, sectionName);
          this.selectableBadgeSubSections$.next(this.getSubSectionsForSection(sectionName));
        }
      }
    );
  }

  private addToSubSectionMenu(newSubSectionName, sectionName: string) {
    if (newSubSectionName && sectionName) {
      const key = sectionName.toLowerCase();
      const newSubSection = new SelectableBadgeSection();
      newSubSection.name = newSubSectionName.charAt(0).toUpperCase() + newSubSectionName.slice(1);
      const selectableBadgeSubSection = this.selectableBadgeSubSectionMap.get(key);
      if (!!selectableBadgeSubSection) {
        selectableBadgeSubSection.push(newSubSection);
        this.selectableBadgeSubSectionMap.set(key, selectableBadgeSubSection.sort(
          (a, b) => a.name.localeCompare(b.name)
        ));
      } else {
        this.selectableBadgeSubSectionMap.set(key, [newSubSection]);
      }
    }
  }

  listenToSection(sectionName: any) {
    if (sectionName === null || sectionName === undefined || sectionName === '') {
      this.disableSubSection$.next(true);
      this.selectableBadgeSubSections$.next([]);
    } else {
      this.disableSubSection$.next(false);
      this.selectableBadgeSubSections$.next(this.getSubSectionsForSection(sectionName));
    }
  }

  getSubSectionsForSection(sectionName: string): SelectableBadgeSection[] {
    const key = sectionName.toLowerCase();
    return this.selectableBadgeSubSectionMap.get(key);
  }

  updateBadge(form: BadgeReq) {
    const newBadge = new VariantBadge();
    newBadge.companyId = this.session.getAdminCompanyId();
    newBadge.name = form.badgeName;
    newBadge.id = this.badge.id;
    newBadge.category = form.sectionTitle.toLowerCase();
    newBadge.subCategory = form.subSectionTitle.toLowerCase();
    const lm = this.addingNewBadge ? 'Adding New Badge' : 'Updating Badge';
    const saveObs$ = this.addingNewBadge ?
      this.productDomainModel.uploadAdminBadge(newBadge, this.imgToUpload) :
      this.productDomainModel.updateCuratedBadges([newBadge], this.imgToUpload);
    if (!this._loadingOpts.containsRequest(lm)) {
      this._loadingOpts.addRequest(lm);
      saveObs$.subscribe((_) => {
        this._loadingOpts.removeRequest(lm);
        this.dismissModalSubject.next(true);
        if (this.addingNewBadge) {
          this.toastService.publishSuccessMessage('Badge successfully uploaded.', 'Add New Badge');
        } else {
          this.toastService.publishSuccessMessage('Badge successfully updated.', 'Edit Badge');
        }
      }, (error: BsError) => {
        this._loadingOpts.removeRequest(lm);
        this.toastService.publishError(error);
      });
    }
  }

  deleteBadge() {
    const opts = new ConfirmationOptions();
    opts.title = `Delete Badge?`;
    opts.bodyText = 'Are you sure you want to delete this badge?. Customers will no longer be able to view it on ' +
      'BudSense. This action cannot be undone, and can potentially screw up lots of things for us.';
    opts.cancelText = 'Back';
    opts.continueText = 'Delete Badge';
    const lm = 'Deleting Badge';
    this.modalService.openGenericConfirmationModal(
      opts,
      (cont) => {
        if (cont) {
          this._loadingOpts.addRequest(lm);
          this.productDomainModel.deleteCuratedBadges([this.badge.id]).subscribe(_ => {
            this._loadingOpts.removeRequest(lm);
            this.dismissModalSubject.next(true);
            this.toastService.publishSuccessMessage('Badge successfully removed.', 'Delete Badge');
          }, (error: BsError) => {
            this._loadingOpts.removeRequest(lm);
            this.toastService.publishError(error);
          });
        }
      }
    );
  }

  private subToBadgeImg(badge: HydratedVariantBadge) {
    const imgKey = 'Badge_Image';
    this.destroyImageSub(imgKey);
    const badgeUrl = badge?.image?.getAssetUrl(AssetSize.Medium)?.srcUrl;
    if (badgeUrl) {
      const s = this.displayImg.bind(
        badgeUrl.pipe(map((url) => (!url ? 'assets/placeholder/list-image-dark.jpg' : url)))
      );
      this.pushImageSub(imgKey, s);
    }
  }

  toggleReplaceImg() {
    this.replaceImg = !this.replaceImg;
  }

}
