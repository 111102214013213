import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { SessionService } from '../session-service';
import { catchError } from 'rxjs/operators';
import { AccountAPI } from '../../api/account-api';
import { AccountDomainModel } from '../../domainModels/account-domain-model';
import { Session } from '../../models/account/dto/session';
import { environment } from '../../../environments/environment';

@Injectable()
export class AuthInterceptorInterceptor implements HttpInterceptor {

  private refreshObservable: ReplaySubject<Session>;
  private ignoredRequests = [
    '/sign-in',
    '/sign-in-new-password',
    '/forgot-password-code',
    '/resend-code',
    '/reset-forgotten-password'
  ];

  constructor(
    private router: Router,
    private session: SessionService,
    private accountAPI: AccountAPI,
    private accountDomainModel: AccountDomainModel
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.ignoredRequests.map(r => request.url.includes(r)).filter(v => v).length > 0) {
      request = this.addAuthenticationToken(request);
      return next.handle(request);
    } else {
      request = this.addAuthenticationToken(request);
      return next.handle(request).pipe(
        catchError(err => {
          if (err?.error?.code === 403) {
            const req = this.session.getRefreshSessionReq();
            if (req) {
              // Stall all incoming requests that wont have a valid token
              if (!this.refreshObservable) {
                this.refreshObservable = new ReplaySubject<Session>(1);
                this.refreshObservable.bind(this.accountDomainModel.isAuthenticated(true));
              }

              // Refresh Session
              return new Observable<HttpEvent<any>>(subscriber => {
                this.refreshObservable.subscribe((sess) => {
                  if (!sess) {
                    this.router.navigate(['/auth/sign-in']);
                  }
                  this.refreshObservable = null;
                  // eslint-disable-next-line rxjs/no-nested-subscribe
                  next.handle(this.addAuthenticationToken(request, sess?.accessToken)).subscribe(response => {
                    subscriber.next(response);
                  }, e => {
                    subscriber.error(e);
                    this.router.navigate(['/auth/sign-in']);
                  });
                }, error => {
                  this.refreshObservable = null;
                  this.session.destroySession.next(true);
                  subscriber.error(error);
                });
              });
            } else {
              // Kill session and navigate to logout
              this.session.destroySession.next(true);
            }
          }
          return throwError(err);
        })
      );
    }
  }

  addAuthenticationToken(request, newToken?: string) {
    if (newToken) {
      return request.clone({
        headers: this.createHeaders(newToken, request.headers)
      });
    } else {
      const token = this.session.getAuthToken();
      return request.clone({
        headers: this.createHeaders(token, request.headers)
      });
    }
  }

  private createHeaders(token: string, headers: HttpHeaders): HttpHeaders {
    if (!headers.get('Content-Type')) {
      headers = headers.append('Content-Type', 'application/json');
    }
    if (!headers.get('Accept')) {
      headers = headers.append('Accept', 'application/json');
    }
    if (!headers.get('UserId') && this.session.getUserId()) {
      headers = headers.append('UserId', this.session.getUserId());
    }
    if (!headers.get('ClientSecret')) {
      headers = headers.append('ClientSecret', environment.cognitoClientSecret);
    }
    if (!headers.get('AdminClientSecret')) {
      headers = headers.append('AdminClientSecret', environment.cognitoAdminClientSecret);
    }
    if (!headers.get('AdminToken') && token) {
      headers = headers.append('AdminToken', token);
    }
    return headers;
  }

}
