import { TypeDefinition } from './type-definition';

export enum SectionColumnConfigDataValue {
  // Asset Column - Image Fit Information
  Contain = 'Contain',
  Cover = 'Cover',
  // Secondary Price Column - Price Information
  SecondaryPrice = 'SecondaryPrice',
  PricePerUOM = 'PricePerUOM',
  OriginalPrice = 'OriginalPrice',
  SaleOriginalPrice = 'SaleOriginalPrice',
  OriginalAndSalePrice = 'OriginalAndSalePrice',
  TaxesInPrice = 'TaxesInPrice',
  TaxesInRoundedPrice = 'TaxesInRoundedPrice',
  PreTaxPrice = 'PreTaxPrice',
  //Not Set value
  NotSet = ''
}

export class SectionColumnConfigDataValueType extends TypeDefinition {

  override value: SectionColumnConfigDataValue;

  public getSelectionInformationDescription(): string {
    return this.description;
  }

}
