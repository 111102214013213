import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthFlow } from '../../../../models/account/enum/auth-flow.enum';
import { AuthViewModel } from '../../viewModels/auth-view-model';
import { Viewable } from '../../../../models/protocols/viewable';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '../../../../services/toast-service';
import { BaseComponent } from '../../../../models/base/base-component';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent extends BaseComponent implements OnInit, Viewable, OnDestroy {

  // View Properties
  public authFlows = AuthFlow;
  private logoClickCount: number = 0;

  constructor(
    public viewModel: AuthViewModel,
    private route: ActivatedRoute,
    private router: Router,
    private toastService: ToastService,
  ) {
    super();
  }

  override ngOnInit(): void {
    this.parseAuthFlow();
    this.setupBindings();
  }

  override ngOnDestroy(): void {
    this.destroy();
    this.viewModel.destroy();
  }

  parseAuthFlow() {
    this.route.url.subscribe((url) => {
      switch (url[url.length - 1].path) {
        case 'sign-in': {
          this.viewModel.authFlow = AuthFlow.SignIn;
          break;
        }
        case 'forgot-password': {
          this.viewModel.authFlow = AuthFlow.ForgotPassword;
          break;
        }
        case 'reset-password': {
          this.viewModel.authFlow = AuthFlow.ResetPassword;
          break;
        }
        case 'new-password': {
          this.viewModel.authFlow = AuthFlow.SetNewPassword;
          break;
        }
      }
    });
  }

  override setupBindings() {
    // Navigate to the account page on successful auth
    const authSuccessSubject = this.viewModel.authSuccess.notNull().subscribe((_) => {
      this.router.navigate(['metrics']).then(() => {
      });
    });
    this.pushSub(authSuccessSubject);
    // Navigate to the reset password page
    const authFlowSubject = this.viewModel.nextAuthFlow.notNull().subscribe((nextAuthFlow) => {
      switch (nextAuthFlow) {
        case AuthFlow.ResetPassword:
          this.router.navigate(['auth/reset-password']).then(() => {
          });
          break;
        case AuthFlow.SetNewPassword:
          this.router.navigate(['auth/new-password']).then(() => {
          });
          break;
      }
    });
    this.pushSub(authFlowSubject);
  }

  logoClicked() {
    // If the user clicks the logo 7 times quickly, it will reset the caches
    this.logoClickCount++;
    if (this.logoClickCount > 6) {
      this.viewModel.clearCaches();
      this.logoClickCount = 0;
      this.toastService.publishInfoMessage('All caches have been cleared.', 'Caches cleared');
    }
    setTimeout(() => {
      this.logoClickCount = 0;
    }, 5000);
  }

}
