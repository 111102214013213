import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormInputItem, FormInputType, FormItemType } from '../../../../../models/shared/stylesheet/form-input-item';
import { FormGroupStyling } from '../../../../../models/shared/stylesheet/form-group-styling';
import { LoadingOptions } from '../../../../../models/shared/loading-options';
import { AuthViewModel } from '../../../viewModels/auth-view-model';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '../../../../../services/toast-service';
import { PasswordValidatorDirective } from '../../../../shared/components/form-group/validators/password-validator.directive';
import { BsError } from '../../../../../models/shared/bs-error';
import { Viewable } from '../../../../../models/protocols/viewable';
import { SignInNewPasswordRequest } from '../../../../../models/account/requests/sign-in-new-password-request';
import { BaseComponent } from '../../../../../models/base/base-component';
import { debounceTime, map, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: [
    './new-password.component.scss',
    '../auth.component.scss'
  ]
})
export class NewPasswordComponent extends BaseComponent implements OnInit, Viewable, OnDestroy {

  // View
  public newPassFormItems: FormInputItem[] = [];
  public styling: FormGroupStyling = new FormGroupStyling();
  private _loadingOpts = new BehaviorSubject<LoadingOptions>(LoadingOptions.default());
  public loadingOpts$ = this._loadingOpts as Observable<LoadingOptions>;
  public isLoading$ = this.loadingOpts$.pipe(map(opts => opts?.isLoading));
  // Variables
  public req: SignInNewPasswordRequest = new SignInNewPasswordRequest();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private toastService: ToastService,
    public viewModel: AuthViewModel
  ) {
    super();
  }

  override setupBindings() {
    this.viewModel.user$.pipe(takeUntil(this.onDestroy)).pipe(debounceTime(1)).subscribe(user => {
      const userId = user?.userId;
      const sessionToken = user?.session?.challenge?.authSession;
      const validSession = user?.session?.validSession();
      if ((!userId || !sessionToken) && !validSession) {
        this.router.navigate(['/auth/sign-in']).then();
      }
    });
  }

  override setupViews() {
    this.setupFormItems();
    this.setupFormStyling();
  }

  setupFormItems() {
    this.newPassFormItems = [];
    // Set up form items

    const passwordInput = new FormInputItem();
    passwordInput.itemType = FormItemType.Input;
    passwordInput.inputName = 'password';
    passwordInput.label = 'New Password';
    passwordInput.placeholder = 'Enter a new password.';
    passwordInput.bindingProperty = 'password';
    passwordInput.inputType = FormInputType.Password;
    passwordInput.required = true;
    passwordInput.customValidator = new PasswordValidatorDirective();
    this.newPassFormItems.push(passwordInput);

    const confirmPasswordInput = new FormInputItem();
    confirmPasswordInput.itemType = FormItemType.Input;
    confirmPasswordInput.inputName = 'confirmPassword';
    confirmPasswordInput.label = 'Confirm Password';
    confirmPasswordInput.placeholder = 'Retype your new password.';
    confirmPasswordInput.bindingProperty = 'confirmPassword';
    confirmPasswordInput.inputType = FormInputType.Password;
    confirmPasswordInput.required = true;
    confirmPasswordInput.mustMatchInputName = 'password';
    confirmPasswordInput.customValidator = new PasswordValidatorDirective();
    this.newPassFormItems.push(confirmPasswordInput);
  }

  setupFormStyling() {
    // Set up form styling
    this.styling.primaryButtonFloat = 'left';
    this.styling.submitButtonText = 'Update Password';
  }

  formSubmitted(req: SignInNewPasswordRequest) {
    const loadingMess = 'Updating password.';
    if (!this._loadingOpts.containsRequest(loadingMess)) {
      this._loadingOpts.addRequest(loadingMess);
      this.viewModel.signInNewPassword(req).subscribe((_) => {
        this._loadingOpts.removeRequest(loadingMess);
      }, (error: BsError) => {
        this._loadingOpts.removeRequest(loadingMess);
        this.toastService.publishError(error);
      });
    }
  }

}
