import { Deserializable } from '../protocols/deserializable';

export class CachedBlob implements Deserializable {

  cacheTime: number;
  blob: string;

  constructor(cacheTime?: number, blob?: string) {
    this.cacheTime = cacheTime;
    this.blob = blob;
  }

  onDeserialize() {
  }

}
