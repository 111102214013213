import { inject, Injectable } from '@angular/core';
import { CacheService } from './cache-service';
import { ImageAPI } from '../api/image-api';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscribable } from '../models/base/subscribable';
import { InjectedDeserializer } from '../models/protocols/deserialize';

declare global {
  interface Window { injector: InjectorService|undefined }
}

@Injectable({ providedIn: 'root' })
export class InjectorService extends Subscribable {

  public cache: CacheService;
  public imageApi: ImageAPI;

  /**
   * The constructor uses inject() on purpose. Why?
   * All parameters injected into the constructor as parameters initialize before window.injector = this.
   * We can't have this, because the cache service relies on window.injector being set.
   * Therefore, wait until window.injector is set before initializing services.
   *
   * TL;DR: Don't inject services as params. Use inject() in constructor body instead.
   */
  constructor(
    public sanitizer: DomSanitizer
  ) {
    super();
    window.injector = this;
    this.cache = inject(CacheService);
    this.imageApi = inject(ImageAPI);
  }

  /**
   * This lives in here as to not create circular import dependencies. If you: import {Deserialize} from '...'
   * then there is a high likelihood that you will create a "circular import dependency", because the Deserialize
   * class imports references to most of the classes in this project.
   *
   * TL;DR: Don't import Deserialize into classes. Use the injector instead.
   */
  public Deserialize: typeof InjectedDeserializer = InjectedDeserializer;

  override destroy() {
    super.destroy();
    window.injector = undefined;
  }

}
