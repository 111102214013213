import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { FormInputItem, FormInputType, FormItemType } from '../../../../models/shared/stylesheet/form-input-item';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { FormGroupStyling } from '../../../../models/shared/stylesheet/form-group-styling';
import * as moment from 'moment';
import { SearchForValidatorDirective } from './validators/search-for-validator.directive';
import { PhoneValidatorDirective } from './validators/phone-validator.directive';
import { FormOptions } from 'src/app/models/shared/stylesheet/form-options';
import { BaseComponent } from '../../../../models/base/base-component';
import { ObjectHasSelectableWrapper } from '../../../../models/protocols/object-has-selectable-wrapper';
import { Checkbox } from '../../../../models/shared/stylesheet/checkbox';
import { PlacementArray } from '@ng-bootstrap/ng-bootstrap/util/positioning';
import { InformationModalComponent } from '../../modals/information-modal/information-modal.component';
import { ModalUtils } from '../../../../utils/modal-utils';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroupViewModel } from './form-group-view-model';
import { PopperUtils } from '../../../../utils/popper-utils';

@Component({
  selector: 'app-form-group',
  templateUrl: './form-group.component.html',
  styleUrls: ['./form-group.component.scss'],
  providers: [FormGroupViewModel],
  encapsulation: ViewEncapsulation.None
})
export class FormGroupComponent extends BaseComponent implements AfterViewInit, OnChanges, OnDestroy {

  // Inputs
  @Input() formItems: FormInputItem[] = [];
  @Input() formObject: any;
  @Input() styling: FormGroupStyling = new FormGroupStyling();
  @Input() options: FormOptions = new FormOptions();
  @Input() validateForm: EventEmitter<any> = new EventEmitter();
  @Input() hydrateInputObject: EventEmitter<any> = new EventEmitter();

  // Outputs
  @Output() formSubmitted: EventEmitter<any> = new EventEmitter();
  @Output() formCancelled: EventEmitter<any> = new EventEmitter();
  @Output() secondaryButtonPressed: EventEmitter<any> = new EventEmitter();
  @Output() formChanges: EventEmitter<any> = new EventEmitter();
  @Output() hydrateInputOutput: EventEmitter<any> = new EventEmitter<any>();

  // Variables
  public form: UntypedFormGroup;
  public submitted: boolean = false;
  public itemTypes = FormItemType;
  public inputTypes = FormInputType;
  public checkboxValue: boolean = false;
  public settingUpForm: boolean = false;
  public initialValuesToEmit: [FormInputItem, any][] = [];
  public holdFormChanges: boolean = false;

  // Popper
  public popperModifier = [PopperUtils.flipModifier()];
  public popperStyles = {
    'background-color': '#FFFFFF',
    'z-index': 99
  };

  constructor(
    public viewModel: FormGroupViewModel,
    private formBuilder: UntypedFormBuilder,
    private modalService: NgbModal,
  ) {
    super();
  }

  override ngAfterViewInit() {
    super.ngAfterViewInit();
    this.setFormObjectValues();
    setTimeout(() => {
      // Add slight delay to allow setFormObjectValues to be set
      this.setFormInitialState();
      this.settingUpForm = false;
      this.fireInitialValues();
    }, 50);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.options?.checkboxBindingProperty?.length === 2) {
      this.checkboxValue = this.options.checkboxBindingProperty[1] || false;
    }
    if (changes.formObject?.previousValue !== changes.formObject?.currentValue ||
      changes.formItems?.previousValue !== changes.formItems?.currentValue) {
      this.destroy();
      this.setupForm();
      this.setupBindings();
    }
    this.settingUpForm = false;
    this.fireInitialValues();
  }

  fireInitialValues() {
    if (this.initialValuesToEmit.length > 0) {
      // Emit any saved values during form setup
      if (this.options.emitInitialValuesAfterSetup) {
        this.initialValuesToEmit.forEach((initialValue) => {
          initialValue[0].valueChanged.next(initialValue[1]);
        });
      }
      this.initialValuesToEmit = [];
    }
  }

  override setupBindings() {
    const validateSub = this.validateForm.subscribe((_) => {
      this.setFormObjectValues();
      this.validate(this.form);
    });
    this.pushSub(validateSub);
    // Bind to form input changes
    const changesSub = this.form.valueChanges.subscribe((_) => {
      if (!this.settingUpForm && !this.holdFormChanges) {
        // dont emit changes during form setup
        this.formChanges.emit();
      }
    });
    this.pushSub(changesSub);
    // Create binding to hydrate input objects from form data
    const hydrateSub = this.hydrateInputObject.subscribe((_) => {
      this.hydrateInputOutput.emit(this.getPopulatedObject());
    });
    this.pushSub(hydrateSub);
  }

  setFormObjectValues() {
    // Update all the form input values from the formObject
    this.holdFormChanges = true;
    this.formItems.forEach((fi) => {
      if (fi.inputName) {
        const val = this.getBindingProperty(fi);
        this.form.get(fi.inputName).setValue(val);
        if (!this.settingUpForm && fi.itemType === FormItemType.Dropdown) {
          fi.valueChanged.next([val, fi.boundInputs]);
        }
      }
    });
    this.holdFormChanges = false;
  }

  setFormInitialState() {
    let hasInitialValue = false;
    this.formItems.forEach(i => {
      const initialValue = i.getValue();
      if (initialValue && initialValue !== '') {
        hasInitialValue = true;
        if (!this.settingUpForm) {
          i.valueChanged.next([initialValue, i.boundInputs]);
        } else {
          this.initialValuesToEmit.push([i, [initialValue, i.boundInputs]]);
        }
      }
    });
    if (hasInitialValue) {
      // Perform an initial validation on the form if it is not empty
      if (this.options.performNonEmptyInitialValidation) {
        this.validate(this.form);
      }
    }
  }

  isFormInputSet(fi: FormInputItem): boolean {
    const item = this.form.get(fi.inputName);
    const itemValue = item?.value;
    return item && itemValue !== null && itemValue !== undefined;
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    this.formItems = [];
    this.formSubmitted.complete();
    this.formCancelled.complete();
    this.secondaryButtonPressed.complete();
    this.formChanges.complete();
  }

  setupForm() {
    this.settingUpForm = true;
    const controlsConfig = new Map<string, any>();
    this.formItems.forEach((fi) => {
      if (fi.inputName) {
        const validators = [];
        if (fi.required) {
          validators.push(Validators.required);
        }
        if (fi.minLength !== 0) {
          validators.push(Validators.minLength(fi.minLength));
        }
        if (fi.maxLength !== 0) {
          validators.push(Validators.maxLength(fi.maxLength));
        }
        if (fi.minValue !== -1) {
          validators.push(Validators.min(fi.minValue));
        }
        if (fi.maxValue !== -1) {
          validators.push(Validators.max(fi.maxValue));
        }
        if (fi.inputType === 'email') {
          validators.push(Validators.email);
        }
        if (fi.inputType === 'search') {
          validators.push(new SearchForValidatorDirective(fi.searchable));
        }
        if (fi.inputType === 'tel') {
          validators.push(new PhoneValidatorDirective());
        }
        if (fi.customValidator) {
          validators.push(fi.customValidator);
        }
        controlsConfig[fi.inputName] = [
          this.getBindingProperty(fi),
          validators
        ];
      }
    });
    this.form = this.formBuilder.group(controlsConfig);
    this.markPrefilledItemsAsTouched();
    // Set form delegate for all Items
    this.formItems.forEach(i => {
      i.formDelegate = this.form;
      if (i.boundInputs) {
        // Ensure all bound props have reference to delegate
        i.boundInputs.forEach(bi => bi.formDelegate = this.form);
      }
    });
  }

  markPrefilledItemsAsTouched() {
    Object.keys(this.form.controls).forEach(field => {
      const control = this.form.get(field);
      if (control instanceof UntypedFormControl && control.value) {
        control.markAsTouched({onlySelf: true});
      }
    });
  }

  resetForm() {
    this.submitted = false;
    this.form.reset();
    this.formItems.forEach((item) => {
      if (item.itemType === FormItemType.CheckboxGroup) {
        item.groupCheckboxOptions.touched = false;
      } else if (item.itemType !== FormItemType.Divider &&
        item.itemType !== FormItemType.Title &&
        item.itemType !== FormItemType.Hidden &&
        item.itemType !== FormItemType.Spacer) {
        this.form.get(item.inputName).markAsPristine({onlySelf: true});
        this.form.get(item.inputName).markAsUntouched({onlySelf: true});
        this.form.get(item.inputName).setErrors(null);
        item.handleValueChanged();
      }
    });
  }

  public canSubmitForm(): boolean {
    let canSumit = true;
    this.formItems.forEach((item) => {
      if (!item.canSubmit()) {
        canSumit = false;
      }
    });
    return canSumit;
  }

  cancelForm() {
    this.resetForm();
    this.formCancelled.emit();
  }

  public submitForm() {
    if (this.form.valid) {
      this.submitted = true;
      this.formSubmitted.emit(this.getPopulatedObject());
      if (this.options.clearOnSubmit) {
        this.resetForm();
      }
    } else {
      this.validate(this.form);
    }
  }

  getPopulatedObject(): any {
    this.formItems.forEach((item) => {
      if (!item.bindingProperty) {
        return;
      }
      let hasNestedProperty = false;
      if (item.bindingProperty.includes('.')) {
        hasNestedProperty = true;
        const bindingPropertyPath = item.bindingProperty.split('.');
        let nestedFormObject = this.formObject;
        bindingPropertyPath.forEach((p) => {
          if (nestedFormObject && nestedFormObject.hasOwnProperty(p)) {
            nestedFormObject = nestedFormObject[p];
          } else {
            hasNestedProperty = false;
          }
        });
      }
      if (this.formObject && this.formObject.hasOwnProperty(item.bindingProperty) || hasNestedProperty) {
        if (item.inputType === FormInputType.Date) {
          // Parse Date
          const dateVal = moment(this.form.get(item.inputName).value, 'YYYY-MM-DD').toDate();
          this.setBindingProperty(item.bindingProperty, dateVal);
        } else if (item.inputType === FormInputType.Search) {
          const setVal = item.searchable.find(i =>
            i.lookupKey === this.form.get(item.inputName).value).value;
          this.setBindingProperty(item.bindingProperty, setVal);
        } else if (item.inputType === FormInputType.Tel) {
          const telephone = this.form.get(item.inputName).value;
          const setVal = telephone.replaceAll('+', '')
            .replaceAll(' ', '').replaceAll('.', '').replaceAll('-', '')
            .replaceAll('(', '').replaceAll(')', '');
          this.setBindingProperty(item.bindingProperty, setVal);
        } else {
          if (item.customValueParser || item.itemType === FormItemType.CheckboxGroup) {
            // Handle custom encoding
            if (item.itemType === FormItemType.CheckboxGroup) {
              this.setBindingProperty(item.bindingProperty, item.customValueParser(item.groupCheckboxes));
            } else {
              const value = item.customValueParser(this.form.get(item.inputName).value);
              this.setBindingProperty(item.bindingProperty, value);
            }
          } else {
            // Standard encode from form value
            this.setBindingProperty(item.bindingProperty, this.form.get(item.inputName).value);
          }
        }
      }
    });
    if (this.options.includeEndFormCheckbox) {
      if (this.formObject.hasOwnProperty(this.options.checkboxBindingProperty[0])) {
        this.formObject[this.options.checkboxBindingProperty[0]] = this.checkboxValue;
      }
    }
    return this.formObject;
  }

  private getBindingProperty(fi: FormInputItem): any {
    if (!this.formObject) {
      return null;
    }
    let val = null;
    if (fi.bindingProperty.includes('.')) {
      const bindingPropertyPath = fi.bindingProperty.split('.');
      let nestedFormObject = this.formObject;
      bindingPropertyPath.forEach((p, i) => {
        if (nestedFormObject.hasOwnProperty(p)) {
          if (i === bindingPropertyPath.length - 1) {
            // Last element, set the value
            val = nestedFormObject[p];
          } else if (nestedFormObject[p]) {
            nestedFormObject = nestedFormObject[p];
          } else {
            val = null;
          }
        }
      });
    } else {
      val = this.formObject[fi.bindingProperty];
    }
    // Get dropdown Object match
    if (fi.itemType === FormItemType.Dropdown && val instanceof Object) {
      const dropdownMatch = fi.dropdownOptions?.find(d => {
        return d.getSelectionUniqueIdentifier() === (val as ObjectHasSelectableWrapper).getSelectionUniqueIdentifier();
      });
      if (dropdownMatch) {
        val = dropdownMatch.getSelectionValue();
      }
    } else if (fi.itemType === FormItemType.Switch && typeof val !== 'boolean') {
      val = val === 'true';
    }
    return val;
  }

  private setBindingProperty(bindingProperty: string, val: any) {
    if (bindingProperty.includes('.')) {
      const bindingPropertyPath = bindingProperty.split('.');
      let nestedFormObject = this.formObject;
      bindingPropertyPath.forEach((p, i) => {
        if (nestedFormObject.hasOwnProperty(p)) {
          if (i === bindingPropertyPath.length - 1) {
            // Last element, set the value
            nestedFormObject[p] = val;
          } else {
            nestedFormObject = nestedFormObject[p];
          }
        }
      });
    } else {
      this.formObject[bindingProperty] = val;
    }
  }

  validate(form: any) {
    Object.keys(form.controls).forEach(field => {
      const control = form.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({onlySelf: true});
      } else if (control instanceof UntypedFormGroup) {
        this.validate(control);
      }
    });
    // Mark any checkbox groups as touched
    this.formItems.forEach((item) => {
      if (item.itemType === FormItemType.CheckboxGroup) {
        item.groupCheckboxOptions.touched = true;
      }
    });
  }

  shouldDisplayAbandonDialog(): boolean {
    // Get any checkbox groups that have been touched
    const filteredItems = this.formItems.filter(fi => {
      return fi.itemType === FormItemType.CheckboxGroup && fi.groupCheckboxOptions.touched;
    });
    const touchedCheckboxGroup = filteredItems?.length > 0;
    // Get all dirty form controls
    const dirty = Object.keys(this.form.controls).map(field => {
      const control = this.form.get(field);
      return control?.dirty;
    });
    return dirty.some((v) => v === true) || touchedCheckboxGroup;
  }

  resetAbandonDialogState() {
    this.formItems.forEach((item) => {
      if (item.itemType === FormItemType.CheckboxGroup) {
        item.groupCheckboxOptions.touched = false;
      } else if (item.itemType !== FormItemType.Divider &&
        item.itemType !== FormItemType.Title &&
        item.itemType !== FormItemType.Hidden &&
        item.itemType !== FormItemType.Spacer) {
        this.form.get(item.inputName).markAsPristine({onlySelf: true});
      }
    });
  }

  handleInputKeyUp(e) {
    let code;
    if (e.key !== undefined) {
      code = e.key;
    } else if (e.keyIdentifier !== undefined) {
      code = e.keyIdentifier;
    } else if (e.keyCode !== undefined) {
      code = e.keyCode;
    }
    if ((code === 13 || code === 'Enter') && this.options.submitOnEnter) {
      this.submitForm();
    }
  }

  checkboxClicked(rem: boolean) {
    this.checkboxValue = rem;
    this.getPopulatedObject();
    this.formChanges.emit();
  }

  groupedCheckboxesChanged(item: FormInputItem, checkboxes: Checkbox[]) {
    this.validate(this.form);
    item.groupCheckboxOptions.touched = true;
    item.groupCheckboxesChanged(checkboxes);
  }

  getTooltipPlacement(itemIndex: number): PlacementArray {
    if (this.styling.tooltipPosition) {
      return this.styling.tooltipPosition;
    } else {
      return itemIndex % 2 === 0 ? ['right', 'auto', 'top', 'bottom'] : ['left', 'auto', 'top', 'bottom'];
    }
  }

  openTooltipInfoModal(item: FormInputItem) {
    const modalRef = this.modalService.open(
      InformationModalComponent,
      ModalUtils.informationModalOptions()
    );
    const compInstance = modalRef.componentInstance as InformationModalComponent;
    compInstance.title = item.tooltipModalTitle ?? 'More Information';
    compInstance.informationItems = item.tooltipModalInfoItems;
    modalRef.result.then((_) => {
    });
  }

}
