import { Injectable } from '@angular/core';
import { AccountDomainModel } from '../../domainModels/account-domain-model';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

type Activation = Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree;

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {

  constructor(
    public accountDomainModel: AccountDomainModel,
    public router: Router
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Activation {
    return this.accountDomainModel.isAuthenticated().pipe(
      map(r => r !== null),
      tap(sess => {
        if (!sess) {
          this.router.navigate(['/auth/sign-in']).then();
        }
      })
    );
  }

}
