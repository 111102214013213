<div class="modal-padding">
  <lib-reactive-form-group [bindTo]="bindTo" [mergeKey]="mergeKey">
    <lib-reactive-form-column-layout>
      <lib-reactive-form-number
        [inputName]="'minTHC'"
        [label]="'Minimum THC'"
        [placeholder]="'Minimum THC content of products'"
        [inlineLabel]="false"
        [required]="false"
        [disabled]="viewModel.isViewOnly$ | push"
        [integersOnly]="false"
        [step]="0.01"
        [tooltip]="'The smart filter will include products with THC values greater than or equal to this value.'"
        [bindingProperty]="'minTHC'">
      </lib-reactive-form-number>

      <lib-reactive-form-number
        [inputName]="'maxTHC'"
        [label]="'Maximum THC'"
        [placeholder]="'Maximum THC content of products'"
        [inlineLabel]="false"
        [required]="false"
        [disabled]="viewModel.isViewOnly$ | push"
        [integersOnly]="false"
        [step]="0.01"
        [tooltip]="'The smart filter will include products with THC values less than or equal to this value.'"
        [bindingProperty]="'maxTHC'">
      </lib-reactive-form-number>

      <lib-reactive-form-number
        [inputName]="'minCBD'"
        [label]="'Minimum CBD'"
        [placeholder]="'Minimum CBD content of products'"
        [inlineLabel]="false"
        [required]="false"
        [disabled]="viewModel.isViewOnly$ | push"
        [integersOnly]="false"
        [step]="0.01"
        [tooltip]="'The smart filter will include products with CBD values greater than or equal to this value.'"
        [bindingProperty]="'minCBD'">
      </lib-reactive-form-number>

      <lib-reactive-form-number
        [inputName]="'maxCBD'"
        [label]="'Maximum CBD'"
        [placeholder]="'Maximum CBD content of products'"
        [inlineLabel]="false"
        [required]="false"
        [disabled]="viewModel.isViewOnly$ | push"
        [integersOnly]="false"
        [step]="0.01"
        [tooltip]="'The smart filter will include products with CBD values less than or equal to this value.'"
        [bindingProperty]="'maxCBD'">
      </lib-reactive-form-number>

      <ng-container *rxFor="let cannabinoid of viewModel.secondaryCannabinoids$ | push">
        <lib-reactive-form-number
          [inputName]="'min' + cannabinoid"
          [label]="'Minimum ' + cannabinoid"
          [placeholder]="'Minimum ' + cannabinoid + ' content of products'"
          [inlineLabel]="false"
          [required]="false"
          [disabled]="viewModel.isViewOnly$ | push"
          [integersOnly]="false"
          [step]="0.01"
          [tooltip]="
            'The smart filter will include products with ' +
            cannabinoid +
            ' values greater than or equal to this value.'
          "
          [bindingProperty]="'min' + cannabinoid">
        </lib-reactive-form-number>

        <lib-reactive-form-number
          [inputName]="'max' + cannabinoid"
          [label]="'Maximum ' + cannabinoid"
          [placeholder]="'Maximum ' + cannabinoid + ' content of products'"
          [inlineLabel]="false"
          [required]="false"
          [disabled]="viewModel.isViewOnly$ | push"
          [integersOnly]="false"
          [step]="0.01"
          [tooltip]="
            'The smart filter will include products with ' + cannabinoid + ' values less than or equal to this value.'
          "
          [bindingProperty]="'max' + cannabinoid">
        </lib-reactive-form-number>
      </ng-container>
    </lib-reactive-form-column-layout>
  </lib-reactive-form-group>
</div>
