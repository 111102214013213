import { TypeDefinition } from './type-definition';

export enum Terpene {
  AlphaBisabolol = 'Alpha Bisabolol',
  AlphaPinene = 'Alpha Pinene',
  BetaCaryophyllene = 'Beta Caryophyllene',
  BetaEudesmol = 'Beta Eudesmol',
  BetaMyrcene = 'Beta Myrcene',
  BetaPinene = 'Beta Pinene',
  Bisabolol = 'Bisabolol',
  Borneol = 'Borneol',
  Camphene = 'Camphene',
  CaryophylleneOxide = 'Caryophyllene Oxide',
  Delta3Carene = 'Delta 3 Carene',
  Eucalyptol = 'Eucalyptol',
  Geraniol = 'Geraniol',
  Guaiol = 'Guaiol',
  Humulene = 'Humulene',
  Linalool = 'Linalool',
  Limonene = 'Limonene',
  Ocimene = 'Ocimene',
  ThreeCarene = 'Three Carene',
  Terpinene = 'Terpinene',
  Terpinolene = 'Terpinolene',
  TransNerolidol = 'Trans Nerolidol',
  Valencene = 'Valencene',
}

export class TerpeneTypeDefinition extends TypeDefinition {

  override value: Terpene;

}
