import { BaseDomainModel } from '../models/base/base-domain-model';
import { Injectable } from '@angular/core';
import { SessionService } from '../services/session-service';
import { BehaviorSubject, defer, forkJoin, Observable, of } from 'rxjs';
import { delay, map, mapTo, switchMap, switchMapTo, take, tap } from 'rxjs/operators';
import { ImageAPI } from '../api/image-api';
import { ProductDomainModel } from './product-domain-model';
import { TemplateAPI } from '../api/template-api';
import { BudsenseFile } from '../models/shared/budsense-file';
import { GenerateUploadUrlRequest } from '../models/image/requests/generate-upload-url-request';
import { StringUtils } from '../utils/string-utils';
import { UploadFilePath } from '../models/enum/dto/upload-file.path';
import { Theme } from '../models/menu/dto/theme';
import { Asset } from '../models/image/dto/asset';
import { ThemeApi } from '../api/theme-api';
import { SectionBlueprint } from '../models/menu/dto/section-blueprint';
import { SectionBlueprintCategory } from '../models/menu/dto/section-blueprint-category';
import { SortUtils } from '../utils/sort-utils';
import { DefaultStackSize } from '../utils/default-stack-size';

@Injectable({
  providedIn: 'root'
})

export class MenuDomainModel extends BaseDomainModel {

  // Active Menu Data
  public refreshThemesSubject = new BehaviorSubject<void>(undefined);
  public themes$ = new BehaviorSubject<Theme[]>(null);
  fetchThemes = this.refreshThemesSubject
    .pipe(
      switchMapTo(this.themeAPI.getThemes())
    ).subscribe(this.themes$);

  private _sectionBlueprints = new BehaviorSubject<SectionBlueprint[]>(null);
  public sectionBlueprints$ = this._sectionBlueprints as Observable<SectionBlueprint[]>;

  private _sectionBlueprintCategories = new BehaviorSubject<SectionBlueprintCategory[]>(null);
  public sectionBlueprintCategories$ = this._sectionBlueprintCategories.pipe(
    map(categories => categories?.sort((a, b) => SortUtils.numericStringAsc(a?.name, b?.name)))
  );

  constructor(
    public session: SessionService,
    public productDomainModel: ProductDomainModel,
    private templateAPI: TemplateAPI,
    private themeAPI: ThemeApi,
    private imageAPI: ImageAPI,
  ) {
    super();
    this.setupBindings();
  }

  setupBindings() {
    // Bind to Variant Badges
    const badgeSub = this.productDomainModel.badges$.subscribe((badges) => {
      if (!badges) {
        if (this.session.liveSession()) {
          this.productDomainModel.getBadges().once();
        }
      }
    });
    this.pushSub(badgeSub);
  }

  saveTheme(
    creatingNew: boolean,
    theme: Theme,
    portraitImagesToUpload: BudsenseFile[],
    landscapeImagesToUpload: BudsenseFile[],
    assetsToDelete: Asset[],
    uploadCardSizeMap?: Map<string, DefaultStackSize>
  ): Observable<Theme> {
    const saveObs$ = !creatingNew
      ? defer(() => this.updateTheme(theme))
      : defer(() => this.createTheme(theme));
    return saveObs$.pipe(
      switchMap(savedTheme => {
        if (theme?.containsStackedContent() && landscapeImagesToUpload?.length > 0) {
          const imageOperations: Observable<void>[] = [];
          const uploadImages$ = landscapeImagesToUpload.map(i => this.uploadThemePreviewImage(savedTheme, i, false));
          imageOperations.push(...(uploadImages$ || []));
          if (assetsToDelete?.length > 0) {
            const deleteAssets$ = assetsToDelete.map(a => this.imageAPI.deleteAsset(a.id, a.md5Hash).pipe(mapTo(null)));
            imageOperations.push(...(deleteAssets$ || []));
          }
          return forkJoin(imageOperations).pipe(
            switchMap(_ => this.updatePrintCardTheme(savedTheme, uploadCardSizeMap))
          );
        }
        const additionalOperations: Observable<any>[] = [];

        if (portraitImagesToUpload?.length > 0) {
          const portraitImages = portraitImagesToUpload.map(i => this.uploadThemePreviewImage(savedTheme, i, true));
          additionalOperations.push(...(portraitImages || []));
        }
        if (landscapeImagesToUpload?.length > 0) {
          const landscapeImages = landscapeImagesToUpload.map(i => this.uploadThemePreviewImage(savedTheme, i, false));
          additionalOperations.push(...(landscapeImages || []));
        }
        if (assetsToDelete?.length > 0) {
          const deleteAssets = assetsToDelete.map(a => this.imageAPI.deleteAsset(a.id, a.md5Hash).pipe(mapTo(null)));
          additionalOperations.push(...(deleteAssets || []));
        }

        return additionalOperations?.length > 0
          ? forkJoin(additionalOperations).pipe(mapTo(savedTheme))
          : of(savedTheme);
      }),
      tap(() => this.refreshThemesSubject.next())
    );
  }

  private updatePrintCardTheme(
    savedTheme: Theme,
    uploadCardSizeMap?: Map<string, DefaultStackSize>
  ): Observable<Theme> {
    return this.themes$.pipe(
      take(1),
      switchMap(themes => {
        const theme = themes.find(t => t?.id === savedTheme?.id);
        const updatedPreviewCardMap = new Map(theme.printConfig.previewCardMap);
        uploadCardSizeMap?.forEach((cardSize, fileName) => {
          const previewImage = theme?.landscapePreviewImages?.find(i => i?.fileName === fileName);
          if (!!previewImage) {
            const hashes = updatedPreviewCardMap?.get(cardSize) || [];
            hashes.push(previewImage?.md5Hash);
            updatedPreviewCardMap.set(cardSize, hashes);
          }
        });
        theme.printConfig.previewCardMap = new Map<DefaultStackSize, string[]>(updatedPreviewCardMap);
        return this.updateTheme(theme);
      })
    );
  }

  private uploadThemePreviewImage(theme: Theme, imgToUpload: BudsenseFile, isPortraitImage: boolean): Observable<void> {
    const uploadReq = new GenerateUploadUrlRequest();
    uploadReq.fileName = StringUtils.normalizeCharacters(imgToUpload.name);
    uploadReq.mediaClass = isPortraitImage
      ? UploadFilePath.ThemePreviewPortraitPath
      : UploadFilePath.ThemePreviewLandscapePath;
    uploadReq.mediaType = imgToUpload.getMediaType();
    uploadReq.metadata = new Map<string, string>();
    uploadReq.metadata.set('ThemeId', theme.id);

    return this.imageAPI.GenerateUploadUrl(uploadReq).pipe(
      switchMap((signedUploadUrl) => {
        return this.imageAPI.PutImageUploadUrl(signedUploadUrl.url, imgToUpload.url.toString(), uploadReq.fileName)
          .pipe(
            // provide delay based on file size
            delay(imgToUpload.getUploadDelay()),
            switchMap(() => this.pollForUploadedPreviewImages(theme, imgToUpload, isPortraitImage, 10, 0)),
          );
      })
    );
  }

  pollForUploadedPreviewImages(
    savedTheme: Theme,
    imageToUpload: BudsenseFile,
    isPortraitImage: boolean,
    numOfRetries: number,
    delayTime: number
  ): Observable<void> {
    const pollingFunc$ = defer(() => this.themeAPI.getThemes());
    return pollingFunc$.pipe(
      delay(delayTime),
      switchMap(themes => {
        const theme = themes.find(t => t.id === savedTheme.id);
        const previewImagePool = isPortraitImage ? theme?.portraitPreviewImages : theme?.landscapePreviewImages;
        const themePreviewIndex = previewImagePool?.findIndex(preview => preview?.fileName === imageToUpload?.name);
        if (themePreviewIndex > -1) {
          numOfRetries = 0;
        }
        if (numOfRetries > 0) {
          return this.pollForUploadedPreviewImages(savedTheme, imageToUpload, isPortraitImage, numOfRetries - 1, 3500);
        }
        numOfRetries = 0;
        this.themes$.next(themes);
        return of(null);
      })
    );
  }

  private updateTheme(theme: Theme): Observable<Theme> {
    return this.themeAPI.updateTheme(theme);
  }

  private createTheme(theme: Theme): Observable<Theme> {
    return this.themeAPI.createTheme(theme);
  }

  public createSectionBlueprint(sectionBlueprint: SectionBlueprint): Observable<SectionBlueprint> {
    return this.templateAPI.CreateSectionBlueprint(sectionBlueprint);
  }

  public createSectionBlueprintCategory(category: SectionBlueprintCategory): Observable<SectionBlueprintCategory> {
    return this.templateAPI.CreateSectionBlueprintCategory(category);
  }

  public updateSectionBlueprintCategory(category: SectionBlueprintCategory): Observable<SectionBlueprintCategory> {
    return this.templateAPI.UpdateSectionBlueprintCategory(category);
  }

  public deleteSectionBlueprintCategory(category: SectionBlueprintCategory): Observable<string> {
    return this.templateAPI.DeleteSectionBlueprintCategory(category);
  }

  public loadSectionBlueprints() {
    this.templateAPI.GetSectionBlueprints().subscribe(blueprints => {
      this._sectionBlueprints.next(blueprints);
    });
  }

  public getSectionBlueprintsByIds(ids: string[]): Observable<SectionBlueprint[]> {
    return this.templateAPI.GetSectionBlueprints(ids);
  }

  public loadSectionBlueprintCategories() {
    this.templateAPI.GetSectionBlueprintCategories().subscribe(categories => {
      this._sectionBlueprintCategories.next(categories);
    });
  }

  public updateSectionBlueprint(blueprint: SectionBlueprint): Observable<SectionBlueprint> {
    const Deserialize = window?.injector?.Deserialize;
    const blueprintDTO = Deserialize?.instanceOf(SectionBlueprint, blueprint)?.translateIntoDTO();
    return this.templateAPI.UpdateSectionBlueprint(blueprintDTO).pipe(
      tap(sectionBlueprint => this.setSectionBlueprint(sectionBlueprint))
    );
  }

  public uploadSectionBlueprintAsset(file: BudsenseFile, sectionBlueprint: SectionBlueprint): Observable<any> {
    const uploadReq = new GenerateUploadUrlRequest();
    uploadReq.fileName = StringUtils.normalizeCharacters(file.name);
    uploadReq.mediaClass = UploadFilePath.SectionImagePath;
    uploadReq.mediaType = file.getMediaType();
    uploadReq.metadata = new Map<string, string>();
    uploadReq.metadata.set('SectionId', sectionBlueprint?.id);

    return this.imageAPI.GenerateUploadUrl(uploadReq).pipe(
      switchMap((signedUploadUrl) => {
        return this.imageAPI.PutImageUploadUrl(signedUploadUrl.url, file.url.toString(), uploadReq.fileName);
      })
    );
  }

  deleteAsset(asset: Asset): Observable<any> {
    if (asset) {
      return this.imageAPI.deleteAsset(asset.id, asset.md5Hash);
    } else {
      return of(true);
    }
  }

  private setSectionBlueprint(sectionBlueprint: SectionBlueprint) {
    this.sectionBlueprints$.pipe(take(1)).subscribe(sectionBlueprints => {
      const sbIndex = sectionBlueprints?.findIndex(sb => sb?.id === sectionBlueprint?.id);
      if (sbIndex >= 0) {
        sectionBlueprints?.splice(sbIndex, 1, sectionBlueprint);
      } else {
        sectionBlueprints?.push(sectionBlueprint);
      }
      this._sectionBlueprints.next(sectionBlueprints);
    });
  }

  public deleteSectionBlueprint(sectionBlueprint: SectionBlueprint): Observable<string> {
    const Deserialize = window?.injector?.Deserialize;
    const blueprintDTO = Deserialize?.instanceOf(SectionBlueprint, sectionBlueprint)?.translateIntoDTO();
    return this.templateAPI.DeleteSectionBlueprint(blueprintDTO);
  }

}
