import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../models/base/base-view-model';
import { CompanyDomainModel } from '../../../../domainModels/company-domain-model';

@Injectable()
export class FormGroupViewModel extends BaseViewModel {

  constructor(
    private companyDomainModel: CompanyDomainModel
  ) {
    super();
  }

}
